﻿import { Component, OnInit } from '@angular/core';
import { CommonDataService } from './../../common-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Users } from './../../../services/Users';
import { ProjectService } from './../../../services/ProjectService';
import { ProjectModule } from './../../../models/ProjectModule';
import { TranslateService } from '@ngx-translate/core';
import * as shape from 'd3-shape';
declare var window: any;
@Component({
  selector: 'app-projects',
  templateUrl: './editProjects.component.html',
  styleUrls: ['./editProjects.component.scss']
})
export class EditProjectsComponent implements OnInit {

    idProject = 0;
    project: ProjectModule = new ProjectModule();
    projectTitleEdit = false;
    username = "";
    description = "";
    portadaText = "";
    public dateDefault: string = "";
    selectedFile: File = null;
    selectedPortada: File = null;
    selectedLogo: File = null;
    selectedLogoFooter: File = null;
    files: any = [];
    comments: any = [];
    showComments = false;
    setlateralColor;
    setlateralBackgroundColor;
    user;
    translateImageSize = "";
    constructor(
    private _commondata: CommonDataService,
    public router: Router,
    public route: ActivatedRoute,
    public user_model: Users,
    public projectService: ProjectService,
    translate: TranslateService,
    ) 
    { 
        let respuesta = translate.get('Image_Size_should_not_be_greater_than_1mb').subscribe(res => { this.translateImageSize = res; });
    }
    ngOnInit() 
    {
        this.user = this.user_model.getLocalUser();
        setTimeout(_ => this._commondata.showLoader(true), 200);
        document.querySelector("#data_select").addEventListener("change", (e:Event) => this.setDate());
        this.onInit(); 
    }
    onInit() 
    {
        
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1; //January is 0!
        var yyyy = today.getFullYear();

        var ddn = ""+dd;
        var mmn = ""+mm;
        
        if(dd<10) {
            ddn = '0'+dd;
        } 

        if(mm<10) {
            mmn = '0'+mm;
        } 

        this.dateDefault = yyyy + '-' + mmn + '-' + ddn;
        (<HTMLInputElement>document.querySelector("#data_select")).value = this.dateDefault;
        setTimeout(_ => this._commondata.showLoader(true), 10);
        
        this.route.params.subscribe(
            (res: ProjectModule) =>
            {
                if(res["idProject"]>0)
                {
                    this.idProject = res["idProject"];
                    (<HTMLElement>document.querySelector("#projectBackground")).style.backgroundImage = "url('assets/images/projectImg.jpg')";
                    let response = this.projectService.getProject(this.idProject).then(
                        (res:any)=>{
                            console.log(res);
                            this.project = res;
                            if(this.project.getDate() != null)
                            {
                                this.dateDefault = this.project.getDate().split("T")[0];

                                (<HTMLInputElement>document.querySelector("#data_select")).value = this.dateDefault;
                            }
                            
                            let lthis = this;
//                            window.CKEDITOR.instances["editor-editor"].on('blur', function() {
//                                lthis.setDescription();
//                            });
//                            console.log(this.project.getDescription());
//                            if(this.project.getDescription() != null)
//                            {
//                                this.description = this.project.getDescription();
//
//                                setTimeout(_ => window.CKEDITOR.instances["editor-editor"].setData(lthis.description), 1000);
//
//                            }
                            
                            
//                            window.CKEDITOR.instances["editor-setPrincipalDescription"].on('blur', function() {
//                                lthis.setPortadaDescription();
//                            });
//                            console.log(this.project.getPortadaText());
//                            if(this.project.getPortadaText() != null)
//                            {
//                                this.portadaText = this.project.getPortadaText();
//                                setTimeout(_ => window.CKEDITOR.instances["editor-setPrincipalDescription"].setData(lthis.portadaText), 1000);
//                                
//                            }
                            
                            if(this.project.getUser() != null)
                            {
                                this.username = this.project.getUser().username;
                            }
                            
                            if(this.project.getLateralBarColor() != null)
                            {
                                (<HTMLInputElement>document.querySelector("[data-color='"+this.project.getLateralBarColor()+"_c']")).style.border = "2px solid";
                            }
                            if(this.project.getLateralBarBackgroundColor() != null)
                            {
                                (<HTMLInputElement>document.querySelector("[data-color='"+this.project.getLateralBarBackgroundColor()+"_b']")).style.border = "2px solid";
                            }
                            
                            this.loadFiles();
                            
                            this.loadComments();
                            
                            setTimeout(_ => this._commondata.showLoader(false), 200);
                        },
                        err=>{
                            console.log("form_no");
                        }
                    );
                }
                if (this.idProject === 0)
                {
                    this.router.navigate(['/projects/']);
                    
                }
            }
        );
    }
    editProjectTitle()
    {
        if(this.projectTitleEdit==false)
        {
            this.projectTitleEdit = true;
        }
        else
        {
            this.project.setName((<HTMLInputElement>document.querySelector("#titleEdit")).value);
            this.projectTitleEdit = false;
            
            let response = this.projectService.putProject(this.project.getId(),{"name":this.project.getName()}).then(
                    (res:ProjectModule)=>{
                        console.log(res);
                        this.project = res;
                        document.querySelector("#titleEditable").innerHTML = this.project.getName();
                    },
                    err=>{
                        
                    }
                );
            
        }
    }
    setState(idState)
    {
        
        this.project.setState(idState);
        let response = this.projectService.putProject(this.project.getId(),{"state":this.project.getState()}).then(
            (res:ProjectModule)=>{
                console.log(res);
                this.project = res;
            },
            err=>{

            }
        );
    }
    setDate()
    {
        console.log((<HTMLInputElement>document.querySelector("#data_select")).value);
        
        this.project.setDate((<HTMLInputElement>document.querySelector("#data_select")).value);
        console.log(this.project);
        let response = this.projectService.putProject(this.project.getId(),{"date":this.project.getDate()}).then(
            (res:ProjectModule)=>{
                console.log(res);
                this.project = res;
            },
            err=>{

            }
        );
    }
    setDescription()
    {
        console.log("yeee");
        this.description = window.CKEDITOR.instances["editor-editor"].getSnapshot();
        this.project.setDescription(this.description);
        console.log(this.project);
        let response = this.projectService.putProject(this.project.getId(),{"description":this.project.getDescription()}).then(
            (res:ProjectModule)=>{
                console.log(res);
                this.project = res;
            },
            err=>{

            }
        );
    }
    
    setPortadaDescription()
    {
//        this.portadaText = window.CKEDITOR.instances["editor-setPrincipalDescription"].getSnapshot();
        this.project.setPortadaText(this.portadaText);
        console.log(this.project);
        let response = this.projectService.putProject(this.project.getId(),{"portadaText":this.project.getPortadaText()}).then(
            (res:ProjectModule)=>{
                console.log(res);
                this.project = res;
            },
            err=>{

            }
        );
    }
    
//    selectingImg(src,imgName)
//    {
//        this.selectImg = imgName;
//        this.OpenMenu("submenu", "wrapDiv");
//        this.url = src;
//        this.imgPreview=true;
//    }
    
    onFileChanged(event) {
    if(event.target.files[0].size>1056770)
    {
        alert(this.translateImageSize);  
        return false;
    }
    this.selectedFile = event.target.files[0];
        if (event.target.files && event.target.files[0]) {
//            this.imgPreview=true;
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            reader.onload = (event) => 
            { // called once readAsDataURL is completed
              //this.url = reader.result;
                this.uploadFile();
            }
        }
    }
    
    uploadFile() {
        
        this.projectService.uploadFile(this.idProject,this.selectedFile).then(
            (data : any)=>{
                console.log(data);
                this.onInit();
              },
              error=>{
                console.log(error);
              }
            );
        
    }
    
    setComment() {
        let user = this.user_model.getLocalUser();
        this.projectService.setComment(this.idProject,window.CKEDITOR.instances["editor-setComment"].getSnapshot(),user.getId()).then(
            (data : any)=>{
                console.log(data);
                window.CKEDITOR.instances["editor-setComment"].setData(" ");
                this.onInit();
              },
              error=>{
                console.log(error);
              }
            );
        
    }
    
    
    loadFiles()
    {
        this.files = [];
        if(this.project.getFiles() != null)
        {
            console.log(this.project.getFiles());
            for(let i=0;i<this.project.getFiles().length;i++)
            {
                if(this.project.getFiles() != null && this.project.getFiles()[i] != null && this.project.getFiles()[i].image != null)
                {
                    console.log(this.project.getFiles()[i]);
                    let currentFileName = this.project.getFiles()[i].image;
                    if(currentFileName.includes(".jpeg") || currentFileName.includes(".jpg") || currentFileName.includes("gif") || currentFileName.includes("png"))
                    {
                        let data = {
                            'id':this.project.getFiles()[i].id,
                            'url':this.user_model.getUrl()+"/upload/files/"+currentFileName,
                            'type':"image",
                            'name':currentFileName
                        }
                        this.files.push(data);
                    }
                    else
                    {
                        let data = {
                            'url':this.user_model.getUrl()+"/upload/files/"+currentFileName,
                            'type':"file",
                            'name':currentFileName
                        }
                        this.files.push(data);
                    }
                }
            }
            console.log(this.files);

        }
    }
    
    loadComments()
    {
        this.comments = [];
        if(this.project.getComments() != null)
        {
            for(let i=0;i<this.project.getComments().length;i++)
            {
                if(this.project.getComments() != null && this.project.getComments()[i] != null && this.project.getComments()[i].message != null && this.project.getComments()[i].user != null && this.project.getComments()[i].user.username != null)
                {
                    this.comments.push(
                        {
                            "name":this.project.getComments()[i].user.username,
                            "message":this.project.getComments()[i].message
                        },
                            
                    );
                }
            }
        }        
                
    }
    
    changeCommentsView(condition)
    {
        if(condition == "true")
        {
            this.showComments = true;
        }
        else
        {
            this.showComments = false;
        }
    }
    
//    onlogChanged(event)
//    {
//        this.selectedLogo = event.target.files[0];
//        if (event.target.files && event.target.files[0]) {
////            this.imgPreview=true;
//            var reader = new FileReader();
//
//            reader.readAsDataURL(event.target.files[0]); // read file as data url
//
//            reader.onload = (event) => 
//            { // called once readAsDataURL is completed
//              //this.url = reader.result;
//                this.uploadLogo();
//            }
//        }
//    }
//    
//    onlogFooterChanged(event)
//    {
//        this.selectedLogoFooter = event.target.files[0];
//        if (event.target.files && event.target.files[0]) {
//            var reader = new FileReader();
//
//            reader.readAsDataURL(event.target.files[0]); // read file as data url
//
//            reader.onload = (event) => 
//            { // called once readAsDataURL is completed
//                this.uploadLogoFooter();
//            }
//        }
//    }
//    
//    uploadLogoFooter() {
//        
//        this.projectService.uploadLogoFooter(this.idProject,this.selectedLogoFooter).then(
//            (data : any)=>{
//                console.log(data);
//                this.onInit();
//              },
//              error=>{
//                console.log(error);
//              }
//            );
//        
//    }
//    
//    uploadLogo() {
//        
//        this.projectService.uploadLogo(this.idProject,this.selectedLogo).then(
//            (data : any)=>{
//                console.log(data);
//                this.onInit();
//              },
//              error=>{
//                console.log(error);
//              }
//            );
//        
//    }
    
    onPortadaChanged(event)
    {
        this.selectedPortada = event.target.files[0];
        if (event.target.files && event.target.files[0]) {
//            this.imgPreview=true;
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            reader.onload = (event) => 
            { // called once readAsDataURL is completed
              //this.url = reader.result;
                this.uploadPortada();
            }
        }
    }
    
    uploadPortada() {
        
        this.projectService.uploadPortada(this.idProject,this.selectedPortada).then(
            (data : any)=>{
                console.log(data);
                this.onInit();
              },
              error=>{
                console.log(error);
              }
            );
        
    }
    deleteFile(id)
    {
        console.log(id);
        this.projectService.deleteFile(id).then(
            (data : any)=>{
                console.log(data);
                this.onInit();
              },
              error=>{
                console.log(error);
              }
            );
    }
    
    setLateralText(lateralbarText)
    {
        let response = this.projectService.putProject(this.project.getId(),{"lateralBarText":lateralbarText}).then(
            (res:ProjectModule)=>{
                console.log(res);
                this.project = res;
            },
            err=>{

            }
        );
        
        
    }
    
    setColor(elemId,color)
    {
        let allElem = document.querySelectorAll(".colorBlock");
        for(let i = 0; i < allElem.length; i++)
        {
            (<HTMLInputElement>allElem[i]).style.border = "0.3px solid";
        }
        (<HTMLInputElement>document.querySelector("#"+elemId)).style.border = "2px solid";
        this.setlateralColor = color;
        
        let response = this.projectService.putProject(this.project.getId(),{"lateralBarColor":this.setlateralColor}).then(
            (res:ProjectModule)=>{
                console.log(res);
                this.project = res;
            },
            err=>{

            }
        );  
    }
    
    
    setBackgroundColor(elemId,color)
    {
        let allElem = document.querySelectorAll(".colorBlocktwo");
        for(let i = 0; i < allElem.length; i++)
        {
            (<HTMLInputElement>allElem[i]).style.border = "0.3px solid";
        }
        (<HTMLInputElement>document.querySelector("#"+elemId)).style.border = "2px solid";
        this.setlateralBackgroundColor = color;
        
        let response = this.projectService.putProject(this.project.getId(),{"lateralBarBackgroundColor":this.setlateralBackgroundColor}).then(
            (res:ProjectModule)=>{
                console.log(res);
                this.project = res;
            },
            err=>{

            }
        );
        
        
    }
    
    projectForUser()
    {
        console.log(this.project.getUser().id);
        console.log(this.user.id);
        return this.project.getUser().id == this.user.id;
    }
    
    getProfile()
    {
        if(this.project.getUser()!=null)
        {
            if(this.project.getUser().image!=null)
            {
                return this.user_model.getUrl()+"/upload/profile/"+this.project.getUser().image;
            }
        }
        return "assets/images/team/05.jpg"
        
    }
    
}
