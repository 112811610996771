import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

export class SectionThemeModule extends Base_Model
{

    private id: number = null;
    public themeName: string = null;
    
    public getId()
    {
        return this.id;
    }
    public getName()
    {
        return this.themeName;
    }
}