import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {FieldsModule} from './FieldsModule';
import {ClusterModule} from './ClusterModule';
export class PoolModule extends Base_Model
{

    private id: number = null;
    private name: string = null;
    private text: string = null;
    private user: string = null;
    public cluster: ClusterModule[] = [];
    private sort: number = null;
    
    public getId()
    {
        return this.id;
    }
    public getName()
    {
        return this.name;
    }
    public getText()
    {
        return this.text;
    }
    public setText(text)
    {
        this.text = text;
        return this;
    }
    public getUser()
    {
        return this.user;
    }
    
    public getClusters()
    {
//        console.log(this.cluster);
//        let clustersAux = [];
//        for(let cluster of this.cluster)
//        {
//            if (!(cluster instanceof ClusterModule))
//            {
//                let clusterAux = new ClusterModule();
//                clusterAux.initialize(cluster);
//                console.log(clusterAux);
//                clustersAux.push(clusterAux);
//            }
//            else
//            {
//                clustersAux.push(cluster);
//            }
//            
//        }
//        this.cluster = clustersAux;
        return this.cluster;
    }
    
    public getSort()
    {
        return this.sort;
    }
    
}