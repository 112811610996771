import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

export class FettleModule extends Base_Model
{

    private id: number = null;
    private logic:string = null;
    private cond:string = null;
    private response:string = null;
    private opts:any = [];
    private lineMainId:string = null;
    private line:any = null;
    
    public getId()
    {
        return this.id;
    }
    
    public getLogic()
    {
        return this.logic;
    }
    
    public getResponse()
    {
        return this.response;
    }
    
    public getCondition()
    {
        return this.cond;
    }
    public getLineMainId()
    {
        return this.lineMainId;
    }
    public getLine()
    {
        return this.line;
    }
    
    public setLogic(logic)
    {
        this.logic = logic
        return this;
    }
    
    public setResponse(response)
    {
        this.response = response;
        return this;
    }
    
    public setCondition(condition)
    {
        this.cond = condition;
        return this;
    }
    public setLineMainId()
    {
        return this.lineMainId;
    }
    public setLine(line)
    {
        this.line = line;
        return this;
    }
    
    public setOpts(opts)
    {
        this.opts = opts;
        return this;
    }
    
    public getOpts()
    {
        return this.opts;
    }
    
    
    
}