﻿import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonDataService } from './../common-data.service';
import { TranslateService } from '@ngx-translate/core';
import { Form } from './../../services/Form';
import { BlockService } from './../../services/BlockService';
import { FieldsService } from './../../services/FieldsService';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from './../shared/_services/index';
import { FormBuilder,FormControl, FormGroup, Validators, NgForm,FormsModule } from '@angular/forms';
import { FormModule } from './../../models/FormModule';

import * as shape from 'd3-shape';
declare var window: any;

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

    @ViewChild("containerPrint") containerPrint: ElementRef;
    idForm = false;
    currentBlock = "0";
    public currentTypeField = false;
    blocks = [];
    initialBlocks = [];
    statusField;
    signupForm: FormGroup;
    editForm: FormGroup;
    subBlock: FormGroup;
    optionalBlock:FormGroup;
    currentIdCreateSubBlock = 0;
    currentGroupId = 0;
    currentFieldId = 0;
    currentFieldType = "";
    myForm:FormModule;
    formTitleEdit = false;
    blockTitle = false;
    blockDeleteId;
    fieldDelete;
    blocksStructure = [];
    blockSortAction;
    blockSort;
    blockSortFind;
    recursiveCheck = "";
    setActionColor = "";
    currentGroup="";
    myGroups;
    myPointsGroups;
    allFields = [];
    isEdit=false;
    editGroupId = 0;
    deleteGroupId = 0;
    mainGroupId = 0;
    conditionalId;
    conditional = [];
    formParagrafQuestions = {};
    conditionalField;
    fieldsUnGruped = [];
    currentFieldSelectedPointGroup = null;
    constructor(
    private _commondata: CommonDataService,
    translate: TranslateService,
    public form: Form,
    public router: Router,
    public route: ActivatedRoute,
    public block: BlockService, 
    public fieldService: FieldsService,
    private modalService: ModalService,
    private frmbuilder:FormBuilder
    ) { }
    
    ngOnInit()
    {
        this.getInit();
    }
    
    getInit()
    {
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.statusField="";
        this.currentFieldId = 0;
        this.isEdit=false;
        
        this.route.params.subscribe(
            (resp: any[]) =>
            {
                if(resp["idForm"]>0)
                {
                    this.idForm = resp["idForm"];
                    this.getForm(this.idForm);
                }
                if (this.idForm === false)
                {
                    this.router.navigate(['/form/']);
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                }
            }
        );
        setTimeout(_ => this._commondata.showLoader(false), 200);
    }
    
    getForm(idForm)
    {
        
        let response = this.form.getForm(idForm).then(
            (res:any)=>{
                console.log(res);
                this.myForm = res["form"];
                setTimeout(_ => this._commondata.showLoader(false), 10);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    createFixed()
    {
        setTimeout(_ => this._commondata.showLoader(true), 10);
        
        let response = this.form.getFormFieldsUnGrouped(this.idForm).then(
            (res:any)=>{
                console.log(res);
                this.fieldsUnGruped = res;
                this.openModal("addField");
                setTimeout(_ => this._commondata.showLoader(false), 10);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
        
    }
    selectField(element)
    {
        this.currentTypeField = element;
    }
    openModal(id: string) 
    {
        setTimeout(_ => this._commondata.showLoader(true), 10);
        
        let response = this.form.getFormFieldsUnGrouped(this.idForm).then(
            (res:any)=>{
                console.log(res);
                this.fieldsUnGruped = res;
                this.modalService.open("addField");
                setTimeout(_ => this._commondata.showLoader(false), 10);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
        
    }
    setCurrentFieldPointGroup(id)
    {
        this.currentFieldSelectedPointGroup = id;
    }
    closeModal(id: string) 
    {
        console.log(id);
        this.modalService.close(id);
    }
    
    createPointsGroup()
    {
        this.modalService.open("createPointsGroupModal");  
    }
    
    savePointsGroup()
    {
        let nameGroup=(<HTMLInputElement>document.getElementById("groupPointsName")).value;
//        if(!this.checkNameInGroup(nameGroup))
//        {
            setTimeout(_ => this._commondata.showLoader(true), 10);
            this.form.createPointsGroup({"name"  :nameGroup ,"form": this.idForm+""}).then(
                (res)=>{
                     setTimeout(_ => this._commondata.showLoader(false), 200);
                        (<HTMLInputElement>document.getElementById("groupPointsName")).value = "";
//                        this.sortFields();
                        this.getInit();
                        this.closeModal('createPointsGroupModal');
                },
                err=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);

                }
            );
//        }
    }
    
    activeGroup(id)
    {
        setTimeout(_ => this._commondata.showLoader(true), 10);
        console.log(id);
        this.currentGroup = id;
        this.form.getPointsGroup(id).then(
            (res)=>{
                let i = 0;
                for( let groups of this.myForm.pointsGroups )
                {
                    if(groups.id == id)
                    {
                        this.myForm.pointsGroups[i] = res;
                    }
                    i++;
                }
                setTimeout(_ => this._commondata.showLoader(false), 200);
                
            },
            err=>{
                setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
    }
    
    selectedPointGroup(pointsGroup,fieldId)
    {
        if(pointsGroup.fields)
        {
            for(let field of pointsGroup.fields)
            {
                if(field.id == fieldId)
                {
                    return true;
                }
            }
        }
        return false;
    }
    
    setFieldToGroupPoint(fieldId,pointsGroups)
    {
        this.fieldService.putField(fieldId,{"pointsGroup":pointsGroups}).then(
                (res)=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.getInit();
                },
                err=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);

                }
            );
    }
    setFieldGroup()
    {
        if(this.currentFieldSelectedPointGroup)
        {
            this.setFieldToGroupPoint(this.currentFieldSelectedPointGroup,this.currentGroup);
        }
    }
    
    groupUpdate(group,target)
    {
        console.log(target.value);
        this.form.putGroupPoint(group.id,{ "percentage":+target.value }).then(
                (res)=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.getInit();
                },
                err=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);

                }
            );
    }
    editGroup(groupId)
    {
        
        this.editGroupId = groupId;
        
    }
    
    updateGroupName(group,value)
    {
        console.log(value);
        this.form.putGroupPoint(group.id,{ "name":value }).then(
                (res)=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.editGroupId = 0;
                    this.getInit();
                },
                err=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);

                }
            );
    }
    
    setDeleteGroup(groupId)
    {
        this.deleteGroupId = groupId;
        this.modalService.open("deleteGroupModal");  
    }
    
    deleteGroup()
    {
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.form.deletePointGroup(this.deleteGroupId).then(
                (res)=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.editGroupId = 0;
                    this.getInit();
                    this.deleteGroupId = 0;
                    this.closeModal("deleteGroupModal"); 
                },
                err=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);

                }
            );
    }
    
    
}
