﻿import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonDataService } from './../common-data.service';
import { TranslateService } from '@ngx-translate/core';
import { Form } from './../../services/Form';
import { BlockService } from './../../services/BlockService';
import { ThemeService } from './../../services/ThemeService';
import { FieldsService } from './../../services/FieldsService';
import { ClusterService } from './../../services/ClusterService';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../shared/_services/index';
import { FormBuilder,FormControl, FormGroup, Validators, NgForm,FormsModule } from '@angular/forms';
import { FormModule } from './../../models/FormModule';
import { ThemeModule } from './../../models/ThemeModule';
import { SectionModule } from './../../models/SectionModule';
import { SectionClusterModule } from './../../models/SectionClusterModule';
import { SectionThemeModule } from './../../models/SectionThemeModule';
import { VariableFieldRelationsModule } from './../../models/VariableFieldRelationsModule';
import { FieldsModule } from './../../models/FieldsModule';
import { Users } from './../../services/Users';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit 
{
    title;
    idTheme;
    myTheme = new ThemeModule();
    myThemes;
    theSections: SectionModule[] = [];
    myForms;
    myQuestions = [];
    myBlocks;
    myPools;
    isEditingTitleTheme=false;
    currentVariable = {"id":0,"status":0,"fettleGroups":[],"field":null,"fettles":[]};
    currentSection = new SectionModule();
    currentSectionCluster: SectionClusterModule = new SectionClusterModule();
    currentSectionTheme = new SectionThemeModule();
    tmpText = "";
    elementToDelete;
    sections;
    sectionsUniq = [];
    myRelationVariableFields = [];
    auxText;
    currentFieldId;
    currentVariableRelation = new VariableFieldRelationsModule();
    previusField;
    bannedThemesId;
    setVariableInputName;
    setVariableInputId;
    currentPool;
    currentVariableStructureToCopy = {"section":null,"sectionCluster":null,"variable":null};
    currentPreviewText;
    user;
    selectedFile;
    url;
    allTemplates;
    editingTemplate = 0;
    currentTemplate = null;
    currentTemplateImage = null;
    updateTemplateId = null; 
    
    constructor(
    private _commondata: CommonDataService,
    translate: TranslateService,
    public form: Form,
    public router: Router,
    public route: ActivatedRoute,
    public block: BlockService, 
    public fieldService: FieldsService,
    private modalService: ModalService,
    private frmbuilder:FormBuilder,
    private themeService:ThemeService,
    private clusterService:ClusterService,
    public users_model: Users
    ) 
    {
       
        
    }
    
    ngOnInit() 
    {
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.currentVariable.field = {"id":null};   
        this.getInit();
        
    }
    
    getInit()
    {
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.user = this.users_model.getLocalUser();
        
                
        let responsetres = this.themeService.getAllTemplates().then(
            (res:any)=>{
                this.allTemplates = res;
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );

    }
    
    editTemplateName(templateId)
    {
        this.editingTemplate = templateId;
    }
    saveTemplateName(target)
    {
        let response = this.themeService.putTemplate(this.editingTemplate,target.value).then(
            (res:any)=>{

                setTimeout(_ => this._commondata.showLoader(false), 200);
                this.editingTemplate = null;
                this.getInit();
            },
            err=>{
                console.log("form_no");
                setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    selectTempate(templateDocumentId)
    {
        let response = this.themeService.selectTemplate(this.myTheme,templateDocumentId).then(
                (res:any)=>{

                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.getInit();
                },
                err=>{
                    console.log("form_no");
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                }
            );
    }
    
    
    
    editThemeTitle()
    {
        if(!this.isEditingTitleTheme)
        {
            this.isEditingTitleTheme = true;
        }
        else
        {
            let data = {
                "name":this.myTheme.name,
            };
        
            let response = this.themeService.putTheme(this.myTheme.getId(),data).then(
                (res:any)=>{

                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.isEditingTitleTheme = false;
                },
                err=>{
                            console.log("form_no");
                            setTimeout(_ => this._commondata.showLoader(false), 200);
                }
            );
        }
    }
    
    editSectionTitle(section)
    {
        this.tmpText = section.name;
        this.currentSection = section;
        this.modalService.open("editSectionTitleModal");
    }
    
    checkPool(pool)
    {
        if(!this.currentPool)
        {
            return false;
        }
        return this.currentPool.getId()==pool.getId();
    }
    
    activePool(pool)
    {
        setTimeout(_ => this._commondata.showLoader(true), 1);
        this.currentPool = pool;
        console.log(pool);
        setTimeout(_ => this._commondata.showLoader(false),1);
        
    }
    
    getBorderColor(question)
    {
        if(question.status == null || question.status == 0)
        {
            return 'border-green';
        }
        
        if(question.status == 1)
        {
            return 'border-red';
        }
        
        if(question.status == 2)
        {
            return 'border-yellow';
        }
    }
    
    setVariableResultAsATitle(target)
    {
        this.currentSection.name="["+target.options[target.selectedIndex].text+"]";
        this.currentSection.field=target.value;
    }
    
    editSectionTitleModal()
    {
        let data;
        if(this.currentSection.field)
        {
            data = {
                "name":this.currentSection.name,
                "field":this.currentSection.field,
                "breakPage":this.currentSection.breakPage?true:false
            };
        }
        else
        {
            data = {
                "name":this.currentSection.name,
                "breakPage":this.currentSection.breakPage?true:false
            };
        }
        
        
        let response = this.themeService.putSection(this.currentSection.getId(),data).then(
            (res:any)=>{
                this.modalService.close("editSectionTitleModal");
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    editSectionClusterTitle(sectionCluster)
    {
        this.tmpText = sectionCluster.name;
        this.currentSectionCluster = sectionCluster;
        this.modalService.open("editSectionClusterTitleModal");
    }
    
    editSectionClusterTitleModal()
    {
        let data = {
            "name":this.currentSectionCluster.name,
            "breakPage":this.currentSectionCluster.breakPage?true:false
        };
        
        let response = this.themeService.putSectionCluster(this.currentSectionCluster.getId(),data).then(
            (res:any)=>{
                this.modalService.close("editSectionClusterTitleModal");
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    deleteSectionCluster()
    {
        let response = this.themeService.deleteSectionCluster(this.currentSectionCluster.getId()).then(
            (res:any)=>{
                this.elementToDelete.parentNode.removeChild(this.elementToDelete);
                
                this.modalService.close("deleteSectionClusterModal");
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    deleteSectionTheme()
    {
        let response = this.themeService.deleteSectionTheme(this.currentSectionTheme.getId()).then(
            (res:any)=>{
                this.elementToDelete.parentNode.removeChild(this.elementToDelete);
                
                this.modalService.close("deleteSectionThemeModal");
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    openModal(id: string) {
        this.modalService.open(id);
    }
    
    closeModal(id: string) {
        this.modalService.close(id);
    }
    
    changePublicValue()
    {
        
        if(this.myTheme.public == null)
        {
            this.myTheme.public = true
        }
        
            let data = {
                "public":this.myTheme.public,
            };
        
            let response = this.themeService.putTheme(this.myTheme.getId(),data).then(
                (res:any)=>{

                    this.myTheme.public = res.public;
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                },
                err=>{
                            console.log("form_no");
                            setTimeout(_ => this._commondata.showLoader(false), 200);
                }
            );
        
    }
    
    onFileChanged(event) 
    {
        this.selectedFile = event.target.files[0];
        if (event.target.files && event.target.files[0]) 
        {
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            reader.onload = (event) => 
            {
                // called once readAsDataURL is completed
                this.url = reader.result;
                this.uploadFile();
            }
        }
    }

    
    uploadFile() 
    {
        setTimeout(_ => this._commondata.showLoader(true), 10);
        if(this.updateTemplateId == null)
        {
            this.themeService.uploadDocument(this.selectedFile).then(
                (data : any)=>{
                    console.log(data);

                    this.getInit();
                    },
                    error=>{
                      console.log(error);
                    }
                );
        }
        else
        {
            this.themeService.updateTemplateVariable(this.updateTemplateId,this.selectedFile).then(
                (data : any)=>{
                    console.log(data);

                    this.getInit();
                    },
                    error=>{
                      console.log(error);
                    }
                );
            this.updateTemplateId = null;    
        }    
        
    }
    
    onImgChanged(event) 
    {
        this.selectedFile = event.target.files[0];
        if (event.target.files && event.target.files[0]) 
        {
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            reader.onload = (event) => 
            {
                // called once readAsDataURL is completed
                this.url = reader.result;
                this.uploadImg();
            }
        }
    }

    
    uploadImg() {
        
        this.themeService.uploadTemplateDocumentImg(this.currentTemplateImage,this.selectedFile).then(
            (data : any)=>{
                console.log(data);

                this.getInit();
                },
                error=>{
                  console.log(error);
                }
            );
        
    }
    
    setCurrentTemplate(templateId)
    {
        this.currentTemplateImage = templateId;
    }
    
    isBlock(str)
    {
        return str.includes("BLOCK_");
    }
    nameBlock(str)
    {
        return str.replace("BLOCK_", "");
    }
    updateVariables(templateId)
    {
        this.updateTemplateId = templateId;
    }
    publicTempate(template)
    {
        if(template.public)
        {
            template.public = false;
        }
        else
        {
            template.public = true;
        }
        let data = {
            "id":template.id,
            "public":template.public
        }
        this.themeService.putUpdateTemplate(data).then(
            (data : any)=>{
                console.log(data);

                this.getInit();
                },
                error=>{
                  console.log(error);
                }
            );
        
    }
    colorIcon(template)
    {
        if(template.public)
        {
            return "color-red";
        }
        return "";
    }        
}
