﻿import { Component, OnInit } from '@angular/core';
import { CommonDataService } from './../common-data.service';
import { Form } from './../../services/Form';
import { FieldsService } from './../../services/FieldsService';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../shared/_services/index';
import { Users } from './../../services/Users';

import * as shape from 'd3-shape';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

    myforms = [];
    formDeleteId;
    public user;
    constructor(
    private _commondata: CommonDataService,
    public form: Form,
    public router: Router,
    public route: ActivatedRoute,
    private modalService: ModalService,
    public fieldService: FieldsService,
    public user_model:Users
    ) { }
    ngOnInit()
    {
        this.onInit();
    }
    onInit() 
    {
        this.getInit();
    }
    
    getInit()
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let response = this.form.getMyFormsSimple().then(
            (res:any)=>{
               this.myforms = res;  
               console.log(this.myforms);
               setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
    }

    createForm()
    {
        let response = this.form.crearForm().then(
            (res)=>{
                let nameGroup="Cuestionario";
                this.fieldService.createGroup({"name"  :nameGroup ,"form": res["id"]+""}).then(
                        (secondRes)=>{
                            console.log(secondRes["id"]);
                            this.router.navigate(['/form/editForm/'+res["id"]]);
                            return;
                        },
                        err=>{
                            setTimeout(_ => this._commondata.showLoader(false), 200);

                        }
                    );
                
            },
            err=>{
                        console.log("form_no");

            }
        );
    }
    
    deleteFormModal(idForm)
    {
        this.modalService.open("deleteFormModal");
        this.formDeleteId = idForm;
    }  
    closeModal(id: string) {
        this.modalService.close(id);
    }  
    
    deleteForm()
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let response = this.form.deleteForm(this.formDeleteId).then(
            (res)=>{
                this.modalService.close("deleteFormModal");
                this.onInit();
            },
            err=>{
                        console.log("form_no");

            }
        );
    }
    getFormatedDate(date)
    {
        if(date==null){
            return "";
        }
        var d = new Date(date);
        
        return d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear();
    }
    
    getEditPublic()
    {
        this.user = this.user_model.getLocalUser();
        return this.user.getCurrentTarrif()==4;
    }
    
    changePublic(form)
    {
        console.log(form.public);
        if(form.public==1)
        {
            form.public=0;
        }
        else
        {
            form.public=1;
        }
        console.log(form.public);
        let response = this.form.putFormPublic(form).then(
            (res:any)=>{
                this.getInit();
            },
            err=>{

            }
        );
    }
    
    colorIcon(form)
    {
        if(form.public == 1)
        {
            return "ti-user"; 
        }
        else
        {
            return "ti-user secondary-one";
        }
       
    }
    
    cloneForm(formId)
    {
        let response = this.form.cloneForm(formId).then(
            (res:any)=>{
                this.getInit();
            },
            err=>{

            }
        );
    }

}
