﻿import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonDataService } from './../../common-data.service';
import { TranslateService } from '@ngx-translate/core';
import { Form } from './../../../services/Form';
import { BlockService } from './../../../services/BlockService';
import { ThemeService } from './../../../services/ThemeService';
import { FieldsService } from './../../../services/FieldsService';
import { ClusterService } from './../../../services/ClusterService';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../shared/_services/index';
import { FormBuilder,FormControl, FormGroup, Validators, NgForm,FormsModule } from '@angular/forms';
import { FormModule } from './../../../models/FormModule';
import { ThemeModule } from './../../../models/ThemeModule';
import { SectionModule } from './../../../models/SectionModule';
import { SectionClusterModule } from './../../../models/SectionClusterModule';
import { SectionThemeModule } from './../../../models/SectionThemeModule';
import { VariableFieldRelationsModule } from './../../../models/VariableFieldRelationsModule';
import { FieldsModule } from './../../../models/FieldsModule';
import { Users } from './../../../services/Users';

@Component({
  selector: 'app-editTheme',
  templateUrl: './editTheme.component.html',
  styleUrls: ['./editTheme.component.scss']
})
export class EditThemeComponent implements OnInit 
{
    title;
    idTheme;
    myTheme = new ThemeModule();
    myThemes;
    theSections: SectionModule[] = [];
    myForms;
    myQuestions = [];
    myBlocks;
    myPools;
    isEditingTitleTheme=false;
    currentVariable = {"id":0,"status":0,"fettleGroups":[],"field":null,"fettles":[]};
    currentSection = new SectionModule();
    currentSectionCluster: SectionClusterModule = new SectionClusterModule();
    currentSectionTheme = new SectionThemeModule();
    tmpText = "";
    elementToDelete;
    sections;
    sectionsUniq = [];
    myRelationVariableFields = [];
    auxText;
    currentFieldId;
    currentVariableRelation = new VariableFieldRelationsModule();
    previusField;
    bannedThemesId;
    setVariableInputName;
    setVariableInputId;
    currentPool;
    currentVariableStructureToCopy = {"section":null,"sectionCluster":null,"variable":null};
    currentPreviewText;
    user;
    selectedFile;
    url;
    allTemplates;
    editingTemplate = 0;
    currentTemplate = null;
    currentTemplateImage = null;
    updateTemplateId = null; 
    
    constructor(
    private _commondata: CommonDataService,
    translate: TranslateService,
    public form: Form,
    public router: Router,
    public route: ActivatedRoute,
    public block: BlockService, 
    public fieldService: FieldsService,
    private modalService: ModalService,
    private frmbuilder:FormBuilder,
    private themeService:ThemeService,
    private clusterService:ClusterService,
    public users_model: Users
    ) 
    {
       
        
    }
    
    ngOnInit() 
    {
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.currentVariable.field = {"id":null};   
        this.getInit();
        
    }
    
    getInit()
    {
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.user = this.users_model.getLocalUser();
        this.route.params.subscribe(
            (resp: any[]) =>
            {
                if(resp["idTheme"]>0)
                {
                    this.idTheme = resp["idTheme"];
                    this.getTheme(this.idTheme);
                }
                if (this.idTheme === false)
                {
                    this.router.navigate(['/theme/']);
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                }
                
                let responsetres = this.themeService.getAllTemplatesPublic().then(
                    (res:any)=>{
                        this.allTemplates = res;
                        setTimeout(_ => this._commondata.showLoader(false), 200);
                    },
                    err=>{
                                console.log("form_no");
                                setTimeout(_ => this._commondata.showLoader(false), 200);
                    }
                );
                
                
//                this.preventRecursiveTheme(this.idTheme);
                
//                let responseone = this.clusterService.getMyPool().then(
//                    (res:any)=>{
//                        this.myPools = res;
//                        console.log(this.myPools);
//                        setTimeout(_ => this._commondata.showLoader(false), 200);
//                    },
//                    err=>{
//                                console.log("form_no");
//                                setTimeout(_ => this._commondata.showLoader(false), 200);
//                    }
//                );
//                
//                let responsedos = this.clusterService.getMyClusterOrphan().then(
//                    (res:any)=>{
//                        console.log(res);
//                        this.myBlocks = res;
//                        setTimeout(_ => this._commondata.showLoader(false), 200);
//                    },
//                    err=>{
//                                console.log("form_no");
//                                setTimeout(_ => this._commondata.showLoader(false), 200);
//                    }
//                );
//                
//                let responsetres = this.themeService.getMyThemes().then(
//                    (res:any)=>{
//                        console.log(res);
//                        this.myThemes = res;
//                        setTimeout(_ => this._commondata.showLoader(false), 200);
//                    },
//                    err=>{
//                                console.log("form_no");
//                                setTimeout(_ => this._commondata.showLoader(false), 200);
//                    }
//                );
                
            }
        );

    }
    
    editTemplateName(templateId)
    {
        this.editingTemplate = templateId;
    }
    saveTemplateName(target)
    {
        let response = this.themeService.putTemplate(this.editingTemplate,target.value).then(
                (res:any)=>{

                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.editingTemplate = null;
                    this.getInit();
                },
                err=>{
                    console.log("form_no");
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                }
            );
    }
    
    selectTempate(templateDocumentId)
    {
        let response = this.themeService.selectTemplate(this.myTheme,templateDocumentId).then(
                (res:any)=>{

                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.getInit();
                },
                err=>{
                    console.log("form_no");
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                }
            );
    }
    
    getTheme(idTheme)
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let response = this.themeService.getTheme(idTheme).then(
            (theme:any)=>{
                
                if(theme.templateDocument)
                {
                    theme.templateDocument.variableTemplates.sort(function(a,b){
                                        console.log(b.sort);
                                                return a.sort - b.sort;

                                    });
                }
                console.log(theme);
                this.myTheme = theme;
                this.theSections = this.myTheme.getSections();
                this.sections = this.myTheme.getSections();
//                if(false)
//                {
//                    this.myQuestions = [];
//                    for(let band of theme.form.bands)
//                    {
//                        for(let field of band.fields)
//                        {
//                            this.myQuestions.push(field);
//                        }
//                    }
//                    setTimeout(_ => this._commondata.showLoader(false), 200);
//                }
//                else
                {
                    let response = this.form.getMyFormsSimple().then(
                        (res:any)=>{
                            console.log(res);
                            this.myForms = res;
                            this.myQuestions = [];
                            for(let band of theme.form.bands)
                            {
                                for(let field of band.fields)
                                {
                                    this.myQuestions.push(field);
                                }
                            }
                            setTimeout(_ => this._commondata.showLoader(false), 200);
                        },
                        err=>{
                                    console.log("form_no");
                                    setTimeout(_ => this._commondata.showLoader(false), 200);
                        }
                    );
                }
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    editThemeTitle()
    {
        if(!this.isEditingTitleTheme)
        {
            this.isEditingTitleTheme = true;
        }
        else
        {
            let data = {
                "name":this.myTheme.name,
            };
        
            let response = this.themeService.putTheme(this.myTheme.getId(),data).then(
                (res:any)=>{

                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.isEditingTitleTheme = false;
                },
                err=>{
                            console.log("form_no");
                            setTimeout(_ => this._commondata.showLoader(false), 200);
                }
            );
        }
    }
    
    editSectionTitle(section)
    {
        this.tmpText = section.name;
        this.currentSection = section;
        this.modalService.open("editSectionTitleModal");
    }
    
    checkPool(pool)
    {
        if(!this.currentPool)
        {
            return false;
        }
        return this.currentPool.getId()==pool.getId();
    }
    
    activePool(pool)
    {
        setTimeout(_ => this._commondata.showLoader(true), 1);
        this.currentPool = pool;
        console.log(pool);
        setTimeout(_ => this._commondata.showLoader(false),1);
        
    }
    
    getBorderColor(question)
    {
        if(question.status == null || question.status == 0)
        {
            return 'border-green';
        }
        
        if(question.status == 1)
        {
            return 'border-red';
        }
        
        if(question.status == 2)
        {
            return 'border-yellow';
        }
    }
    
    setVariableResultAsATitle(target)
    {
        this.currentSection.name="["+target.options[target.selectedIndex].text+"]";
        this.currentSection.field=target.value;
    }
    
    editSectionTitleModal()
    {
        let data;
        if(this.currentSection.field)
        {
            data = {
                "name":this.currentSection.name,
                "field":this.currentSection.field,
                "breakPage":this.currentSection.breakPage?true:false
            };
        }
        else
        {
            data = {
                "name":this.currentSection.name,
                "breakPage":this.currentSection.breakPage?true:false
            };
        }
        
        
        let response = this.themeService.putSection(this.currentSection.getId(),data).then(
            (res:any)=>{
                this.modalService.close("editSectionTitleModal");
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    editSectionClusterTitle(sectionCluster)
    {
        this.tmpText = sectionCluster.name;
        this.currentSectionCluster = sectionCluster;
        this.modalService.open("editSectionClusterTitleModal");
    }
    
    editSectionClusterTitleModal()
    {
        let data = {
            "name":this.currentSectionCluster.name,
            "breakPage":this.currentSectionCluster.breakPage?true:false
        };
        
        let response = this.themeService.putSectionCluster(this.currentSectionCluster.getId(),data).then(
            (res:any)=>{
                this.modalService.close("editSectionClusterTitleModal");
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    deleteSectionCluster()
    {
        let response = this.themeService.deleteSectionCluster(this.currentSectionCluster.getId()).then(
            (res:any)=>{
                this.elementToDelete.parentNode.removeChild(this.elementToDelete);
                
                this.modalService.close("deleteSectionClusterModal");
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    deleteSectionTheme()
    {
        let response = this.themeService.deleteSectionTheme(this.currentSectionTheme.getId()).then(
            (res:any)=>{
                this.elementToDelete.parentNode.removeChild(this.elementToDelete);
                
                this.modalService.close("deleteSectionThemeModal");
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    deleteSectionClusterTitle(sectionCluster,elementToDelete)
    {
        this.currentSectionCluster = sectionCluster;
        this.elementToDelete = elementToDelete;
        this.modalService.open("deleteSectionClusterModal");
    }
    
    deleteSectionThemeTitle(sectionTheme,elementToDelete)
    {
        this.currentSectionTheme = sectionTheme;
        console.log(this.currentSectionTheme);
        this.elementToDelete = elementToDelete;
        this.modalService.open("deleteSectionThemeModal");
    }
    
    cancelSectionClusterTitleModal()
    {
        this.currentSectionCluster.name = this.tmpText;
    }
    
    deleteSection()
    {
        let response = this.themeService.deleteSection(this.currentSection.getId()).then(
            (res:any)=>{
                console.log(this.elementToDelete);
                console.log(this.elementToDelete.parentNode);
                this.elementToDelete.parentNode.removeChild(this.elementToDelete);
                
                this.modalService.close("deleteSectionModal");
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    deleteSectionTitle(section,elementToDelete)
    {
        this.currentSection = section;
        this.elementToDelete = elementToDelete;
        this.modalService.open("deleteSectionModal");
    }
    
    cancelSectionTitleModal()
    {
        this.currentSection.name = this.tmpText;
    }
    
    openModal(id: string) {
        this.modalService.open(id);
    }
    
    closeModal(id: string) {
        this.modalService.close(id);
        this.getTheme(this.idTheme);
    }
    
    getQuestionsForm(target)
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let response = this.themeService.putFormOnTheme(this.myTheme.getId(),target.value).then(
            (res:any)=>{
                let response = this.themeService.getTheme(this.myTheme.getId()).then(
                    (theme:any)=>{
                        console.log(theme);
                        this.myTheme = theme;
                        this.theSections = this.myTheme.getSections();
                        this.sections = this.myTheme.getSections();
                        if(theme.form)
                        {
                            this.myQuestions = [];
                            for(let band of theme.form.bands)
                            {
                                for(let field of band.fields)
                                {
                                    this.myQuestions.push(field);
                                }
                            }

                        }
                        
                        setTimeout(_ => this._commondata.showLoader(false), 200);
                    },
                    err=>{
                                console.log("form_no");
                                setTimeout(_ => this._commondata.showLoader(false), 200);
                    }
                );
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    createSection()
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let lthis = this;
        let response = this.themeService.createSection(this.idTheme).then(
            (res:any)=>{
                
                
                lthis.getTheme(lthis.idTheme);
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    createSubSection(section)
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let lthis = this;
        let response = this.themeService.createSubSection(this.idTheme,section).then(
            (res:any)=>{
                
                
                lthis.getTheme(lthis.idTheme);
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    setSectionToCluster(section,cluster)
    {
        let data = {
            "section":section.getId()+"",
            "cluster":cluster.id+"",
            "name":cluster.name
        };
        setTimeout(_ => this._commondata.showLoader(true), 10);
        let response = this.themeService.setSectionToCluster(data).then(
            (res:any)=>{
                this.getTheme(this.idTheme);
                setTimeout(_ => this._commondata.showLoader(false), 10);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    setSectionToTheme(section,theme)
    {
        let data = {
            "themeName": theme.name,
            "themeIdentification": this.idTheme,
            "theme": theme.id,
            "section": section.getId()
        };
        
        console.log("-------------------");
        console.log(data);
        console.log("-------------------");
        
        setTimeout(_ => this._commondata.showLoader(true), 10);
        let response = this.themeService.setSectionToTheme(data).then(
            (res:any)=>{
                this.getTheme(this.idTheme);
                this.preventRecursiveTheme(this.idTheme);
                setTimeout(_ => this._commondata.showLoader(false), 10);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    preventRecursiveTheme(themeId)
    {
       
        setTimeout(_ => this._commondata.showLoader(true), 10);
        let response = this.themeService.preventRecursiveTheme(themeId).then(
            (res:any)=>{
                this.bannedThemesId = res;
                setTimeout(_ => this._commondata.showLoader(false), 10);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    checkRecursivity(subtheme)
    {
        return  !this.bannedThemesId.includes(""+subtheme.id)
    }
    
    selectedForm(id)
    {
        if(this.myTheme.form == null)
        {
            return null;
        }
        if(this.myTheme.form.id == "")
        {
            return null;
        }
        if(this.myTheme.form.id == id)
        {
            return "selected";
        }
        
        return null;
    }
    
    cancelCurrentForm()
    {
        this.modalService.open("unLinkFormModal");
    }
    
    cancelCurrentFormModal()
    {
//        this.myQuestions = [];
        let data = {
            "form":""
        };
        setTimeout(_ => this._commondata.showLoader(true), 10);
        let response = this.themeService.deleteFormFromTheme(this.myTheme.getId()).then(
            (res:any)=>{
                this.getTheme(this.myTheme.getId())
    
                setTimeout(_ => this._commondata.showLoader(false), 10);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    getMyFormName()
    {
        return this.myTheme.form!=null?this.myTheme.form.name:'';
    }
    
    editVariable(variables)
    {
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.currentVariable = variables;
        let response = this.themeService.loadVariableField(this.currentVariable.id).then(
            (res:any)=>{
                this.currentVariable.field = res;
                let response = this.themeService.loadFettleGroup(this.currentVariable.id).then(
                    (res:any)=>{
                        this.currentVariable.fettleGroups = res;
                        this.modalService.open("asignamentModal");
                        setTimeout(_ => this._commondata.showLoader(false), 10);
                    },
                    err=>{
                                console.log("form_no");
                                setTimeout(_ => this._commondata.showLoader(false), 200);
                    }
                );
                
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
        
        
        
    }
    getSelectedQuestionRelation(questionId)
    {
        if(this.currentVariable)
        {
            if(this.currentVariable.field!=null)
            {
                if(questionId==this.currentVariable.field.id)
                {
                    return true;
                }
            }
        }
        return null
        
    }
    
    currentStatusRelation()
    {
        if(this.currentVariable)
        {
            if(this.currentVariable.status==1)
            {
                return 1;
            }
            if(this.currentVariable.status==2)
            {
                return 2;
            }
        }
        return 0
    }
    
    getCurrentParentRelation()
    {
        if(this.currentVariableRelation.getParent()!=null)
        {
            for(let section of this.myTheme.getSections())
            {
                for(let sectionCluster of section.getSectionClusters())
                {
                    for(let variableFieldRelation of sectionCluster.getVariableFieldRelations())
                    {
                        if(variableFieldRelation.getId()==this.currentVariableRelation.getParent().getId())
                        {
                            this.currentVariableStructureToCopy.section = section;
                            this.currentVariableStructureToCopy.sectionCluster = sectionCluster;
                            this.currentVariableStructureToCopy.variable = variableFieldRelation;
                            return 2;
                        }
                    }
                }
            }

        }
    }

    
    getColorBallModal(status)
    {
        if(this.currentVariable)
        {
            if((this.currentVariable.status == null || this.currentVariable.status == 0) && status==0)
            {
                return "ball-green";
            }

            if(this.currentVariable.status == 1 && status==1)
            {
                return "ball-yellow";
            }
            if(this.currentVariable.status == 2 && status==2)
            {
                return "ball-blue";
            }
        }
        return "ball-grey";
    }
    
    getColorBallVariable(variable)
    {
        if(variable)
        {
            if((/*relation.getStatus() == null || */ variable.status == 0) )
            {
                return "ball-green";
            }

            if(variable.status == 1)
            {
                return "ball-yellow";
            }
            
            if(variable.status == 2)
            {
                return "ball-blue";
            }
        }
        return "ball-grey";
    }
    
    conditionalSectionModal(variables)
    {
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.currentVariable = variables;
        
        setTimeout(_ => this._commondata.showLoader(true), 10);
        let response = this.themeService.loadFettle(this.currentVariable.id).then(
            (res:any)=>{
                this.currentVariable.fettles = res;
                this.modalService.open("editConditionalModal");
                setTimeout(_ => this._commondata.showLoader(false), 10);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
        
        setTimeout(_ => this._commondata.showLoader(false), 10);
    }
    
    changeCurrentStatus(status)
    {
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.currentVariable.status = status;
        setTimeout(_ => this._commondata.showLoader(true), 10);
        let response = this.themeService.putVariable(this.currentVariable.id,{"status":this.currentVariable.status}).then(
            (res:any)=>{
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    
    saveVariableFieldRelation()
    {
        if(this.currentVariable.field)
        {
            this.currentVariableRelation.saveField();
            let data = {
                "field" : ""+this.currentVariable.field.id
            };
            setTimeout(_ => this._commondata.showLoader(true), 10);
            let response = this.themeService.setVariableRelation(this.currentVariable.id,data).then(
                (res:any)=>{
                    this.getTheme(this.idTheme);
                    this.modalService.close("asignamentModal");
                    setTimeout(_ => this._commondata.showLoader(false), 10);
                },
                err=>{
                            console.log("form_no");
                            setTimeout(_ => this._commondata.showLoader(false), 10);
                }
                );
        }
    }
    setVariableFieldRelation(fieldId)
    {
        for(let question of this.myQuestions)
        {
            if(fieldId == question.id)
            {
                let field = new FieldsModule();
                field.initialize(question);
                this.currentVariable.field = field;
            }
        }
        
    }
    
    getRelationVariableFields(sectionCluster,variable)
    {
        for(let relation of sectionCluster.getVariableFieldRelations() )
        {
            if(relation.getVariable().getId() == variable.getId())
            {
                return relation;
            }
        }
        return false; 
    }
    myRelationCheck()
    {
        console.log(this.myRelationVariableFields);
    }
    
    getRelationQuestion(sectionCluster,variable)
    {
        let relation = this.getRelationVariableFields(sectionCluster,variable);
        
        if(relation==false)
        {
            return "";
        }
        if(relation.getStatus()==1)
        {
            return "";
        }
        
                
        return relation.getField().getQuestion();
           
    }
    
    getRelationQuestionQuotes(sectionCluster,variable)
    {
        let result = this.getRelationQuestion(sectionCluster,variable);
        if(result != "" && result != null)
        {
            return "["+result+"]";
        }
        
        return "";
    }
    
    logicAllSelect()
    {
        let selectLogic = document.querySelectorAll(".selectlogic");
        
        for(let i = 0; i < selectLogic.length; i++)
        {
            console.log(selectLogic[i]);
            if((<HTMLOptionElement>selectLogic[i]).value == "")
            {
                return false;
            }
        }
        
        return true;
    }
    addConditionGroup()
    {
        setTimeout(_ => this._commondata.showLoader(true), 10);
        let response = this.themeService.newConditionGroupRelation({"variableTemplate":this.currentVariable.id}).then(
            (res:any)=>{
                setTimeout(_ => this._commondata.showLoader(false), 10);
                this.currentVariable.fettleGroups.push(res);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 10);
            }
        );
    }
    
    addCondition(fettleGroup)
    {
        let response = this.themeService.newConditionFettle({"cond":"==","fettleGroup":fettleGroup.id}).then(
            (fettle:any)=>{
                setTimeout(_ => this._commondata.showLoader(false), 10);
                fettleGroup.fettes.push(fettle);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 10);
            }
        );
    }
    
    deleteLine(fettleGroup,fettle,i)
    {
        
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.fieldService.deleteCondition(fettle.id).then(
            (res)=>
            {
                setTimeout(_ => this._commondata.showLoader(false), 200);
                fettleGroup.fettes.splice(i,1);

            },
            err=>{
                setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
                    
    }
       
    conditionalQuestionChange(fettle, target)
    {
        fettle.line = target.value;
        console.log(this.myQuestions);
        for(let field of this.myQuestions)
        {
            
            if(fettle.line==field.id)
            {
                if(field.parent)
                {
                    field = field.parent;
                }
                console.log(field);
                if(field.choice != null)
                {
                    fettle.opts = field.choice.opts;
                }
                else
                {
                    fettle.opts = [];
                }
            }
        }
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.themeService.putFettle(fettle.id,{"line":{"id":fettle.line},"opts":fettle.opts}).then(
            (res)=>
            {
                setTimeout(_ => this._commondata.showLoader(false), 10);

            },
            err=>{
                setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
    }
    conditionalComparationChange(fettle,target)
    {
        fettle.cond = target.value;
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.themeService.putFettle(fettle.id,{"cond":fettle.cond}).then(
            (res)=>
            {
                setTimeout(_ => this._commondata.showLoader(false), 10);

            },
            err=>{
                setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
    }
    
    saveResponseCondition(fettle,target)
    {
        fettle.response = target.value;
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.themeService.putFettle(fettle.id,{"response":fettle.response}).then(
            (res)=>
            {
                setTimeout(_ => this._commondata.showLoader(false), 10);

            },
            err=>{
                setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
    }
    saveLogicCondition(fettle,target)
    {
        fettle.logic = target.value;
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.themeService.putFettle(fettle.id,{"logic":fettle.logic}).then(
            (res)=>
            {
                setTimeout(_ => this._commondata.showLoader(false), 10);

            },
            err=>{
                setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
    }
    
    changeConditionValue(fettleGroup,target)
    {
        fettleGroup.value = target.value;
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.themeService.putFettleGroup(fettleGroup.id,{"value":fettleGroup.value}).then(
            (res)=>
            {
                setTimeout(_ => this._commondata.showLoader(false), 10);

            },
            err=>{
                setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
    }
    
    deleteFettleGroup(fettleGroup,i)
    {
        this.currentVariable.fettleGroups.splice(i,1);
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.themeService.deleteFettleGroup(fettleGroup.id).then(
            (res)=>
            {
                setTimeout(_ => this._commondata.showLoader(false), 10);

            },
            err=>{
                setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
    }
    
    changeConditionSection(status)
    {
        this.currentVariable.status = status;
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.themeService.putVariable(this.currentVariable.id,{"status":this.currentVariable.status}).then(
            (res)=>
            {
                setTimeout(_ => this._commondata.showLoader(false), 10);

            },
            err=>{
                setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
    }
    
    getSectionBallColor(status)
    {
        if(status==null || status==0)
        {
            return 'ball-green ml-10';
        }
        if(status==1)
        {
            return 'ball-yellow ml-10';
        }
        if(status==2)
        {
            return 'ball-red ml-10';
        }
    }
    
    conditionalSectionChange(fettle,target,mod)
    {
        let data;
        console.log(this.myQuestions);
        switch(mod){
            case "question":
                fettle.line = target.value;
                for(let field of this.myQuestions)
                {
                    if(fettle.line==field.id)
                    {
                        if(field.parent)
                        {
                            field = field.parent;
                        }
                        
                        if(field.choice != null)
                        {
                            fettle.opts = field.choice.opts;
                        }
                        else
                        {
                            fettle.opts.push([]);
                        }
                    }
                }
                data = {"line":{"id":fettle.line},"opts":fettle.opts};
                break;
            case "condition":
                fettle.cond = target.value;
                data = {"cond":fettle.cond};
                break;
            case "response":
                fettle.response = target.value;
                data = {"response":fettle.response};
                break;
            case "logic":
                fettle.logic = target.value;
                data = {"logic":fettle.logic};
                break;    
        }
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.themeService.putFettle(fettle.id,data).then(
            (res)=>
            {
                setTimeout(_ => this._commondata.showLoader(false), 10);

            },
            err=>{
                setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
    }
    
    deleteSectionCondition(currentVariable,fettle,i)
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.fieldService.deleteCondition(fettle.id).then(
            (res)=>
            {
                setTimeout(_ => this._commondata.showLoader(false), 200);
                currentVariable.fettles.splice(i,1);

            },
            err=>{
                setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
    }
    
    deleteSectionClusterCondition(currentSectionCluster,fettle)
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.fieldService.deleteCondition(fettle.line).then(
            (res)=>
            {
                setTimeout(_ => this._commondata.showLoader(false), 200);
                currentSectionCluster.removeFettle(fettle);

            },
            err=>{
                setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
    }
    
    conditionalSectionClusterModal(sectionCluster)
    {
        console.log(sectionCluster);
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.currentSectionCluster = sectionCluster;
        this.themeService.getSectionCluster(sectionCluster.id).then(
            (res: any)=>
            {
                this.currentSectionCluster = res;
                setTimeout(_ => this._commondata.showLoader(false), 10);

            },
            err=>{
                setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
        this.modalService.open("editConditionalSectionClusterModal");
    }
    
    changeConditionSectionCluster(status)
    {
        this.currentSectionCluster.setStatus(status);
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.themeService.putSectionCluster(this.currentSectionCluster.getId(),{"status":this.currentSectionCluster.getStatus()}).then(
            (res)=>
            {
                setTimeout(_ => this._commondata.showLoader(false), 10);

            },
            err=>{
                setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
    }
    
    addConditionSection()
    {
        let response = this.themeService.newConditionFettle({"cond":"==","variable":this.currentVariable.id}).then(
            (fettle:any)=>{
                setTimeout(_ => this._commondata.showLoader(false), 10);
                this.currentVariable.fettles.push(fettle);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 10);
            }
        );
    }
    
    addConditionSectionCluster()
    {
        let response = this.themeService.newConditionFettle({"cond":"==","sectionCluster":this.currentSectionCluster.getId()}).then(
            (fettle:any)=>{
                setTimeout(_ => this._commondata.showLoader(false), 10);
                this.currentSectionCluster.addFettle(fettle);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 10);
            }
        );
    }
    
    changeGroupFettleStatus(fettleGroup)
    {
        
        if(fettleGroup.status !="1")
        {
            fettleGroup.status = "1";
        }
        else
        {
            fettleGroup.status = "0";
        }
        
        let response = this.themeService.putConditionFettle(fettleGroup.id,{"status":fettleGroup.status}).then(
            (fettle:any)=>{
                setTimeout(_ => this._commondata.showLoader(false), 10);
//                this.currentSectionCluster.addFettle(fettle);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 10);
            }
        );
    }
    
    conditionalFettleGroupQuestionChange(fettleGroup,target)
    {
            fettleGroup.line = {"id":null};
        
            fettleGroup.templateDocument = {"id":null};
            
        (<HTMLSelectElement>document.getElementById("selectConditionTemplate")).value = "";
        fettleGroup.line.id = target.value;
        let response = this.themeService.putConditionFettle(fettleGroup.id,{"templateDocument":null,"field":{"id":fettleGroup.line.id}}).then(
            (fettle:any)=>{
                setTimeout(_ => this._commondata.showLoader(false), 10);
//                this.currentSectionCluster.addFettle(fettle);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 10);
            }
        );
    }
    
    conditionalFettleGroupDocChange(fettleGroup,target)
    {
        (<HTMLSelectElement>document.getElementById("selectConditionField")).value = "";
        fettleGroup.line = {"id":null};
        if(fettleGroup.templateDocument == null)
        {
            fettleGroup.templateDocument = {"id":null};
        }
        fettleGroup.templateDocument.id = target.value;
        let response = this.themeService.putConditionFettle(fettleGroup.id,{"field":null,"templateDocument":{"id":target.value}}).then(
            (fettle:any)=>{
                setTimeout(_ => this._commondata.showLoader(false), 10);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 10);
            }
        );
    }
    
    ////// SORT Section//////////
    
    searchNextCorrectSection(position,command,sections,parent)
    {
        let increase = 0;
        if(command=="up")
        {
            increase = -1;
        }
        if(command=="down")
        {
            increase = 1;
        }
        
        for(let i=position+increase;((i<=sections.length)&&(i>=0));i=i+increase)
        {
            if(sections[i] && (parent == sections[i].hasParent()))
            {
                
                return sections[i];
                
            }
        }
        return false;    
        
    }
    moveSectionSort(section,str,sections,parent)
    {
        let findSection;
        for(let i=0;i<sections.length;i++)
        {
            if(sections[i]){
                
                    if(sections[i].getId() == section.getId())
                    {
                        if(str=="up")
                        {
                            findSection=this.searchNextCorrectSection(i,"up",sections,parent);
                            if(findSection==false)
                            {
                                return false;
                            }
                        }
                        if(str=="checkUp")
                        {
                            findSection=this.searchNextCorrectSection(i,"up",sections,parent);
                            if(findSection)
                            {
                                return true;
                            }
                        }
                        if(str=="down")
                        {
                            findSection=this.searchNextCorrectSection(i,"down",sections,parent);
                            if(findSection==false)
                            {
                                return false;
                            }
                        }
                        if(str=="checkDown")
                        {
                            findSection=this.searchNextCorrectSection(i,"down",sections,parent);
                            if(findSection)
                            {
                                return true;
                            }
                        }
                    }
                
            }
            else
            {
                return false;
            }
        }
        if(findSection)
        {
            
            setTimeout(_ => this._commondata.showLoader(true), 200);
            this.themeService.putSection(section.getId(),{"sort": findSection.getSort()}).then(
                (res)=>{
                    this.themeService.putSection(findSection.getId(),{"sort": section.getSort()}).then(
                            (res)=>{
                                let response = this.themeService.getTheme(this.myTheme.getId()).then(
                                    (theme:any)=>{
                                        console.log(theme);
                                        this.myTheme = theme;
                                        this.theSections = this.myTheme.getSections();

                                        setTimeout(_ => this._commondata.showLoader(false), 200);
                                    },
                                    err=>{
                                                console.log("form_no");
                                                setTimeout(_ => this._commondata.showLoader(false), 200);
                                    }
                                );
                            },
                            err=>{
                                setTimeout(_ => this._commondata.showLoader(false), 200);

                            }
                        );
                },
                err=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);

                }
            );
        }
        else
        {
            return false;
        }
    }
    
    showText(sectionCluster)
    {
        console.log(sectionCluster);
        
        let response = this.clusterService.getCluster(sectionCluster.getCluster().getId()).then(
            (cluster:any)=>{
                this.currentPreviewText = cluster.getText();
                this.modalService.open("previewClusterModal");
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
        
        
    }
    
    ////// SORT Section-Cluster//////////
    
    searchNextCorrectSectionCluster(theSectionClusters,position,command)
    {
        let increase = 0;
        if(command=="up")
        {
            increase = -1;
        }
        if(command=="down")
        {
            increase = 1;
        }
        for(let i=position+increase;((i<=theSectionClusters.length)&&(i>=0));i=i+increase)
        {
            
            if(theSectionClusters[i])
            {
                
                return theSectionClusters[i];
                
            }
        }
        return false;    
        
    }
    moveSectionClusterSort(section,sectionCluster,str)
    {
        let findSectionCluster;
        let theSectionClusters = section.getSectionClusters();
        for(let i=0;i<theSectionClusters.length;i++)
        {
            if(theSectionClusters[i]){
                
                    if(theSectionClusters[i].getId() == sectionCluster.getId())
                    {
                        if(str=="up")
                        {
                            findSectionCluster=this.searchNextCorrectSectionCluster(theSectionClusters,i,"up");
                            if(findSectionCluster==false)
                            {
                                return false;
                            }
                        }
                        if(str=="checkUp")
                        {
                            findSectionCluster=this.searchNextCorrectSectionCluster(theSectionClusters,i,"up");
                            if(findSectionCluster)
                            {
                                return true;
                            }
                        }
                        if(str=="down")
                        {
                            findSectionCluster=this.searchNextCorrectSectionCluster(theSectionClusters,i,"down");
                            if(findSectionCluster==false)
                            {
                                return false;
                            }
                        }
                        if(str=="checkDown")
                        {
                            findSectionCluster=this.searchNextCorrectSectionCluster(theSectionClusters,i,"down");
                            if(findSectionCluster)
                            {
                                return true;
                            }
                        }
                    }
                
            }
            else
            {
                return false;
            }
        }
        if(findSectionCluster)
        {
            
            setTimeout(_ => this._commondata.showLoader(true), 200);
            this.themeService.putSectionCluster(sectionCluster.getId(),{"sort": findSectionCluster.getSort()}).then(
                (res)=>{
                    this.themeService.putSectionCluster(findSectionCluster.getId(),{"sort": sectionCluster.getSort()}).then(
                            (res)=>{
                                let response = this.themeService.getTheme(this.myTheme.getId()).then(
                                    (theme:any)=>{
                                        console.log(theme);
                                        this.myTheme = theme;
                                        this.theSections = this.myTheme.getSections();

                                        setTimeout(_ => this._commondata.showLoader(false), 200);
                                    },
                                    err=>{
                                                console.log("form_no");
                                                setTimeout(_ => this._commondata.showLoader(false), 200);
                                    }
                                );
                            },
                            err=>{
                                setTimeout(_ => this._commondata.showLoader(false), 200);

                            }
                        );
                },
                err=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);

                }
            );
        }
        else
        {
            return false;
        }
    }
    
    saveVariableCopy()
    {
        if(this.currentVariableStructureToCopy.variable != null)
        {
            let response = this.themeService.setVariableRelation(this.currentVariableRelation.getId(),{"parent":""+this.currentVariableStructureToCopy.variable.getId()}).then(
                (theme:any)=>{

                    this.getTheme(this.idTheme);
                    this.modalService.close("asignamentModal");

                    setTimeout(_ => this._commondata.showLoader(false), 200);
                },
                err=>{
                            console.log("form_no");
                            setTimeout(_ => this._commondata.showLoader(false), 200);
                }
            );
        }
    }
    
    changeSecctionStructure()
    {
        console.log("HERE");
        this.currentVariableStructureToCopy.sectionCluster=null;
        this.currentVariableStructureToCopy.variable=null;
    }
    
    changeSecctionClusterStructure()
    {
        this.currentVariableStructureToCopy.variable=null;
    }
    
    changeSectionBreakPage()
    {
        if(this.currentSection.breakPage)
        {
            this.currentSection.breakPage = 0;
        }
        else
        {
            this.currentSection.breakPage = 1;
        }
        console.log(this.currentSection);
    }
    
    changeSectionClusterBreakPage()
    {
        if(this.currentSectionCluster.breakPage)
        {
            this.currentSectionCluster.breakPage = 0;
        }
        else
        {
            this.currentSectionCluster.breakPage = 1;
        }
        console.log(this.currentSectionCluster);
    }
    
    changeVariableRelationBreakPage()
    {
        if(this.currentVariableRelation.breakPage)
        {
            this.currentVariableRelation.breakPage = 0;
        }
        else
        {
            this.currentVariableRelation.breakPage = 1;
        }
        console.log(this.currentVariableRelation);
    }

    changePublicValue()
    {
        
        if(this.myTheme.public == null)
        {
            this.myTheme.public = true
        }
        
            let data = {
                "public":this.myTheme.public,
            };
        
            let response = this.themeService.putTheme(this.myTheme.getId(),data).then(
                (res:any)=>{

                    this.myTheme.public = res.public;
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                },
                err=>{
                            console.log("form_no");
                            setTimeout(_ => this._commondata.showLoader(false), 200);
                }
            );
        
    }
    
    onFileChanged(event) 
    {
        this.selectedFile = event.target.files[0];
        if (event.target.files && event.target.files[0]) 
        {
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            reader.onload = (event) => 
            {
                // called once readAsDataURL is completed
                this.url = reader.result;
                this.uploadFile();
            }
        }
    }

    
    uploadFile() 
    {
        
        
    }
    
    onImgChanged(event) 
    {
        this.selectedFile = event.target.files[0];
        if (event.target.files && event.target.files[0]) 
        {
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            reader.onload = (event) => 
            {
                // called once readAsDataURL is completed
                this.url = reader.result;
                this.uploadImg();
            }
        }
    }

    
    uploadImg() {
        
        this.themeService.uploadTemplateDocumentImg(this.currentTemplateImage,this.selectedFile).then(
            (data : any)=>{
                console.log(data);

                this.getInit();
                },
                error=>{
                  console.log(error);
                }
            );
        
    }
    
    setCurrentTemplate(templateId)
    {
        this.currentTemplateImage = templateId;
    }
    
    isBlock(str)
    {
        return str.includes("BLOCK_");
    }
    nameBlock(str)
    {
        return str.replace("BLOCK_", "");
    }
    updateVariables(templateId)
    {
        this.updateTemplateId = templateId;
    }
    
    selectedTemplate(id)
    {
        if(this.myTheme.templateDocument == null)
        {
            return null;
        }
        if(this.myTheme.templateDocument.id == "")
        {
            return null;
        }
        if(this.myTheme.templateDocument.id == id)
        {
            return "selected";
        }
        if(this.myTheme.templateDocument.parent == id)
        {
            return "selected";
        }
        
        return null;
    }
    
    viewFettle(variable)
    {
//        this.themeService.getViewFettle(variable.id).then(
//            (data : any)=>{
//                console.log(data);
//                },
//                error=>{
//                  console.log(error);
//                }
//            );
    }    
}
