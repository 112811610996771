import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {SectionClusterModule} from './SectionClusterModule';
import {SectionThemeModule} from './SectionThemeModule';
import {FettleModule} from './FettleModule';
import {FieldsModule} from './FieldsModule';
export class SectionModule extends Base_Model
{

    private id: number = null;
    public name: string = null;
    private sort: number = null;
    public children:SectionModule[] = [];
    public sectionClusters: SectionClusterModule[] = [];
    public sectionThemes: SectionThemeModule[] = [];
    public parent: string = null;
    public status: number = null;
    public field: FieldsModule;
    public fettles: FettleModule[] = [];
    public breakPage: number = 0;
    
    public getId()
    {
        return this.id;
    }
    public getName()
    {
        return this.name;
    }
    
    public getSort()
    {
        if(this.sort == null)
        {
            this.sort = this.id;
        }
        return this.sort;
    }
    
    public hasParent()
    {
        if(this.parent!=null)
        {
            return true;
        }
        return false;
    }
    
    public getStatus()
    {
        return this.status;
    }
    
    public setStatus(status)
    {
        this.status = status;
        return this
    }
    
    public getSectionClusters()
    {
        let sectionClustersAux = [];
        for(let sectionCluster of this.sectionClusters)
        {
            if (!(sectionCluster instanceof SectionClusterModule))
            {
                let sectionClusterAux = new SectionClusterModule();
                sectionClusterAux.initialize(sectionCluster);
                sectionClustersAux.push(sectionClusterAux);
            }
            else
            {
                sectionClustersAux.push(sectionCluster);
            }
            
        }
        this.sectionClusters = sectionClustersAux;
        
        this.sectionClusters.sort(function(a,b){
           return a.getSort()-b.getSort(); 
        });
        
        return this.sectionClusters;
    }
    
    public getSectionTheme()
    {
        let sectionThemeAux = [];
        for(let sectionT of this.sectionThemes)
        {
            if (!(sectionT instanceof SectionThemeModule))
            {
                let sectionTAux = new SectionThemeModule();
                sectionTAux.initialize(sectionT);
                sectionThemeAux.push(sectionTAux);
            }
            else
            {
                sectionThemeAux.push(sectionT);
            }
            
        }
        this.sectionThemes = sectionThemeAux;
        
//        this.sectionClusters.sort(function(a,b){
//           return a.getSort()-b.getSort(); 
//        });
        
        return this.sectionThemes;
    }
    
    public getSections()
    {
        let sectionsAux = [];
        for(let section of this.children)
        {
            if (!(section instanceof SectionModule))
            {
                let sectionAux = new SectionModule();
                sectionAux.initialize(section);
                sectionsAux.push(sectionAux);
            }
            else
            {
                sectionsAux.push(section);
            }
            
        }
        this.children = sectionsAux;
        this.children.sort(function(a,b){
           return a.getSort()-b.getSort(); 
        });
        return this.children;
    }
    
    public getFettles()
    {
        let fettlesAux = [];
        for(let fettle of this.fettles)
        {
            if (!(fettle instanceof FettleModule))
            {
                let fettleAux = new FettleModule();
                fettleAux.initialize(fettle);
                fettlesAux.push(fettleAux);
            }
            else
            {
                fettlesAux.push(fettle);
            }
            
        }
        this.fettles = fettlesAux;
        return this.fettles;
    }
    
    public addPosibility()
    {
        for(let fettle of this.fettles)
        {
            if(fettle.getLogic() == null || fettle.getLogic() == "")
            {
                return false;
            }
        }
        return true;
    }
    public addFettle(fettle)
    {
        this.getFettles();
        this.fettles.push(fettle);
    }
    
    public removeFettle(fettle)
    {
        this.getFettles();
        let fettlesAux = [];
        for(let fettleaux of this.fettles)
        {
            if(fettleaux.getId()!=fettle.getId())
            {
                fettlesAux.push(fettleaux);
            }
        }
        this.fettles = fettlesAux;
        
        
        return this.fettles;
    }
    
    public getField()
    {
            if (!(this.field instanceof FieldsModule))
            {
                let fieldAux = new FieldsModule();
                fieldAux.initialize(this.field);
                this.field = fieldAux;
            }
            
        return this.field;
    }
    
    public setField(field)
    {   
        this.field = field;
        return this;
    }
    
    public setBreakPage(breakPage)
    {   
        this.breakPage = breakPage;
        return this;
    }
    
    public getBreakPage()
    {   
        return this.breakPage;
    }
    
}