﻿import { Component, OnInit ,Input } from '@angular/core';
import { CommonDataService } from './../../../common-data.service';
import { TranslateService } from '@ngx-translate/core';
import { Form } from './../../../../services/Form';
import { BlockService } from './../../../../services/BlockService';
import { ConfigurationService } from './../../../../services/ConfigurationService';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../shared/_services/index';
import { FormBuilder,FormControl, FormGroup, Validators, NgForm,FormsModule } from '@angular/forms';
import { FieldsService } from './../../../../services/FieldsService';
import { Users } from './../../../../services/Users';
import { FieldsModule } from './../../../../models/FieldsModule';
//import { EditFormComponent } from './../editForm.component';

import * as shape from 'd3-shape';
declare var window: any;
@Component({
  selector: 'app-paragraf',
  templateUrl: './paragraf.component.html',
  styleUrls: ['./paragraf.component.scss']
})

export class ParagrafComponent implements OnInit 
{
//    specialElements = [];
//    specialElementsCount = 0;
    seeOpt = false;
    insertSelectTitle;
    insertDescritpion;
    selectquestion = "";
    //insertIdentifierSelectTitle;
    @Input() currentView;
    @Input() statusField;
    @Input() currentGroupId;
    @Input() idForm;
    @Input() isFix = false;
    fixQuestions;
    currentField;
    currentFixed;
    nameField;
    selectType = "Simple";
    next = false;
    @Input() currentFieldId = 0;
    currentModal = "addField";
    parragraf = "";
    nameError = false;
    copySelects = [];
    allElements = {};
    formParagrafQuestions = {};
    ckeditorContent;
    optObj = {id:null,extra:[],points:0};
    nOfOptions = [this.optObj,this.optObj,this.optObj,this.optObj,this.optObj,this.optObj,this.optObj,this.optObj,this.optObj,this.optObj];
    typeSelect = false;
    globalImg = "";
    special;
    insertDescription;
    selectedImg;
    lowValue;
    highValue;
    constructor(
    private _commondata: CommonDataService,
    translate: TranslateService,
    public form: Form,
    public router: Router,
    public route: ActivatedRoute,
    private modalService: ModalService,
    public fieldsService: FieldsService,
//    public editForm: EditFormComponent,
    public configurationService: ConfigurationService,
    public usersService: Users
    ) 
    { 
        let respuesta = translate.get('Select_question').subscribe(res => { this.selectquestion = res; });
        console.log(this.currentFieldId);
        let url = this.usersService.getUrl();
    }
    ngOnInit() 
    {
        console.log("NGONINIT");
        console.log(this.currentFieldId);
        //document.querySelector("body").addEventListener("editParagrafRefresh", (e:Event) => this.onInit());
        this.getFixed();
        this.onInit();     
    }
    onInit()
    {
        console.log(this.currentFieldId);
//        this.ckeditorContent="";
        if(this.currentFieldId>0)
        {
//            setTimeout(_ => this._commondata.showLoader(true), 200);
            this.currentModal="editFieldModal";
            let response = this.fieldsService.getLine(this.currentFieldId).then(
            (res:any)=>{
                this.currentField = res;
                if(this.statusField=='textPlain' || this.statusField=='line' || this.statusField=='number' || this.statusField=='numberRange' || this.statusField == 'ubication')
                {
                    (<HTMLInputElement>document.getElementById("insertTextPlain")).value = this.currentField.question;
                    if(this.currentField.extra)
                    {
                        this.lowValue = JSON.parse(this.currentField.extra).low;
                        this.highValue = JSON.parse(this.currentField.extra).high;
                    }
                }
                else if(this.statusField=='title_oriented')
                {
                    (<HTMLInputElement>document.getElementById("insertTitlePlain")).value = this.currentField.question;
                }
                else if(this.statusField=='separator')    
                {
                    (<HTMLInputElement>document.getElementById("insertTextPlain")).value = this.currentField.question;
                }
                else if(this.statusField=='fixed')
                {
                    (<HTMLInputElement>document.getElementById("insertFixPlain")).value = this.currentField.question;
                }
                else if(this.statusField=='select' || this.statusField=='switch' )
                {
                    (<HTMLInputElement>document.getElementById("insertSelectTitle")).value = this.currentField.question;
                    this.typeSelect = this.currentField.typeSelect;
                }
                console.log(this.currentField);
                this.insertDescription = this.currentField.description;
                
//                (<HTMLInputElement>document.getElementById("insertDescription")).value = this.currentField.description;
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);

            }
            );
        }
        else
        {
            setTimeout(_ => this._commondata.showLoader(false), 200);
        }
         
    }
    getInit()
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.currentView.getInit();
    }
    
    openModal(id: string) {
        this.modalService.open(id);
        this.statusField = "";
    }

    closeModal(id: string) {
        this.modalService.close(id);
        this.modalService.close('addField');
        this.modalService.close('editFieldModal');
        this.statusField = "";
//        this.specialElements = [];
//        this.specialElementsCount = 0;
        this.selectType = "Simple";
        this.next = false;
        this.parragraf = "";
        this.currentFieldId=0;
        this.getInit();
    }
    
    nextStep()
    {
        this.nameField = (<HTMLInputElement>document.getElementById("parragrafTitle")).value;
        
        if(this.nameField=="")
        {
            this.nameError = true;
            console.log("HERE");
            return;
        }
        else
        {
            this.nameError = false;
        }
                
        this.ckeditorContent=this.parragraf;
               
    }
    
    insertTextPlain(mdlclose)
    {
        let title = "";
        let description = "";
            console.log("entre!");
            console.log(this.idForm);
            title = (<HTMLInputElement>document.getElementById("insertTextPlain")).value;
            description = this.insertDescription;
            console.log(title);
        let auxType = this.statusField;
        let extra = JSON.stringify({"low":this.lowValue,"high":this.highValue});
            //let identifier = (<HTMLInputElement>document.getElementById("insertIdentifierTextPlain")).value = "";
            
        setTimeout(_ => this._commondata.showLoader(true), 200);
        if(this.currentFieldId>0)
        {
            let dataLine = {
                        "question": title,
                        "text": title,
                        "description":description,
                        "auxType": auxType,
                        "extra": extra,
                        //"identifier": identifier,
                        "band": ""+this.currentGroupId,
                        "fixed": this.isFix
                    };
            let response = this.fieldsService.updateLine(this.currentFieldId,dataLine).then(
            (res:FieldsModule)=>{
                console.log(res);
                this.getInit();
                this.closeModal('editFieldModal');
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                console.log("form_no");
            }
        );
        }
        else
        {
            let dataLine = {
                        "question": title,
                        "text": title,
                        "description":description,
                        "auxType": auxType,
                        "extra": extra,
                        //"identifier": identifier,
                        "band": ""+this.currentGroupId,
                        "form": this.idForm,
                        "textarea":{},
                        "fixed": this.isFix
                    };
            let response = this.fieldsService.createLines(dataLine).then(
            (res:FieldsModule)=>{
                console.log(res);
                
                    this.getInit();
                    this.closeModal('addField');
                    setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                console.log("form_no");
            }
        );
        }
//        this.specialElements = [];
//        this.specialElementsCount = 0;
        this.seeOpt = false;
        this.statusField="";
        this.selectType = "Simple";
        this.next = false;
        this.parragraf = "";          
       
    }
    
    insertSeparatorPlain()
    {
        this.special = "separator";
        let title = "";
            console.log("entre!");
            console.log(this.idForm);
            title = (<HTMLInputElement>document.getElementById("insertSeparatorPlain")).value;
            console.log(title);
            //let identifier = (<HTMLInputElement>document.getElementById("insertIdentifierTextPlain")).value = "";
            
        setTimeout(_ => this._commondata.showLoader(true), 200);
        if(this.currentFieldId>0)
        {
            let dataLine = {
                        "question": title,
                        "text": title,
                        "band": ""+this.currentGroupId,
                        "special":this.special,
                        "fixed": this.isFix
                    };
            let response = this.fieldsService.updateLine(this.currentFieldId,dataLine).then(
            (res:FieldsModule)=>{
                console.log(res);
                this.getInit();
                this.closeModal('editFieldModal');
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                console.log("form_no");
            }
        );
        }
        else
        {
            let dataLine = {
                        "question": title,
                        "text": title,
                        "band": ""+this.currentGroupId,
                        "form": this.idForm,
                        "textarea":{},
                        "special":this.special,
                        "fixed": this.isFix
                    };
            let response = this.fieldsService.createLines(dataLine).then(
            (res:FieldsModule)=>{
                console.log(res);
                
                    this.getInit();
                    this.closeModal('addField');
                    setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                console.log("form_no");
            }
        );
        }
//        this.specialElements = [];
//        this.specialElementsCount = 0;
        this.seeOpt = false;
        this.statusField="";
        this.selectType = "Simple";
        this.next = false;
        this.parragraf = "";
    }
    
    insertTitlePlain()
    {
        this.special = "title";
        let title = "";
            console.log("entre!");
            console.log(this.idForm);
            title = (<HTMLInputElement>document.getElementById("insertTitlePlain")).value;
        let description = this.insertDescription;
            console.log(title);
            //let identifier = (<HTMLInputElement>document.getElementById("insertIdentifierTextPlain")).value = "";
            
        setTimeout(_ => this._commondata.showLoader(true), 200);
        if(this.currentFieldId>0)
        {
            let dataLine = {
                        "question": title,
                        "text": title,
                        "description":description,
                        "band": ""+this.currentGroupId,
                        "special":this.special,
                        "fixed": this.isFix
                    };
            let response = this.fieldsService.updateLine(this.currentFieldId,dataLine).then(
            (res:FieldsModule)=>{
                console.log(res);
                this.getInit();
                this.closeModal('editFieldModal');
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                console.log("form_no");
            }
        );
        }
        else
        {
            let dataLine = {
                        "question": title,
                        "text": title,
                        "description":description,
                        "band": ""+this.currentGroupId,
                        "form": this.idForm,
                        "textarea":{},
                        "special":this.special,
                        "fixed": this.isFix
                    };
            let response = this.fieldsService.createLines(dataLine).then(
            (res:FieldsModule)=>{
                console.log(res);
                
                    this.getInit();
                    this.closeModal('addField');
                    setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                console.log("form_no");
            }
        );
        }
//        this.specialElements = [];
//        this.specialElementsCount = 0;
        this.seeOpt = false;
        this.statusField="";
        this.selectType = "Simple";
        this.next = false;
        this.parragraf = "";
    }
    
    insertSelect()
    {
        let options = [];
        let selectedOptions = document.querySelectorAll(".select-options");
        let sele = "";
        let auxType = this.statusField;
                
        let data = [];        
        for(let i=0;i<selectedOptions.length;i++)
        {
            let currentElement = (<HTMLInputElement>selectedOptions[i]);
            let value = currentElement.value;
            let extraValues = document.querySelectorAll(".extra_"+i);
            let extra = [];
            for(let j=0;j<extraValues.length;j++)
            {
                extra.push((<HTMLInputElement>extraValues[j]).value);
            }
            data.push({
                "id": currentElement.getAttribute("data-id"),
                "value": value,
                "img": currentElement.getAttribute("data-img"),
                "text": currentElement.value,
                "extra":extra,
                "points": (<HTMLInputElement>document.getElementById("points_"+i)).value
            });
        }
        setTimeout(_ => this._commondata.showLoader(true), 200);
        if(this.currentFieldId>0)
        {
            let dataLine = {
                        "question": this.insertSelectTitle,
                        "text": this.insertSelectTitle,
                        "band": ""+this.currentGroupId,
                        "form": this.idForm,
                        "description":this.insertDescription,
                        "typeSelect":this.typeSelect,
                        "fixed": this.isFix,
                        "auxType": auxType
                    };
            let response = this.fieldsService.updateLine(this.currentFieldId,dataLine).then(
            (res:FieldsModule)=>{
                let response = this.fieldsService.updateOpts(res.choice.id,data).then(
                (res:FieldsModule)=>{
                    console.log(res);
                    this.getInit();
                    this.closeModal('addField');
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                },
                err=>{
                    console.log("form_no");
                }
            )},
            err=>{
                console.log("form_no");
            }
        );
        }
        else
        {
            let dataLine = {
                        "question": this.insertSelectTitle,
                        "text": this.insertSelectTitle,
                        "band": ""+this.currentGroupId,
                        "form": this.idForm,
                        "description":this.insertDescription,
                        "typeSelect":this.typeSelect,
                        "choice":{
                            "type":this.statusField=="select"?"Simple":"Multiple"
                        },
                        "fixed": this.isFix,
                        "auxType": auxType
                    };
            let response = this.fieldsService.createLines(dataLine).then(
            (res:FieldsModule)=>{
                console.log(res);
                
                let response = this.fieldsService.createOpts(res.choice.id,data).then(
                (res:FieldsModule)=>{
                    console.log(res);

                        this.getInit();
                        this.closeModal('addField');
                        setTimeout(_ => this._commondata.showLoader(false), 200);
                },
                err=>{
                    console.log("form_no");
                }
            );    
            },
            err=>{
                console.log("form_no");
            }
        );
        }
    }        
    openSecondModal(id: string) {
        let filter = "";
        let idSelect = "";
        let response = this.form.getLineQuestions(this.idForm).then(
            (res: any)=>{
                console.log(res);
                this.formParagrafQuestions = res; 
                
                if(id == "textPlainModal")
                {
                    filter = "Texto_plano";
                    idSelect = "#paragraf_select_plain_text";
                }
                else
                {
                    idSelect = "#paragraf_select_simple_multi";
                    console.log(this.selectType);
                    if(this.selectType == "Simple")
                    {
                        filter = "Simple";
                    }
                    else
                    {
                        filter = "Multiple";
                    }
                }
                
                let options = '<option value="">'+this.selectquestion+'</option>';
                console.log(this.formParagrafQuestions);
                for(let i=0;i<Object.keys(this.formParagrafQuestions).length;i++)
                {
                    let quest = this.formParagrafQuestions[i];
                    console.log(quest);
                    if(filter == "Texto_plano" && quest.textarea!=null)
                    {
                        options+="<option value='"+quest.id+"'>"+quest.question+"</option>";
                    }
                    if(filter == "Simple" && quest.choice!=null)
                    {
                        options+="<option value='"+quest.id+"'>"+quest.question+"</option>";
                    }
                    if(filter == "Multiple" && quest.choice!=null)
                    {
                        options+="<option value='"+quest.id+"'>"+quest.question+"</option>";
                    }
                }
                console.log(options);
                document.querySelector(idSelect).innerHTML = options;
                this.modalService.open(id);
            
            },
            err=>{
                console.log("form_no");

            }
        );
        
        
       
    }
    
    getButtonsFromParagraf(filter)
    {
        
        let paragraf = window.CKEDITOR.instances["editor-paragrafEditor"].getSnapshot();
        console.log(paragraf);
        let parser = new DOMParser();
        let doc = parser.parseFromString(paragraf, "text/xml");
        console.log(doc);
        let query = "";
        if(filter=="Texto_plano")
        {
            let buttons = [];
            query = "input";
            let btns = doc.querySelectorAll(query);
            console.log(btns);
            for(let i=0;i<btns.length;i++)
            {
                console.log(btns);
                let data = {
                    "title":btns[i].getAttribute("value"),
                    "type":btns[i].getAttribute("name")
                };
                console.log(data);
                buttons.push(data);
            }
            console.log(buttons);
            return buttons;
        }
        else
        {
            let select = [];
            query = "select";
            let sel = (<HTMLSelectElement>doc.querySelectorAll(query));
            console.log(sel);
            for(let i=0;i<sel.length;i++)
            {
                let currentOpt = sel[i].querySelectorAll("option")[0]; 
                if(currentOpt.getAttribute("value")==filter)
                {
                    let data = {
                        "title":currentOpt.innerHTML,
                        "type":currentOpt.getAttribute("value"),
                        "select":sel[i]
                    };
                    console.log(data);
                    select.push(data);
                }
            }
            this.copySelects = select;
            console.log(select);
            return select;
        }
        
    }

    closeSecondModal(id: string) {
        //(<HTMLInputElement>document.getElementById("insertTextPlain")).value = "";
        //(<HTMLInputElement>document.getElementById("insertIdentifierTextPlain")).value = "";
        this.statusField = "";
//        this.specialElements = [];
//        this.specialElementsCount = 0;
        this.selectType = "Simple";
        this.next = false;
        this.parragraf = "";
        this.currentFieldId=0;
        this.seeOpt = false;
        this.modalService.close("editFieldModal");
        this.modalService.close(id);
        this.getInit();
        
    }
    closeModalSimple(id: string) {
        this.statusField = "";
        this.selectType = "Simple";
        this.next = false;
        this.parragraf = "";
        this.currentFieldId=0;
        this.seeOpt = false;
        this.modalService.close("editFieldModal");
        this.modalService.close(id);
        this.getInit();
    }
    
    goToOption()
    {
        
        let title = "";
        if(document.getElementById("insertSelectTitle"))
        {
            this.insertSelectTitle = (<HTMLInputElement>document.getElementById("insertSelectTitle")).value;
        }
        this.insertDescritpion = this.insertDescription;
        this.seeOpt = true;

        this.fieldsService.getAllImage().then(
        (data : any)=>{
            
            console.log(data);
            let option = "";
            for(let i=0;i<data.length;i++)
            {
                let valu = data[i].split("/");
                let imgName = valu[valu.length-1]; 
                
                option = option + "<div style='width: 24%;display: inline-block;' class='dropdown-item col-xl-3'><img style='width:100%;cursor:pointer;' class='addImg' data-img='"+imgName+"' src='"+data[i]+"'></div>";
            }
            
            for(let j=0;j<this.nOfOptions.length;j++)
            {
                console.log(j);
                console.log(document.querySelector("#submenu"+j));
                if(document.querySelector("#submenu"+j)!=null)
                {
                    document.querySelector("#submenu"+j).innerHTML = option;
                    
                }
            }

            let elements = document.getElementsByClassName("addImg");
            for(let y=0;y<elements.length;y++)
            {
                elements[y].addEventListener("click", (e:Event) => this.selectingImg(elements[y].getAttribute("src"),elements[y].getAttribute("data-img"),elements[y].closest(".dropdown-menu").getAttribute("data-open")));
            }
            if(this.currentFieldId>0)
            {
                console.log(this.currentField.choice);
                let i = 0;
                for(let opt of this.currentField.choice.opts)
                {
                        
                        if(this.nOfOptions.length<i)
                        {
                            this.nOfOptions.push(opt);
                        }
                        this.nOfOptions[i] = opt;
                        i++;
                }
                setTimeout( () => {
                    for(let j=0;j<this.nOfOptions.length;j++)
                    {
                        console.log(j);
                        console.log(document.querySelector("#submenu"+j));
                        if(document.querySelector("#submenu"+j)!=null)
                        {
                            document.querySelector("#submenu"+j).innerHTML = option;

                        }
                    }
                    let elements = document.getElementsByClassName("addImg");
                    for(let y=0;y<elements.length;y++)
                    {
                        elements[y].addEventListener("click", (e:Event) => this.selectingImg(elements[y].getAttribute("src"),elements[y].getAttribute("data-img"),elements[y].closest(".dropdown-menu").getAttribute("data-open")));
                    }
                     
                let i = 0;
                    for(let opt of this.currentField.choice.opts)
                    {

                            let idInput = "#select"+i;
                            if((<HTMLInputElement>document.querySelector(idInput))!=null)
                            {
                                (<HTMLInputElement>document.querySelector(idInput)).value = opt.text;
                            } 
                            i++;

                    }
                }, 200 );
                
            }
        },
        (err)=>{

        });    
            
        
    }
    selectingImg(src,dataImg,openId)
    {
        let imgElement = document.querySelector(openId);
        let optionElement = imgElement.getAttribute("data-leyenda");
        
        imgElement.innerHTML = "<img style='max-width:200px' src='"+src+"'>";
        document.querySelector(optionElement).setAttribute("data-img",dataImg);
        if((<HTMLInputElement>document.querySelector(optionElement)).value=="")
        {
            (<HTMLInputElement>document.querySelector(optionElement)).value=(<HTMLInputElement>document.querySelector(optionElement)).getAttribute("data-default-text");
        }
    }
    setSelectType(selectType)
    {
        this.selectType = selectType;
    }
    addParagraf(mdlclose)
    {
        
        this.nameField = (<HTMLInputElement>document.getElementById("parragrafTitle")).value;
        
        if(this.nameField=="")
        {
            this.nameError = true;
            console.log("HERE");
            return;
        }
        else
        {
            this.nameError = false;
        }
        
        this.parragraf = window.CKEDITOR.instances["editor-paragrafEditor"].getSnapshot();
        let data = {
            "question": this.nameField,
            "block": ""+this.currentGroupId,
            "content": this.parragraf,
            "form": this.idForm,
            "fixed": this.isFix
        };
        console.log(data);
        if(mdlclose == "true")
        {
            this.modalService.close(this.currentModal);
        }
        setTimeout(_ => this._commondata.showLoader(true), 200);
        if(this.currentFieldId>0)
        {
            let response = this.fieldsService.updateParagraf(this.currentFieldId,data).then(
            (res:FieldsModule)=>{
                console.log(res);
                this.currentFieldId = res.getId();
                if(mdlclose=="true")
                {
                    this.getInit();
                    this.closeModal('addField');
                }
                else
                {
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.onInit();
                }
            },
            err=>{
                console.log("form_no");

            }
        );
        }
        else
        {
            let response = this.fieldsService.createParagraf(data).then(
            (res:FieldsModule)=>{
                console.log(res);
                this.currentFieldId = res.getId();
                if(mdlclose=="true")
                {
                    this.getInit();
                    this.closeModal('addField');
                }
                else
                {
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.onInit();
                }
            },
            err=>{
                console.log("form_no");

            }
        );
        }
//        this.specialElements = [];
//        this.specialElementsCount = 0;
        this.seeOpt = false;
        this.statusField="";
        this.selectType = "Simple";
        this.next = false;
        this.parragraf = "";
    }
    OpenMenu(sectionName, Wrapdiv) {
        var CurrentCls = document.getElementById(sectionName).getAttribute("class");
        if (CurrentCls == "dropdown-menu full-width")
        {
            document.getElementById(sectionName).setAttribute("class", "dropdown-menu show full-width");
            document.getElementById(sectionName).previousElementSibling.setAttribute("aria-expanded", "true");
            document.getElementById(Wrapdiv).setAttribute("class", "btn-group info-drop info-drop-box show full-width");
        }
        else {
            document.getElementById(sectionName).setAttribute("class", "dropdown-menu full-width");
            document.getElementById(sectionName).previousElementSibling.setAttribute("aria-expanded", "false");
            document.getElementById(Wrapdiv).setAttribute("class", "btn-group info-drop info-drop-box full-width");
        }
    }
    htmlEntities(str) 
    {
        return String(str).replace(/&/g, '&amp;').replace(/"/g, '&quot;');
    }
    
    changeTypeSelect(typeSelect)
    {
        this.typeSelect = typeSelect;
    }
    
    addOption()
    {
        this.nOfOptions.push(this.optObj);
        this.fieldsService.getAllImage().then(
        (data : any)=>{
            console.log(data);
            let option = "";
            for(let i=0;i<data.length;i++)
            {
                let valu = data[i].split("/");
                let imgName = valu[valu.length-1]; 

                option = option + "<div style='width: 24%;display: inline-block;' class='dropdown-item col-xl-3'><img style='width:100%;cursor:pointer;' class='addImg' data-img='"+imgName+"' src='"+data[i]+"'></div>";
            }
            for(let i=0;i<this.nOfOptions.length;i++)
            {
                if(document.querySelector("#submenu"+(i+1))!=null)
                {
                    document.querySelector("#submenu"+(i+1)).innerHTML = option;
                }
            }

            let elements = document.getElementsByClassName("addImg");
            for(let y=0;y<elements.length;y++)
            {
                elements[y].addEventListener("click", (e:Event) => this.selectingImg(elements[y].getAttribute("src"),elements[y].getAttribute("data-img"),elements[y].closest(".dropdown-menu").getAttribute("data-open")));
            }
            if(this.currentFieldId>0)
            {
                console.log(this.currentField.choice);
                let i = 0;
                for(let opt of this.currentField.choice.opts)
                {
                        i++;
                        if(this.nOfOptions.length<i)
                        {
                            this.nOfOptions.push(this.optObj);
                        }
                }
                setTimeout( () => { 
                let i = 0;
                    for(let opt of this.currentField.choice.opts)
                    {

                            i++;

                            let idInput = "#select"+i;
                            if((<HTMLInputElement>document.querySelector(idInput))!=null)
                            {
                                (<HTMLInputElement>document.querySelector(idInput)).value = opt.text;
                            } 


                    }
                }, 200 );
                
            }
        },
        (err)=>{

        });
    }
    
    onImgChanged(event)
    {
        this.selectedImg = event.target.files[0];
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            reader.onload = (event) => 
            { // called once readAsDataURL is completed
                this.uploadImg();
            }
        }
    }
    
    uploadImg() 
    {
        
        this.configurationService.uploadImg(this.selectedImg).then(
            (data : any)=>{
                console.log(data);
                this.goToOption();
            },    
              (error)=>{
                console.log(error);
              }
            );
    }
    
    getFixed()
    {
        setTimeout(_ => this._commondata.showLoader(true), 10);
        let response = this.fieldsService.getFixed().then(
            (res:any)=>{
                console.log(res);
                this.fixQuestions = res;
            },
            (err)=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
        setTimeout(_ => this._commondata.showLoader(false), 200);
    }
    
    insertFixed()
    {
        let title = "";
            title = (<HTMLInputElement>document.getElementById("insertFixPlain")).value;
            
        if(this.currentFieldId>0)
        {
            let dataLine = {
                        "question": title,
                        "text": title,
                        "band": ""+this.currentGroupId,
                        "fixed": this.isFix,
                        "parent":+(<HTMLInputElement>document.getElementById("currentFixed")).value
                    };
            let response = this.fieldsService.updateLine(this.currentFieldId,dataLine).then(
            (res:FieldsModule)=>{
                console.log(res);
                this.getInit();
                this.closeModal('editFieldModal');
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                console.log("form_no");
            }
            );
        }
        else
        {
            let dataLine = {
                        "question": title,
                        "text": title,
                        "band": ""+this.currentGroupId,
                        "form": this.idForm,
                        "textarea":{},
                        "fixed": this.isFix,
                        "parent":+(<HTMLInputElement>document.getElementById("currentFixed")).value
                    };
            console.log(dataLine);        
            let response = this.fieldsService.createLines(dataLine).then(
            (res:FieldsModule)=>{
                console.log(res);
                
                    this.getInit();
                    this.closeModal('addField');
                    setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                console.log("form_no");
            }
            );
        }
    }
    
    addExtra(opt)
    {
        console.log(opt);
        if(!opt.extra)
        {
            opt.extra = [];
        }
        opt.extra.push("value");
    }
    
    changeText(i,j,value)
    {
        
    }
    
    deleteExtra(opt,j)
    {
        let auxExtra = [];
        for(let extr of opt.extra)
        {
            if(extr != opt.extra[j])
            {
                auxExtra.push(extr);
            }
        }
        opt.extra = auxExtra;
    }
    
}

