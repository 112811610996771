import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {ClusterModule} from './ClusterModule';
import {VariableFieldRelationsModule} from './VariableFieldRelationsModule';
import {FettleModule} from './FettleModule';

export class SectionClusterModule extends Base_Model
{

    private id: number = null;
    public name: string = null;
    private sort: number = null
    public cluster: ClusterModule = null;
    private status: number = null;
    public variableFieldRelations: VariableFieldRelationsModule[] = [];
    public fettles: FettleModule[] = [];
    public breakPage: number = 0;
    
    public getId()
    {
        return this.id;
    }
    public getName()
    {
        return this.name;
    }
    public getStatus()
    {
        return this.status;
    }
    public setStatus(status)
    {
        this.status = status;
        return this;
    }

    
    public getSort()
    {
        if(this.sort == null)
        {
            this.sort = this.id;
        }
        return this.sort;
    }

    
    public getCluster()
    {
        
            if (!(this.cluster instanceof ClusterModule))
            {
                let clusterAux = new ClusterModule();
                clusterAux.initialize(this.cluster);
                this.cluster = clusterAux;
            }
            
        return this.cluster;
    }
    
    
    public getVariableFieldRelations()
    {
        let variableFieldRelationsAux = [];
        for(let variableFieldRelation of this.variableFieldRelations)
        {
            if (!(variableFieldRelation instanceof VariableFieldRelationsModule))
            {
                let variableFieldRelationAux = new VariableFieldRelationsModule();
                variableFieldRelationAux.initialize(variableFieldRelation);
                variableFieldRelationsAux.push(variableFieldRelationAux);
            }
            else
            {
                variableFieldRelationsAux.push(variableFieldRelation);
            }
            
        }
        this.variableFieldRelations = variableFieldRelationsAux;
        
        
        return this.variableFieldRelations;
    }
    
    public getFettles()
    {
        let fettlesAux = [];
        for(let fettle of this.fettles)
        {
            if (!(fettle instanceof FettleModule))
            {
                let fettleAux = new FettleModule();
                fettleAux.initialize(fettle);
                fettlesAux.push(fettleAux);
            }
            else
            {
                fettlesAux.push(fettle);
            }
            
        }
        this.fettles = fettlesAux;
        return this.fettles;
    }
    
    public addPosibility()
    {
        console.log(this.fettles);
        for(let fettle of this.fettles)
        {
            if(fettle.getLogic() == null || fettle.getLogic() == "")
            {
                return false;
            }
        }
        return true;
    }
    public addFettle(fettle)
    {
        this.getFettles();
        this.fettles.push(fettle);
    }
    
    public removeFettle(fettle)
    {
        this.getFettles();
        console.log(this.fettles);
        let fettlesAux = [];
        for(let fettleaux of this.fettles)
        {
            if(fettleaux.getId()!=fettle.getId())
            {
                fettlesAux.push(fettleaux);
            }
        }
        this.fettles = fettlesAux;
        console.log(this.fettles);
        
        return this.fettles;
    }
    
    public setBreakPage(breakPage)
    {   
        this.breakPage = breakPage;
        return this;
    }
    
    public getBreakPage()
    {   
        return this.breakPage;
    }
    
}