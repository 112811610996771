﻿import { Component, OnInit } from '@angular/core';
import { CommonDataService } from './../common-data.service';
import { ModalService } from './../shared/_services/index';
import { FormBuilder,FormControl, FormGroup, Validators, NgForm,FormsModule } from '@angular/forms';
import { Users } from './../../services/Users';
import { UserAuthService } from './../../services/auth.service';
import { environment } from './../../environments/environment';
import * as shape from 'd3-shape';

@Component({
  selector: 'app-users',
  templateUrl: './bills.component.html',
  styleUrls: ['./bills.component.scss']
})
export class BillsComponent implements OnInit {

    billName;
    userNewBill;
    allUsers;
    allBills;
    selectedFile;
    currentDeleteBill;
    private url: string = environment.backendUrl;
    
    constructor(
    private _commondata: CommonDataService,
    private modalService: ModalService,
    public user_model: Users,
    public auth: UserAuthService,
    ) { }
    ngOnInit() 
    {          
        this.onInit();
    }
    onInit()
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.user_model.getAllBills().then(
            (data : any)=>{
                    this.allBills = data;
                    console.log(this.allBills);
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                },
                error=>{
                    console.log(error);
                }
            );
    }
    
    getInit()
    {
        
    }
    
    isSuperAdmin()
    {
        return this.user_model.getLocalUser().getCurrentTarrif()==4;
    }
    
    newBill()
    {
        
        this.user_model.getAllUsers().then(
            (data : any)=>{
                    this.allUsers = data;
                    console.log(this.allUsers);
                    this.billName = "";
                    this.userNewBill = "";
                    this.modalService.open('newBill');
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                },
                error=>{
                    console.log(error);
                }
            );
        
    }
    setDeleteBill(bill)
    {
        this.currentDeleteBill = bill
    }
    openModal(modal)
    {
        this.modalService.open(modal);
    }
    closeModal(modal)
    {
        this.modalService.close(modal);
    }
    
    onFileChanged(event) 
    {
        this.selectedFile = event.target.files[0];
        if (event.target.files && event.target.files[0]) 
        {
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            reader.onload = (event) => 
            {
                // called once readAsDataURL is completed
////                this.url = reader.result;
//                this.uploadFile();
            }
        }
    }

    
    createBill() 
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        console.log(this.billName);
        console.log(this.userNewBill);
            this.user_model.createNewBill(this.userNewBill,this.billName,this.selectedFile).then(
                (data : any)=>{
                    console.log(data);

                    this.onInit();
                    this.modalService.close('newBill');
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    },
                    error=>{
                      console.log(error);
                    }
                );
           
        
    }
    
    getDownloadBill(file)
    {        
            return this.url+"/download/bill/"+file;
    }
    
    deleteBill()
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
            this.user_model.deleteBill(this.currentDeleteBill).then(
                (data : any)=>{
                    console.log(data);

                    this.onInit();
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    },
                    error=>{
                      console.log(error);
                    }
                );
    }
    
    
}
