﻿import { Component, OnInit } from '@angular/core';
import { CommonDataService } from './../common-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Users } from './../../services/Users';
import { ProjectService } from './../../services/ProjectService';
import { ProjectModule } from './../../models/ProjectModule';
import { BlockModule } from './../../models/BlockModule';
import {environment} from './../../environments/environment';
import * as shape from 'd3-shape';
declare var window: any;


@Component({
  selector: 'app-preview-container',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

    idProject = 0;
    preview = null;
    project;
    blocks = [];
    public dateDefault: string = "";
    count = 0;
    editorValue:string = '';
    private url: string = environment.backendUrl;
    ckeditorContent;
    user;
    checkPDF = false;
    pdfLeft = 3;
    constructor(
    private _commondata: CommonDataService,
    public router: Router,
    public route: ActivatedRoute,
    public user_model: Users,
    public projectService: ProjectService
    ){}
    ngOnInit() 
    {

        this.onInit();
    }
    
    onInit()
    {
        this.user = this.user_model.getLocalUser();
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.route.params.subscribe(
            (res: ProjectModule) =>
            {
                if(res["idProject"]>0)
                {
                    this.ckeditorContent="";
                    this.idProject = res["idProject"];
                    let response = this.projectService.getProject(this.idProject).then(
                        (res:any)=>{
                            this.project = res;
                            if(!this.isPDFAvariable())
                            {
                                let response = this.projectService.getPDFDownloads(this.idProject).then(
                                (res:any)=>{
                                    this.pdfLeft = res;
                                    if(res<3)
                                    {
                                        this.checkPDF = true;
                                    }

                                    setTimeout(_ => this._commondata.showLoader(false), 1000);
                                },
                                    err=>{
                                        console.log("form_no");
                                        setTimeout(_ => this._commondata.showLoader(false), 200); 
                                    }
                                );
                            }
                            else
                            {
                                setTimeout(_ => this._commondata.showLoader(false), 100);
                            }  
                        },
                            err=>{
                                console.log("form_no");
                                setTimeout(_ => this._commondata.showLoader(false), 200); 
                            }
                        ); 
                     
                 
                }
                if (this.idProject === 0)
                {
                    this.router.navigate(['/projects/']);
                    
                }
            }
        );
    }
    
    isButtonAvariable()
    {
        return this.user_model.getLocalUser().getCurrentTarrif() >= 3 ;
    }
    
    isPDFAvariable()
    {
        return this.checkPDF || this.isButtonAvariable();
    }

    saveCurrentHTML()
    {
//        setTimeout(_ => this._commondata.showLoader(true), 200);
////        let text = window.CKEDITOR.instances["editor-previewEditor"].getSnapshot();
////        let response = this.projectService.putProject(this.idProject,{"preview":text}).then(
//            (res:any)=>{
////                window.CKEDITOR.instances["editor-previewEditor"].setData("");
//                this.onInit();
//                
//            },
//                err=>{
//                    console.log("form_no");
//                }
//            );
    }
    clearCurrentHTML()
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let response = this.projectService.putProject(this.idProject,{"preview":"","modify":"0"}).then(
            (res:any)=>{
//                window.CKEDITOR.instances["editor-previewEditor"].setData("");
                this.onInit();
            },
                err=>{
                    console.log("form_no");
                }
            );
    }
    pdfCurrentHTML()
    {
        
            return this.url+"/make/html/pdf/"+this.idProject;
        
    }
    
    setProjectBasic()
    {
        if(!this.isButtonAvariable())
        {
            setTimeout(_ => this._commondata.showLoader(true), 200);
            let response = this.projectService.postPrintPdf({"user":this.user_model.getLocalUser().getId(),"project":this.idProject}).then(
            (res:any)=>{
                this.checkPDF = false;
                setTimeout(_ => this._commondata.showLoader(false), 200);
                let responsetwo = this.projectService.getPDFDownloads(this.idProject).then(
                                (res:any)=>{
                                    this.pdfLeft = res;
                                    if(res<3)
                                    {
                                        this.checkPDF = true;
                                    }

                                    setTimeout(_ => this._commondata.showLoader(false), 1000);
                                },err=>{
                    console.log("form_no");
                });
            },
                err=>{
                    console.log("form_no");
                }
            );
        }
    }
    
    docxCurrentHTML()
    {
        return this.url+"/make/html/word/"+this.idProject;
    }
    getResponsesExel()
    {
        return this.url+"/make/html/projectToXls/"+this.idProject;
    }
    
    getLeft()
    {
        return 3-this.pdfLeft;
    }
}
