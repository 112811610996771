import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
export class VariableModule extends Base_Model
{

    private id: number = null;
    public name: string = null;
    public description: string = null;
    
    public getId()
    {
        return this.id;
    }
    public getName()
    {
        return this.name;
    }
    public getDescription()
    {
        return this.description;
    }
    
    
}