import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {FieldsModule} from './FieldsModule';
import {VariableModule} from './VariableModule';
export class ClusterModule extends Base_Model
{

    private id: number = null;
    private name: string = null;
    private text: string = null;
    private user: string = null;
    private sort: number = null;
    public variables: VariableModule[] = [];
    
    public getId()
    {
        return this.id;
    }
    public getName()
    {
        return this.name;
    }
    public getText()
    {
        return this.text;
    }
    public getSort()
    {
        return this.sort;
    }
    public setText(text)
    {
        this.text = text;
        return this;
    }
    public getUser()
    {
        return this.user;
    }
    
    public getVariables()
    {
        let variablesAux = [];
        for(let variable of this.variables)
        {
            if (!(variable instanceof VariableModule))
            {
                let variableAux = new VariableModule();
                variableAux.initialize(variable);
                variablesAux.push(variableAux);
            }
            else
            {
                variablesAux.push(variable);
            }
            
        }
        this.variables = variablesAux;
        return this.variables;
    }
    
}