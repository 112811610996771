﻿import { Component, OnInit } from '@angular/core';
import { CommonDataService } from './../common-data.service';
import { ModalService } from './../shared/_services/index';
import { FormBuilder,FormControl, FormGroup, Validators, NgForm,FormsModule } from '@angular/forms';
import { Users } from './../../services/Users';
import { UserAuthService } from './../../services/auth.service';
import { environment } from './../../environments/environment';
import * as shape from 'd3-shape';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

    userForm: FormGroup;
    myUsers;
    sendPost;
    signupForm;
    userIdDelete;
    userIdEdit = 0;
    user;
    browser;
    timer;
    tarrifView=false;
    myTarrifsType;
    constructor(
    private _commondata: CommonDataService,
    private modalService: ModalService,
    private frmbuilder:FormBuilder,
    public user_model: Users,
    public auth: UserAuthService,
    ) { }
    ngOnInit() 
    {
        
        
        this.userForm=this.frmbuilder.group({  
                username:new FormControl(null, [Validators.required,Validators.minLength(3)]),
                email:new FormControl(null, [Validators.required,Validators.minLength(3)]),
                password:new FormControl(null, [Validators.required,Validators.minLength(3)]),
            });
                    
        this.onInit();
        
         
    }
    onInit()
    {
        this.tarrifView=false;
        this.user = this.user_model.getLocalUser();
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let response = this.user_model.getMyUsers().then(
            (res:any)=>{
               this.myUsers = res;  
               console.log(this.myUsers);
               setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );   
    }
    newUser()
    {
        
        if(0 < this.user.getProjectCount() || this.user.getCurrentTarrif()==4 || this.user.getCurrentTarrif()==3)
        {
            this.modalService.open('newUser');
            this.userForm.get("username").setValue("");
            this.userForm.get("email").setValue("");
            this.userForm.get("password").setValue("");
            this.userIdEdit= 0;
        }
        else
        {
            this.user_model.getMyTPV().then(
            (res:any)=>{
                
                this.myTarrifsType = res;
                this.tarrifView = true;
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            (err)=>{
                
            });
            this.tarrifView = true;
            setTimeout(_ => this._commondata.showLoader(false), 200);
        }
    
    }
    
    editUserModal(user)
    {
        
        this.modalService.open('newUser');
        this.userForm.get("username").setValue(user.username);
        this.userForm.get("email").setValue(user.email);
        this.userForm.get("password").setValue(user.plainPassword);
        this.userIdEdit= user.id;
    
    }
    
    closeModal()
    {
        this.modalService.close('newUser');
    }
    
    createNewUser(data)
    {
        if (this.userForm.valid) {
              console.log('form submitted');
                this._commondata.showLoader(true);
            this.sendPost = {
                'username':data.value.username,
                'email':data.value.email,
                'plainPassword':data.value.password,
                'parent':this.user_model.getLocalUser().getId()+"",
                'enabled':true
            };
            if(this.userIdEdit==0)
            {
                if(this.user.getCurrentTarrif()==4 || this.user.getCurrentTarrif()==3)
                {
                    this.user_model.putUser(this.user.getId(),{"usersCount":this.user.getUsersCount()-1}).then(
                    (res: any)=>{
                        this.user = this.user_model.getLocalUser();
                        let responsetwo = this.auth.registerUser(this.sendPost).then(
                        (restwo:any)=>{
                            setTimeout(_ => this._commondata.showLoader(false), 200);
                            this.onInit();
                            this.modalService.close('newUser');

                        },
                        err=>{
                            setTimeout(_ => this._commondata.showLoader(false), 200);
                        });
                    },
                    (err: any)=>{

                    });
                }
                else
                {
                   
                    let responsetwo = this.auth.registerUser(this.sendPost).then(
                    (restwo:any)=>{
                        setTimeout(_ => this._commondata.showLoader(false), 200);
                        this.onInit();
                        this.modalService.close('newUser');

                    },
                    err=>{
                        setTimeout(_ => this._commondata.showLoader(false), 200);
                    });
                    
                }
                
                
            }
            else
            {
                this.user_model.updateUser(this.userIdEdit,this.sendPost).then(
                    (res: any)=>{
                        this.onInit();
                        this.modalService.close('newUser');
                    },
                    (err: any)=>{
                        
                    });
            }
        }else{
            this.validateAllFormFields(this.userForm);
        }
    }
    
    validateAllFormFields(formGroup: FormGroup) {        
        Object.keys(formGroup.controls).forEach(field => { 
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {            
              control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {       
              this.validateAllFormFields(control);           
            }
        });
                
    }
    
    deleteUserModal(idUser)
    {
        this.modalService.open("deleteUserModal");
        this.userIdDelete = idUser;
    }
    deleteUser()
    {
        
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let response = this.user_model.deleteUser(this.userIdDelete).then(
            (res)=>{
                this.userIdDelete=0;
                this.modalService.close("deleteUserModal");
                this.onInit();
            },
            err=>{
                        console.log("form_no");

            }
        );
    }
    
    getProfile(child)
    {
        
        if(child.image!=null)
        {
            return this.user_model.getUrl()+"/upload/profile/"+child.image;
        }
        
        return "assets/images/profile-avatar.jpg"
    }
    
    goSelected(tarrifId)
    {
        this.browser = window.open(environment.backendUrl + '/api/payment/create/' + this.user_model.getLocalUser().getId() + "/" 
        + tarrifId , "_blank", "hideurlbar=yes, hidenavigationbuttons=true");
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.timer = setInterval(_ =>this.checkStatusWindow(), 1000);

    }
    
    goNextUser(numUser)
    {
        this.browser = window.open(environment.backendUrl + '/api/payment/create/next/' + this.user_model.getLocalUser().getId() + "/" 
        + numUser +"/"+"users" , "_blank", "hideurlbar=yes, hidenavigationbuttons=true");
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.timer = setInterval(_ =>this.checkStatusWindow(), 1000);

    }
    
    checkStatusWindow()
    {
        if (this.browser.closed) {
            // The window is closed.
            clearInterval(this.timer);
            if(/*this.browser.URL.indexOf("/api/payment/complete") > -1*/ true) {
                setTimeout(_ => this._commondata.showLoader(false), 200);
                
                this.user_model.getProf().then(
                (payments: any)=>{
                    this.onInit();

                },
                err=>{

                });


            }
        }
            
    }
    goToUser()
    {
        this.tarrifView = false;
        setTimeout(_ => this._commondata.showLoader(false), 200);
    }
    
}
