import {Injectable} from '@angular/core';
import {Api} from '../providers/api';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {RequestOptions, Headers} from '@angular/http';
import {Users} from './Users';
import {FormModule} from '../models/FormModule';
import {BlockModule} from '../models/BlockModule';
import {ClusterModule} from '../models/ClusterModule';
import { PoolModule } from './../models/PoolModule';

@Injectable()
export class ClusterService 
{
    
    
    constructor(public UsersService: Users,private api: Api, public form: FormModule, public block: BlockModule, public cluster: ClusterModule) 
    {
        this.UsersService = UsersService;
    }

    crearCluster()
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            console.log(this.UsersService.getLocalUser().getId());
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                
                var d = new Date();
                lthis.api.post('api/clusters', {"name":"Bloque","created":d,"active":1,"user":lthis.UsersService.getLocalUser().getId()}).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    crearPool()
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            console.log(this.UsersService.getLocalUser().getId());
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                
                lthis.api.post('api/pools', {"name":"Group","user":lthis.UsersService.getLocalUser().getId()}).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    getCluster(clusterID)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                options.withCredentials = true;
                lthis.api.get('api/clusters/'+clusterID).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        let cluster = new ClusterModule();
                        cluster.initialize(res);
                            
                        resolve(cluster);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    getMyCluster()
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.get('api/cluster/getMyCluster', '', options).subscribe(
                    (res: any) =>
                    {
                        let toRet = [];
                        console.log("Register result ", res);
                        for (let current of JSON.parse(res.text())) {
                            lthis.cluster = new ClusterModule();
                            lthis.cluster.initialize(current);
                            toRet.push(lthis.cluster);
                        }
                        resolve(toRet);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    getMyClusterOrphan()
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.get('api/cluster/getMyClusterOrphan', '', options).subscribe(
                    (res: any) =>
                    {
                        let toRet = [];
                        console.log("Register result ", res);
                        for (let current of JSON.parse(res.text())) {
                            lthis.cluster = new ClusterModule();
                            lthis.cluster.initialize(current);
                            toRet.push(lthis.cluster);
                        }
                        resolve(toRet);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    getMyPool()
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.get('api/pool/getMyPool', '', options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        let toRet = [];
                        console.log("Register result ", res);
                        res.sort(function(a,b){
                            return a.sort - b.sort;
                        });    
                        for (let current of res) {
                            current.cluster.sort(function(a,b){
                                return a.sort - b.sort;
                            });  
                            let pool = new PoolModule();
                            pool.initialize(current);
                            toRet.push(pool);
                        }
                        resolve(toRet);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    saveCluster(clusterId,data)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.put('api/clusters/'+clusterId, data, options).subscribe(
                    (res: any) =>
                    {
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    savePool(poolId,data)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.put('api/pools/'+poolId, data, options).subscribe(
                    (res: any) =>
                    {
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    checkVariable(data)
    {
        
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.post('api/check/variable/',data).subscribe(
                (res: any) =>
                {
                    resolve(res.text());
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
        
    }
    
    deleteCluster(clusterId)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.delete('api/clusters/'+clusterId, options).subscribe(
                    (res: any) =>
                    {
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    deletePool(poolId)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.delete('api/pools/'+poolId, options).subscribe(
                    (res: any) =>
                    {
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
}

