﻿import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ColorPickerService, Cmyk } from 'ngx-color-picker';
import { CommonDataService } from './../common-data.service';
import { ConfigurationService } from '../../services/ConfigurationService';
import { Users } from '../../services/Users';
import { TranslateService } from '@ngx-translate/core';
import * as shape from 'd3-shape';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

    user;
    selectedFile;
    selectedImage;
    imgPreview;
    imgLogoPreview;
    url;
    configuration;
    primaryColor="";
    secondaryColor="";
    barColor="";
    event;
    selectedLogo;
    selectedLogoFooter;
    translateImageSize = "";
    constructor(
    private _commondata: CommonDataService,
    private ConfigurationService: ConfigurationService,
    public vcRef: ViewContainerRef, 
    public userService: Users,
    private cpService: ColorPickerService,
    translate: TranslateService,
    ) {
        let respuesta = translate.get('Image_Size_should_not_be_greater_than_1mb').subscribe(res => { this.translateImageSize = res; });
      }
    ngOnInit() 
    {
        setTimeout(_ => this._commondata.showLoader(false), 200);
        this.event = document.createEvent('Event');
        this.event.initEvent('triggerColors', true, true);
        this.onInit();
    }
    onInit()
    {
        
        this.user = this.userService.getLocalUser();
        this.configuration = this.user.getConfiguration();
        this.imgLogoPreview = this.user.getConfiguration().getLogo();
        this.primaryColor=this.user.getConfiguration().getFirst();
        this.secondaryColor=this.user.getConfiguration().getSecond();
        this.barColor=this.user.getConfiguration().getColor();
        let lthis = this;
        console.log("disparar evento");
        document.querySelector("body").dispatchEvent(this.event);
//        (<HTMLElement>document.getElementsByClassName("admin-header")[0]).style.background=this.user.getConfiguration().getColor();
//        (<HTMLInputElement>document.getElementsByClassName("btn-secondary")[0]).style.background = this.secondaryColor;
//        (<HTMLInputElement>document.getElementsByClassName("btn-secondary")[0]).style.borderColor = this.secondaryColor;
//        (<HTMLElement>document.getElementsByClassName("btn-secondary")[0]).style.boxShadow="none";
//        
//        (<HTMLElement>document.getElementsByClassName("btn-primary")[0]).style.background=this.primaryColor;
//        (<HTMLElement>document.getElementsByClassName("btn-primary")[0]).style.borderColor=this.primaryColor;
//        (<HTMLElement>document.getElementsByClassName("btn-primary")[0]).style.boxShadow="none";
        
        setTimeout(_ => this._commondata.showLoader(false), 200);
    }
    
    public toggle: boolean = false;
    public rgbaText: string = 'rgba(165, 26, 214, 0.2)';
    public arrayColors: any = {
        color1: '#2883e9',
        color2: '#e920e9',
        color3: 'rgb(255,245,0)',
        color4: 'rgb(236,64,64)',
        color5: 'rgba(45,208,45,1)'
    };
    public selectedColor: string = 'color1';
    public selectedColor1: string = 'color2';
    public color1: string = '#2889e9';
    public color2: string = 'rgb(0, 255, 255)';
    public color3: string = '#fff500';
    public color4: string = '#a51ad633';//'rgb(236,64,64)';
    public color5: string = 'rgba(45,208,45,1)';
    public color6: string = '#f200bd';
    public color7: string = '#f200bd';
    public color8: string = 'rgba(0, 255, 0, 0.5)';
    public color9: string = '#278ce2';
    public color10: string = 'rgb(236,64,64)';
    public color11: string = '#f2ff00';
    public color12: string = '#f200bd';
    public color13: string = 'rgba(0, 255, 0, 0.5)';
    public color14: string = 'rgb(0, 255, 255)';
    public color15: string = '#a51ad633';
    public color16: string = 'rgba(45,208,45,0.5)';
    public color17: string = '#f200bd';
    public color18: string = '#fff500';
    public cmykColor: Cmyk = new Cmyk(0, 0, 0, 0);
    public onEventLog(event: string, data: any): void {
        console.log(event, data);
    }
    public onChangeColor(color: string): Cmyk {
        this.primaryColor = color;
        const hsva = this.cpService.stringToHsva(color);
        const rgba = this.cpService.hsvaToRgba(hsva);
        return this.cpService.rgbaToCmyk(rgba);
    }
    public onChangeColorHex8(color: string): string {
        this.primaryColor = color;
        const hsva = this.cpService.stringToHsva(color, true);
        return this.cpService.outputFormat(hsva, 'rgba', null);
    }
    
    onFileChangedImg(event) {
        if(event.target.files[0].size>1056770)
        {
            alert(this.translateImageSize);  
            return false;
        }
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.selectedImage = event.target.files[0];
                
        if (event.target.files && event.target.files[0]) {
            this.imgPreview=true;
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            reader.onload = (event) => 
            { // called once readAsDataURL is completed
              //this.url = reader.result;
              this.uploadImage();
            }
        }
    }
    onFileChangedLogo(event){
        if(event.target.files[0].size>1056770)
        {
            alert(this.translateImageSize);  
            return false;
        }
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.selectedFile = event.target.files[0];

        if (event.target.files && event.target.files[0]) {
            this.imgLogoPreview=true;
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            reader.onload = (event) => 
            { // called once readAsDataURL is completed
              //this.user.getConfiguration().setLogo(reader.result);
              this.uploadFile();
            }
        }
    }   


    uploadFile() { 
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.ConfigurationService.uploadLogoWeb(this.selectedFile,this.user).then(
            (user : any)=>{
                user.getConfiguration().setLogo(user.getConfiguration().getLogo()+"?"+ new Date().getTime());
                this.userService.saveLocalUser(user);
                //this.user = this.userService.getLocalUser();
                this.onInit();
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            error=>{
                console.log(error);
            }
        );

    }
    
    uploadImage() { 
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.ConfigurationService.uploadImageWeb(this.selectedImage,this.user).then(
            (user : any)=>{
                user.getConfiguration().setImage(user.getConfiguration().getImage()+"?"+ new Date().getTime());
                this.userService.saveLocalUser(user);
                //this.user = this.userService.getLocalUser();
                this.onInit();
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            error=>{
                console.log(error);
            }
        );

    }
    
    addPrimaryColor()
    {
        this.primaryColor = "#ffffff";
    }
    
    saveColors()
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.ConfigurationService.uploadColorWeb(this.user,this.primaryColor,this.secondaryColor,this.barColor).then(
            (user : any)=>{
                this.userService.saveLocalUser(user);
                this.onInit();
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            error=>{
                console.log(error);
            }
        );
    }
    
    resetConfig()
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.ConfigurationService.resetConfigWeb(this.user).then(
            (user : any)=>{
                this.userService.saveLocalUser(user);
                this.onInit();
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            error=>{
                console.log(error);
            }
        );
    }
    savePrimaryColor()
    {
//        this.ConfigurationService.uploadPrimaryColor(this.primaryColor).then(
//            (data : any)=>{
//                
//            },
//            error=>{
//                console.log(error);
//            }
//        );
    }
    
    onlogChanged(event)
    {
        this.selectedLogo = event.target.files[0];
        if (event.target.files && event.target.files[0]) {
//            this.imgPreview=true;
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            reader.onload = (event) => 
            { // called once readAsDataURL is completed
              //this.url = reader.result;
                this.uploadLogo();
            }
        }
    }
    
    onlogFooterChanged(event)
    {
        this.selectedLogoFooter = event.target.files[0];
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            reader.onload = (event) => 
            { // called once readAsDataURL is completed
                this.uploadLogoFooter();
            }
        }
    }
    
    uploadLogoFooter() {
        
        this.ConfigurationService.uploadLogoFooter(this.user.getId(),this.selectedLogoFooter).then(
            (data : any)=>{
                console.log(data);
                let splitImage = this.selectedLogoFooter["name"].split(".");
                let extension = splitImage.pop();
                this.user.setLogoFooter( this.user.getId()+"-"+this.slugify(splitImage.join()).normalize('NFD').replace(/[\u0300-\u036f]/g, "")+"."+extension);              
                },
              error=>{
                console.log(error);
              }
            );
        
    }
    
    uploadLogo() {
        
        this.ConfigurationService.uploadLogo(this.user.getId(),this.selectedLogo).then(
            (data : any)=>{
                console.log(data);
//                this.onInit();
                let splitImage = this.selectedLogo["name"].split(".");
                let extension = splitImage.pop();
                this.user.setLogo( this.user.getId()+"-"+this.slugify(splitImage.join()).normalize('NFD').replace(/[\u0300-\u036f]/g, "")+"."+extension);
              },
              error=>{
                console.log(error);
              }
            );
        
    }
    
    slugify(text)
    {
      return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
    }
    
    
}
