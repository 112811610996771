import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {VariableModule} from './VariableModule';
import {FieldsModule} from './FieldsModule';
import {FettleGroupModule} from './FettleGroupModule';

export class VariableFieldRelationsModule extends Base_Model
{

    private id: number = null;
    public field:FieldsModule = null;
    public fieldAux:FieldsModule = null;
    public variable:VariableModule = null;
    public fettleGroups:FettleGroupModule[] = [];
    private status:number = null;
    public parent:any = null;
    public breakPage: number = 0;
    
    public getId()
    {
        return this.id;
    }
    
    public getStatus()
    {
        return this.status;
    }
    
    public getParent()
    {
        if (!(this.parent instanceof VariableFieldRelationsModule))
            {
                let variableAux = new VariableFieldRelationsModule();
                variableAux.initialize(this.parent);
                this.parent = variableAux;
            }
            
        return this.parent;
    }
    
    public setStatus(status)
    {
        this.status = status;
        
        return this;
    }
    
    public getVariable()
    {
        
            if (!(this.variable instanceof VariableModule))
            {
                let variableAux = new VariableModule();
                variableAux.initialize(this.variable);
                this.variable = variableAux;
            }
            
        return this.variable;
    }
    
    public getField()
    {
        
            if (!(this.field instanceof FieldsModule))
            {
                let fieldAux = new FieldsModule();
                fieldAux.initialize(this.field);
                this.field = fieldAux;
            }
            
        return this.field;
    }
    
    public setField(field)
    {
        this.field = field;
        return this;
    }
    
    public setAuxField(field)
    {
        this.fieldAux = field;
        return this;
    }
    
    public getAuxField()
    {
        return this.fieldAux;
    }
    
    public saveField()
    {
        this.field = this.fieldAux;
    }
    
    public getFettleGroups()
    {
        let fettlesAux = [];
        for(let fettle of this.fettleGroups)
        {
            if (!(fettle instanceof FettleGroupModule))
            {
                let fettleAux = new FettleGroupModule();
                fettleAux.initialize(fettle);
                fettlesAux.push(fettleAux);
            }
            else
            {
                fettlesAux.push(fettle);
            }
            
        }
        this.fettleGroups = fettlesAux;
        
        
        return this.fettleGroups;
    }
    
    public setFettleGroups(fettleGroups)
    {
        
        this.fettleGroups = fettleGroups;
        
        
        return this;
    }
    
    public addGroupCondition(fettleGroup)
    {
        this.getFettleGroups();
        this.fettleGroups.push(fettleGroup);
    }
    
    public removeGroupCondition(fettleGroup)
    {
        this.getFettleGroups();
        let fettlesAux = [];
        for(let fettle of this.fettleGroups)
        {
            if(fettleGroup.getId()!=fettle.getId())
            {
                fettlesAux.push(fettle);
            }
        }
        this.fettleGroups = fettlesAux;
        
        
        return this.fettleGroups;
    }
    
    public setBreakPage(breakPage)
    {   
        this.breakPage = breakPage;
        return this;
    }
    
    public getBreakPage()
    {   
        return this.breakPage;
    }
}