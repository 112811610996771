﻿import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';

import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboards/dashboard/dashboard.component';
import { FormComponent } from './form/form.component';
import { BlockComponent } from './blocks/block.component';
import { BillsComponent } from './bills/bills.component';
import { GroupsComponent } from './groups/groups.component';
import { ThemeComponent } from './themes/theme.component';
import { TemplateComponent } from './template/template.component';
import { EditThemeComponent } from './themes/editTheme/editTheme.component';
import { EditFormComponent } from './form/editForm/editForm.component';
import { ProjectComponent } from './projects/projects.component';
import { SelectFormComponent } from './projects/selectForm/form.component';
import { ConfigComponent } from './config/config.component';
import { EditProjectsComponent } from './projects/editProject/editProjects.component';
import { UsersComponent } from './users/users.component';
import { CuentasComponent } from './cuentas/cuentas.component';
import { PreviewComponent } from './preview/preview.component';
import { BoardComponent } from './form/editForm/board/board.component';
import { ParagrafComponent } from './form/editForm/paragraf/paragraf.component';
import { PictureComponent } from './form/editForm/picture/picture.component';
import { ImageComponent } from './form/editForm/image/image.component';
import { DiagramaComponent } from './form/editForm/diagrama/diagrama.component';
import { SelectUserComponent } from './projects/selectUser/selectUser.component';
import { FieldComponent } from './field/field.component';
import { AccessProjectsComponent } from './projects/accessProject/accessProjects.component';
import { resetPasswordComponent } from './auth/resetPassword/resetPassword.component';

import { Dashboard06Component } from './dashboards/dashboard06/dashboard06.component';

import { CommonDataService } from './common-data.service';

import { EditorComponent } from './forms/editor/editor.component';
import { FormInputComponent } from './forms/form-input/form-input.component';
import { InputGroupComponent } from './forms/input-group/input-group.component';
import { ToastrComponent } from './forms/toastr/toastr.component';

import { FontawesomeIconComponent } from './icons/fontawesome-icon/fontawesome-icon.component';
import { ThemifyIconsComponent } from './icons/themify-icons/themify-icons.component';
import { WeatherIconComponent } from './icons/weather-icon/weather-icon.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AppContactsComponent } from './pages/app-contacts/app-contacts.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { FileManagerComponent } from './pages/file-manager/file-manager.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { BlankComponent } from './pages/blank/blank.component';
import { LayoutContainerComponent } from './pages/layout-container/layout-container.component';
import { ErrorComponent } from './pages/error/error.component';
import { FaqsComponent } from './pages/faqs/faqs.component';

import { MapsComponent } from './maps/maps.component';
import { TimelineComponent } from './timeline/timeline.component';
import { LoginComponent } from './auth/login/login.component';
import { LayoutComponent } from './shared/_layout/layout/layout.component';
import { Layout1Component } from './shared/_layout/layout1/layout1.component';
import { Layout2Component } from './shared/_layout/layout2/layout2.component';
import { Layout3Component } from './shared/_layout/layout3/layout3.component';
import { Layout4Component } from './shared/_layout/layout4/layout4.component';
import { RegisterComponent } from './auth/register/register.component';
import { LockscreenComponent } from './auth/lockscreen/lockscreen.component';
import { FormValidationComponent } from './forms/form-validation/form-validation.component';
import { FieldErrorDisplayComponent } from './forms/field-error-display/field-error-display.component';

import { Modal1Component } from './shared/_directives/index';
import { ModalService } from './shared/_services/index';
import { RatingComponent } from './shared/_directives/rating/rating.component';
//import { ButtonsComponent } from './elements/buttons/buttons.component';
import { MailComponent } from './mail/mail.component';
import { ProjectSummaryComponent } from './pages/project-summary/project-summary.component';

import { ToastModule, ToastOptions } from 'ng2-toastr/ng2-toastr';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ColorPickerModule } from 'ngx-color-picker';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { HttpModule } from '@angular/http';

import { NgxChartsModule } from '@swimlane/ngx-charts';


import { DxVectorMapModule, DxSelectBoxModule, DxTextBoxModule } from 'devextreme-angular';

import { CKEditorModule } from 'ngx-ckeditor';

import { ChartsModule } from 'ng2-charts';

import { CalendarModule } from 'angular-calendar';

import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { DemoUtilsModule } from './demo-utils/module';

import { Users } from '../services/Users';
import { Form } from '../services/Form';
import { BlockService } from '../services/BlockService';
import { ProjectService } from '../services/ProjectService';
import { FormModule } from '../models/FormModule';
import { BlockModule } from '../models/BlockModule';
import { BandModule } from '../models/BandModule';
import { ClusterModule } from '../models/ClusterModule';
import { ThemeModule } from '../models/ThemeModule';
import { ConfigModule } from '../models/ConfigModule';
import { TarrifModule } from '../models/tarrifModule';
import { TarrifTypeModule } from '../models/tarrifTypeModule';
import { UserAuthService } from '../services/auth.service';
import { FieldsService } from '../services/FieldsService';
import { RoomService } from '../services/RoomService';
import { ConfigurationService } from '../services/ConfigurationService';
import { RoomModule } from '../models/RoomModule';
import { FieldsModule } from '../models/FieldsModule';
import { ProjectModule } from '../models/ProjectModule';
import { PaymentModule } from '../models/PaymentModule';
import { ClusterService } from '../services/ClusterService';
import { ThemeService } from '../services/ThemeService';

//import { NgDragDropModule } from 'ng-drag-drop'; 

import { Api } from '../providers/api';

import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {InterceptorService} from '../services/interceptor.service';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/lang/', '.json');
}

declare var require: any;

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    FormComponent,
    BlockComponent,
    BillsComponent,
    GroupsComponent,
    ThemeComponent,
    EditThemeComponent,
    PreviewComponent,
    EditFormComponent,
    ProjectComponent,
    SelectFormComponent,
    ConfigComponent,
    EditProjectsComponent,
    SelectUserComponent,
    UsersComponent,
    CuentasComponent,
    BoardComponent,
    ParagrafComponent,
    PictureComponent,
    ImageComponent,
    DiagramaComponent,
    FieldComponent,
    AccessProjectsComponent,
    TemplateComponent,
//    Dashboard02Component,
//    Dashboard03Component,
//    Dashboard04Component,
//    Dashboard05Component,
    Dashboard06Component,
//    AccordionsComponent,
//    AlertsComponent,
//    ColorpickerComponent,
//    DropdownComponent,
//    ListsComponent,
//    ModalComponent,
//    NavComponent,
//    NicescrollComponent,
//    PricingtableComponent,
//    DataHtmlTableComponent,
//    DatepickerComponent,
//    TabsComponent,
//    TypographyComponent,
//    PopoverTooltipsComponent,
//    ProgressComponent,
//    SwitchComponent,
//    Sweetalert2Component,
    //ChartComponent,
    //CalendarComponent, 
    EditorComponent,
    FormInputComponent,
    InputGroupComponent,
    ToastrComponent,
    FontawesomeIconComponent,
    ThemifyIconsComponent,
    WeatherIconComponent,
    ProjectsComponent,
    ProfileComponent,
    //ChatComponent,
    AppContactsComponent,
    ContactsComponent,
    FileManagerComponent,
    InvoiceComponent,
    BlankComponent,
    LayoutContainerComponent,
    ErrorComponent,
    FaqsComponent,
    MapsComponent,
    TimelineComponent,
    LoginComponent,
    resetPasswordComponent,
    LayoutComponent,
    Layout1Component,
    Layout2Component,
    Layout3Component,
    Layout4Component,
    RegisterComponent,
    LockscreenComponent,
    Modal1Component,
    RatingComponent,
    //ButtonsComponent,
    FormValidationComponent,
    FieldErrorDisplayComponent,
    MailComponent,
    ProjectSummaryComponent
    //DataLocalComponent,
    //DataTableComponent
    //CalendarProjectComponent
      ],
  imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      //NgDragDropModule,
      ToastModule.forRoot(),
      NgbModule.forRoot(),
      ReactiveFormsModule,
      FormsModule,
      ChartsModule,
      NgxDatatableModule,
      ColorPickerModule,
      HttpModule,
      NgxChartsModule,
      //AgmCoreModule.forRoot({ apiKey: "AIzaSyDjVoaCW3PAn52C7WPpJ7NBBqU1_TUfnSI" }),
      DxVectorMapModule,
      DxSelectBoxModule,
      DxTextBoxModule,
      CKEditorModule,
      MalihuScrollbarModule.forRoot(),
      NgbModalModule.forRoot(),
      CalendarModule.forRoot(),
      DemoUtilsModule,
      HttpClientModule,
      TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
  ],
  providers: [
    CommonDataService, 
    ModalService, 
    ToastOptions,
    Users,
    Form,
    BlockService,
    ProjectService,
    FieldsService,
    RoomService,
    UserAuthService,
    ConfigurationService,
    ClusterService,
    ThemeService,
    Api,
    FormModule,
    BlockModule,
    BandModule,
    ConfigModule,
    TarrifModule,
    TarrifTypeModule,
    FieldsModule,
    ClusterModule,
    ThemeModule,
    RoomModule,
    ProjectModule,
    HttpClientModule,
    {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true,
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
