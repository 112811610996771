﻿import { Component, OnInit } from '@angular/core';
import { CommonDataService } from './../../common-data.service';
import { Form } from './../../../services/Form';
import { ThemeService } from './../../../services/ThemeService';
import { ProjectService } from './../../../services/ProjectService';
import { Users } from './../../../services/Users';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import * as shape from 'd3-shape';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class SelectFormComponent implements OnInit {

    myThemes = [];
    user;
    browser;
    timer;
    myTarrifsType;

    constructor(
    private _commondata: CommonDataService,
    public form: Form,
    public themeService: ThemeService,
    public router: Router,
    public route: ActivatedRoute,
    public projectService: ProjectService,
    public users_model: Users
    ) { }
    ngOnInit() 
    {
        this.getInit();
    }
    
    getInit()
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.user = this.users_model.getLocalUser();
        
        if(this.checkNumProjects())
        {
            let response = this.themeService.getMyThemesForProject().then(
                (res:any)=>{
                    this.myThemes = res;  
                    console.log(this.myThemes);
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                },
                err=>{
                    console.log("form_no");
                    setTimeout(_ => this._commondata.showLoader(false), 200);

                }
            );
        }
        else
        {
            this.users_model.getMyTPV().then(
            (res:any)=>{
                
                this.myTarrifsType = res;
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            (err)=>{
                
            });
        }
        
        
    }

    createProject(theme)
    {
        if(this.user.getCurrentTarrif()==4 || this.user.getCurrentTarrif()==3)
        {
            let response = this.projectService.createProject(theme).then(
                (res)=>{
                   
                            setTimeout(_ => this._commondata.showLoader(false), 200);
                            this.router.navigate(['/projects/editProject/'+res["id"]]);
                            return;    
                },
                err=>{
                            console.log("form_no");

                }
            );
        }
        else
        {
            let response = this.projectService.createProject(theme).then(
                (res)=>{
                    let response = this.users_model.putUser(this.user.getId(),{"projectCount":this.user.getProjectCount()-1}).then(
                    (restwo:any)=>{
                            setTimeout(_ => this._commondata.showLoader(false), 200);
                            this.router.navigate(['/projects/editProject/'+res["id"]]);
                            return;
                         },
                         err=>{
                                     console.log("form_no");
                                     setTimeout(_ => this._commondata.showLoader(false), 200);

                         }
                    );    
                },
                err=>{
                            console.log("form_no");

                }
            );
        }
    }
    
    checkNumProjects()
    {
        return (0 < this.user.getProjectCount() || this.user.getCurrentTarrif()==4 || this.user.getCurrentTarrif()==3);
    }
    
    goSelected(tarrifId)
    {
        this.browser = window.open(environment.backendUrl + '/api/payment/create/' + this.users_model.getLocalUser().getId() + "/" 
        + tarrifId , "_blank", "hideurlbar=yes, hidenavigationbuttons=true");
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.timer = setInterval(_ =>this.checkStatusWindow(), 1000);

    }
    
    goNextProject(numProjects)
    {
        this.browser = window.open(environment.backendUrl + '/api/payment/create/next/' + this.users_model.getLocalUser().getId() + "/" 
        + numProjects +"/"+"projects" , "_blank", "hideurlbar=yes, hidenavigationbuttons=true");
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.timer = setInterval(_ =>this.checkStatusWindow(), 1000);

    }
    
    checkStatusWindow()
    {
        if (this.browser.closed) {
            // The window is closed.
            clearInterval(this.timer);
            if(/*this.browser.URL.indexOf("/api/payment/complete") > -1*/ true) {
                setTimeout(_ => this._commondata.showLoader(false), 200);
                
                this.users_model.getProf().then(
                (payments: any)=>{
                    this.getInit();

                },
                err=>{

                });


            }
        }
            
    }
    
}
