import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

export class TarrifTypeModule extends Base_Model
{

    private id: number = null;
    private price: number = null;
    private tarrifType: string = null;
    private active: number = null;
    private numProjects: number = null;
    private numUsers: number = null;
    private nextProject: number = null;
    
    public getId()
    {
        return this.id;
    }
    public getPrice()
    {
        return this.price;
    }
    public getTarrifType()
    {
        return this.tarrifType;
    }
    public setTarrifType(data)
    {
        this.tarrifType = data;
        return this;
    }
    public getNumProjects()
    {
        return this.numProjects;
    }
    public getNumUsers()
    {
        return this.numUsers;
    }
    public getNextProject()
    {
        return this.nextProject;
    }
    public getActive()
    {
        return this.active;
    }
    
    

}