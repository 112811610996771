﻿import { Component, OnInit } from '@angular/core';
import { CommonDataService } from './../common-data.service';
import { ThemeService } from './../../services/ThemeService';
import { FieldsService } from './../../services/FieldsService';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../shared/_services/index';

import * as shape from 'd3-shape';
declare var window: any;

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit {

    myThemes = [];
    mainThemeId;
    currentTheme;
    constructor(
    private _commondata: CommonDataService,
    //public form: Form,
    public themeService: ThemeService,
    public router: Router,
    public route: ActivatedRoute,
    private modalService: ModalService,
    public fieldService: FieldsService
    ) { }
    
    ngOnInit()
    {
        this.onInit();
    }
    
    onInit() 
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let response = this.themeService.getMyThemes().then(
            (res:any)=>{
                this.myThemes = res;  
                console.log(this.myThemes);
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                console.log("form_no");
                setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
    }
    createTheme()
    {
        let response = this.themeService.createTheme().then(
            (res)=>{
                
                this.onInit();
                
            },
            err=>{
                        console.log("form_no");

            }
        );
    }
    activeTheme(theme)
    {
        setTimeout(_ => this._commondata.showLoader(true), 1);
        this.currentTheme = theme;
        console.log(this.currentTheme);
        setTimeout(_ => this._commondata.showLoader(false), 1);
        
        
    }
    checkTheme(theme)
    {
        console.log(theme);
        console.log(this.currentTheme);
        if(!this.currentTheme)
        {
            return false;
        }
        return this.currentTheme.id==theme.id;
    }
    
    addVariable(cluster)
    {
        this.modalService.open("addVariable");
    }
    
    closeModal(id: string) {
        this.modalService.close(id);
    }
    
    setDeleteTheme(theme)
    {
        this.currentTheme = theme;
        this.modalService.open("deleteThemeModal");
    }
    
    deleteTheme()
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let response = this.themeService.deleteTheme(this.currentTheme.id).then(
            (res:any)=>{
                this.onInit();
            },
            err=>{
                console.log("form_no");
                setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
    }
    


}
