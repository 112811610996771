import {Injectable} from '@angular/core';
import {Api} from '../providers/api';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {RequestOptions, Headers} from '@angular/http';
import {FieldsModule} from '../models/FieldsModule';
import {BandModule} from '../models/BandModule';
import {Users} from './Users';

@Injectable()
export class FieldsService 
{
    constructor(public UsersService: Users,private api: Api) 
    {
        this.UsersService = UsersService;
    };

    createBoard(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('api/boards', data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    let fields: FieldsModule = new FieldsModule();
                    fields.initialize(res);
                    console.log(fields);
                    resolve(fields);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    updateBoard(id,data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.put('api/boards/'+id, data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    let fields: FieldsModule = new FieldsModule();
                    fields.initialize(res);
                    console.log(fields);
                    resolve(fields);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    getBoard(id)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.get('api/boards/'+id).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
//                    let fields: FieldsModule = new FieldsModule();
//                    fields.initialize(res);
//                    console.log(fields);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    getRoom(id)
    {
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.get('api/rooms/'+id).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
    }
    createDiagram(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('api/diagrams', data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    let fields: FieldsModule = new FieldsModule();
                    fields.initialize(res);
                    console.log(fields);
                    resolve(fields);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    getDiagram(id)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.get('api/diagrams/'+id).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    let fields: FieldsModule = new FieldsModule();
                    fields.initialize(res);
                    console.log(fields);
                    resolve(fields);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    updateDiagram(id,data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.put('api/diagrams/'+id, data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    let fields: FieldsModule = new FieldsModule();
                    fields.initialize(res);
                    console.log(fields);
                    resolve(fields);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    createImage(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('api/images', data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    let fields: FieldsModule = new FieldsModule();
                    fields.initialize(res);
                    console.log(fields);
                    resolve(fields);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    updateImage(id,data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.put('api/images/'+id, data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    let fields: FieldsModule = new FieldsModule();
                    fields.initialize(res);
                    console.log(fields);
                    resolve(fields);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    getImage(id)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.get('api/images/'+id).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    let fields: FieldsModule = new FieldsModule();
                    fields.initialize(res);
                    console.log(fields);
                    resolve(fields);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    createPicture(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('api/pictures', data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    let fields: FieldsModule = new FieldsModule();
                    fields.initialize(res);
                    console.log(fields);
                    resolve(fields);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    getPicture(id)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.get('api/pictures/'+id).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    let fields: FieldsModule = new FieldsModule();
                    fields.initialize(res);
                    console.log(fields);
                    resolve(fields);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    updatePicture(id,data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.put('api/pictures/'+id, data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    let fields: FieldsModule = new FieldsModule();
                    fields.initialize(res);
                    console.log(fields);
                    resolve(fields);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    createParagraf(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('api/paragrafs', data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    let fields: FieldsModule = new FieldsModule();
                    fields.initialize(res);
                    console.log(fields);
                    resolve(fields);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    updateParagraf(id,data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.put('api/paragrafs/'+id, data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    let fields: FieldsModule = new FieldsModule();
                    fields.initialize(res);
                    console.log(fields);
                    resolve(fields);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    createOpts(choiceId,optsData)
    {
        let lthis = this;
        let data = {
            "idChoice":choiceId,
            "opts":optsData
        };
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('api/create/opts/', data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    updateLine(id,data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.put('api/lines/'+id, data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
//                    let fields: FieldsModule = new FieldsModule();
//                    fields.initialize(res);
//                    console.log(fields);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    getParagraf(id)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.get('api/paragrafs/'+id, ).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    let fields: FieldsModule = new FieldsModule();
                    fields.initialize(res);
                    console.log(fields);
                    resolve(fields);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    getLine(id)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.get('api/lines/'+id).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    let fields: FieldsModule = new FieldsModule();
                    fields.initialize(res);
                    console.log(fields);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    createLines(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('api/lines', data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    createTextArea(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('api/textareas', data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    createChoice(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('api/choices', data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    createOpt(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('api/opts', data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    updateOpts(choiceId,optsData)
    {
        let lthis = this;
        let data = {
            "idChoice":choiceId,
            "opts":optsData
        };
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('api/update/opts/', data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    uploadImage(id:number, image: File)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            console.log(image);
            let extension = image.name.split(".").pop();
            const formData = new FormData();
            formData.append('file', image, id+"."+extension);
            console.log(formData);
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                lthis.api.post('api/image/uploadImage', formData, options).subscribe(
                (res: any) =>
                {
                    console.log(res);
                    resolve(res);
                },
                (err) =>
                {
                    console.log(" Error.. \n " + err);
                    reject(err);
                });
            });
        }    
    }
    
    uploadPicture(id:number, image: File)
    {
        console.log(image);
      const formData = new FormData();
      formData.append('file', image, id+"");
      console.log(formData);
      let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.post('api/picture/uploadImage', formData).subscribe(
            (res: any) =>
            {
                console.log(res);
                resolve(res);
            },
            (err) =>
            {
                console.log(" Error.. \n " + err);
                reject(err);
            });
        });
    }
    
    getAllImage()
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/image/getAllImage').map(res => res.json()).subscribe(
            (res: any) =>
            {
                console.log(res);
                resolve(res);
            },
            (err) =>
            {
                console.log(" Error.. \n " + err);
                reject(err);
            });
        });
    }
    
    deleteField(field)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.delete('api/fields/'+field.id).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    
                    console.log(res);
                    resolve(res);
                            
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    putField(id,data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.put('api/fields/'+id,data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    
                    console.log(res);
                    resolve(res);
                            
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    deleteOrphanLine(form)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.delete('api/orphan/lines/'+form.id+"").subscribe(
                (res: any) =>
                {
                    console.log(res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    editColFields(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('api/block/setColumns', data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    editField(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            console.log(data);
            lthis.api.post('api/block/setSort',data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    createGroup(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            console.log(data);
            lthis.api.post('api/bands',data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    myGroup(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            console.log(data);
            lthis.api.post('api/my/bands/'+data,"").map(res => res.json()).subscribe(
                (res: any) =>
                {
                    let bands = [];
                    console.log("Register result ", res);
                    console.log(res);
                    for(let jband of res)
                    {
                        let band: BandModule = new BandModule();
                        band.initialize(jband);
                        bands.push(band);
                    }
                    console.log(bands);
                    resolve(bands);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    oneGroup(project,step)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.get('api/one/band/'+project.id+"/"+step,"").map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    
                    let band: BandModule = new BandModule();
                    band.initialize(res);
                    
                    // IS OPTIONAL?
                    if(res.block!=null)
                    {
                        band.setBlockId(res.block.id);
                        band.setOptional(res.block.optional);
                        band.setQuestion(res.block.question);
                        if(res.block.question && res.block.optional==1)
                        {
                            band.setOptional(1);
                            band.setQuestion(res.block.question);
                            //CHECK IF HAS THE RESPONSE ON THE PROJECT
                            if(project.getAnswer() && 
                            project.getAnswer()[res.block.id+"_b"] &&
                            project.getAnswer()[res.block.id+"_b"]["b"])
                            {
                                if(project.getAnswer()[res.block.id+"_b"]["b"]=="0")
                                {
                                    // display the posible answers if the user says YES to the block question
                                    band.setOptional(0);
                                }
                            }
                            console.log(res.block);
                        }
                    }
                    console.log(res);
                    resolve(band);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    myNav(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            console.log(data);
            lthis.api.post('api/my/nav/'+data,"").map(res => res.json()).subscribe(
                (res: any) =>
                {
//                    let bands = [];
//                    console.log("Register result ", res);
//                    for(let jband of res)
//                    {
//                        let band: BandModule = new BandModule();
//                        band.initialize(jband);
//                        bands.push(band);
//                    }
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    putBandField(field,band)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.put('api/put/field/band/'+field+"/"+band,"").map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    updateBand(id,value)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.put('api/bands/'+id,value).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    deleteBand(id,main)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.delete('api/band/'+id+"/"+main).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    editCondition(id,conditions)
    {
        let data = {
            "number" : id,
            "conditions" : conditions
        };
        
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('api/edit/condition/',data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    getConditions(id)
    {
        
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.get('api/get/conditions/'+id).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    deleteCondition(id)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.delete('api/fettles/'+id).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    getMyFields(formId)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.get('api/field/getMyFields/'+formId).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    putFieldStatus(id,subData)
    {
        let data = {
            "id" : id,
            "status" : subData.status
        };
        
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.put('put/field/status',data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    
                    console.log(res);
                    resolve(res);
                            
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    getFixed()
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.get('api/my/fixed/fields').map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
}

