﻿import { Component, OnInit, Input, ElementRef,AfterViewInit, ViewChild} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonDataService } from './../common-data.service';
import { Form } from './../../services/Form';
import { Users } from './../../services/Users';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import {Observable} from 'rxjs/Rx';
import { ProjectService } from './../../services/ProjectService';
import { FieldsService } from './../../services/FieldsService';
import { ProjectModule } from './../../models/ProjectModule';
import { ModalService } from './../shared/_services/index';
import {} from '@types/googlemaps';
//import html2canvas from 'html2canvas';
import * as shape from 'd3-shape';
declare var window: any;
declare var google: any;
declare var html2canvas: any;

@Component({
  selector: 'app-field-container',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss']
})
export class FieldComponent implements OnInit {

    @ViewChild('mapContainer') gmap: ElementRef;
    map: google.maps.Map;
    @ViewChild('canvas') public canvas: ElementRef;

    private cx: CanvasRenderingContext2D
    public width = 400;
    public height = 400;
    myforms = [];
    @Input() field;
    @Input() project;
    @Input() fieldType;
    elements = [];
    boardArray;
    diagramArray = {};
    boardAnswers = {};
    paragrafAnswers = {};
    cellx;
    celly;
    selectedFile: File = null;
    ImgGalery = {};
    fieldEdit;
    imgCanvas;
    mapOptions;
    markers = []; 
    imgStatus = "image";
    translateImageSize = "";
    constructor(
    private _commondata: CommonDataService,
    public router: Router,
    public user_model: Users,
    public route: ActivatedRoute,
    public projectService: ProjectService,
    public fieldsService: FieldsService,
    private modalService: ModalService,
    translate: TranslateService,
    ) {
        let respuesta = translate.get('Image_Size_should_not_be_greater_than_1mb').subscribe(res => { this.translateImageSize = res; });
      }
    ngOnInit() 
    {
//        let lat = 40.730610;
//        let lng = -73.935242;
//        let coordinates = new google.maps.LatLng(lat, lng);
//        
//        this.mapOptions= {center: coordinates,zoom: 8};
        
        //setTimeout(_ => this._commondata.showLoader(true), 200);
        console.log(this.project);
        console.log(this.field);
        console.log(this.fieldType);
        
        if(this.fieldType == 'Board')
        {
            this.setUpTable();
        }
        
        
        if(this.fieldType == 'Diagram')
        {
            this.setUpDiagram();
        }
        //setTimeout(_ => this._commondata.showLoader(false), 200);
    }
    mapInitializer() 
    {
        
        this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
        let lthis = this;
        this.map.addListener('click', function(event) 
        {  
            lthis.addMarker(event.latLng);  
        });
        this.map.addListener("rightclick",function(event){
            lthis.removeMarker();
        });
    }
    addMarker(location) 
    {  
        var marker = new google.maps.Marker({  
          position: location,  
          map: this.map  
        });  
        this.markers.push(marker);  
    }
    removeMarker()
    {
        let lastMark = this.markers.pop();
        lastMark.setMap(null);
    }
    saveMap(map)
    {
//        let lthis = this;
//        html2canvas(document.getElementsByTagName("body")[0]).then(canvas => {
//            console.log(canvas.toDataURL());
//            lthis.canvas.nativeElement.src = canvas.toDataURL();
////            this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
////            this.downloadLink.nativeElement.download = 'marble-diagram.png';
////            this.downloadLink.nativeElement.click();
//        });
        
        
//        html2canvas(this.gmap.nativeElement, {
//            useCORS: true,
//            onrendered: function(canvas) {
//                let theCanvas = canvas;
//                console.log(theCanvas.toDataURL());
//                lthis.canvas.nativeElement.src = theCanvas.toDataURL();
//            }
//        });
        console.log("HERE");
        
    }  
    
    public ngAfterViewInit() 
    {
        //setTimeout(_ => this._commondata.showLoader(true), 200);
        if(this.fieldType == 'Picture')
        {
            this.setUpPicture();
        }
        if(this.fieldType == 'Image')
        {
            this.setUpImage();
        }
        if(this.fieldType == 'Line')
        {
            this.setUpParagraf();
        }
        //setTimeout(_ => this._commondata.showLoader(false), 200);
    }
    
    
    removeSpaces(str)
    {
         return str.replace(new RegExp('&nbsp;', "g"),"");
    }
    getImg(img,hasImg)
    {
        if(hasImg != false)
        {
            return this.user_model.getUrl()+"/upload/images/"+img;
        }
        else
        {
            return this.user_model.getUrl()+"/upload/images/notImage.png";
        }    
        
    }
    getProjectPicture(img)
    {
        
        if(this.project.getAnswer()==null)
        {
            
                if( img==null || img=="")
                {
                    return this.user_model.getUrl()+"/upload/images/notImage.png";
                }
                else
                {
                    return this.user_model.getUrl()+"/upload/images/"+img;
                }
            
        }
        else
        {
            if(this.project.getAnswer()[this.field.id] == null)
            {
                
                    if( img==null || img=="")
                    {
                        return this.user_model.getUrl()+"/upload/images/notImage.png";
                    }
                    else
                    {
                        return this.user_model.getUrl()+"/upload/images/"+img;
                    }
                
            }
            else
            {
                    return this.user_model.getAnswersUrl()+""+this.project.getAnswer()[this.field.id];
            }
        }
    }
    getProjectImg(img)
    {
        if(this.project.getAnswer()==null)
        {
            if( img==null || img=="")
            {
                return this.user_model.getUrl()+"/upload/images/notImage.png";
            }
            else
            {
                return this.user_model.getUrl()+"/upload/images/"+img;
            }
        }
        else
        {
            if(this.project.getAnswer()[this.field.id] == null)
            {
                if( img==null || img=="")
                {
                    return this.user_model.getUrl()+"/upload/images/notImage.png";
                }
                else
                {
                    return this.user_model.getUrl()+"/upload/images/"+img;
                }
            }
            else
            {
                return this.project.getAnswer()[this.field.id];
            }
        }
    }
    
    setUpParagraf()
    {
        if(this.field.parent)
        {
            this.field.parent.id = this.field.id;
            this.field.parent.question = this.field.question;
            this.field = this.field.parent;
        }
        
        if(this.project.getAnswer()==null)
        {
            this.paragrafAnswers = {};
        }
        else
        {
            if(this.project.getAnswer()[this.field.id]==null)
            {
                this.paragrafAnswers = {};
                console.log(this.paragrafAnswers);
//                if(this.field.auxType == "switch")
//                {
//                    this.paragrafAnswers = JSON.parse(this.field.extra).low;
//                    this.updateAnswer(this.paragrafAnswers);
//                } 
            }
            else
            {
                this.paragrafAnswers = this.project.getAnswer()[this.field.id];
            }
        }
        console.log(this.project);
        console.log(this.project.getAnswer());
        console.log(this.field.id);
        console.log(this.paragrafAnswers);
        console.log("weeeeeeeeeeeeeeeeeeeeoooooooooooo");
        console.log(this.field);
        
        if(this.field.textarea!=null || this.field.auxType == "switch")
        {
            if(this.field.auxType == "switch")
            {
                if(this.field.choice)
                {
                    if(this.field.choice.opts[0] && this.field.choice.opts[1])
                    {
                        this.field.extra = JSON.stringify({low:this.field.choice.opts[0].text , high: this.field.choice.opts[1].text});
                        
                        if(this.project.getAnswer()[this.field.id]==null)
                        {
                            this.paragrafAnswers = {};
                            this.paragrafAnswers = JSON.parse(this.field.extra).low;
                            this.updateAnswer(this.paragrafAnswers);
                        }
                        
                    }
                }
            }
            console.log(this.paragrafAnswers);
            if(Object.keys(this.paragrafAnswers).length!=0)
            {
                this.elements.push({"id":this.field.id,"type":"text","title":this.field.question,"content":this.paragrafAnswers,"description":this.field.description,"special":this.field.special,"auxType":this.field.auxType,"extra":JSON.parse(this.field.extra)});
            }
            else
            {
                this.elements.push({"id":this.field.id,"type":"text","title":this.field.question,"content":"","description":this.field.description,"special":this.field.special,"auxType":this.field.auxType,"extra":JSON.parse(this.field.extra)});
            }
                
        }
        else if(this.field.choice!=null)
        {
                let title = this.field.question;
                let tipo = this.field.choice.type;
                let opts=[];
                let isImg = false;
                for(let y=0;y<this.field.choice.opts.length;y++)
                {
                    let opt = this.field.choice.opts[y];
                    let text=opt.text;
                    let val=opt.value;
                    let img=opt.img;
                    if(img)
                    {
                        isImg = true;
                    }
                    console.log(opt.img);
                    if(val.includes("{{{img:"))
                    {
                        isImg = true;
                        val = val.replace("{{{img:","");
                        val = val.replace("}}}","");
                        if(Object.keys(this.paragrafAnswers).length>0)
                        {
                            if(this.paragrafAnswers["img:"+val]!="" &&  this.paragrafAnswers["img:"+val]!=null)
                            {
                                opts.push({"class":'dropdown-item col-xl-3 bg-selected',"text":text,"value":val,"img":img});
                            }
                            else
                            {
                                opts.push({"class":'dropdown-item col-xl-3',"text":text,"value":val,"img":img});
                            }
                        }
                        else
                        {
                            opts.push({"class":'dropdown-item col-xl-3',"text":text,"value":val,"img":img});
                        }
                    }
                    else
                    {
                        if(this.paragrafAnswers["img:"+val]!="" &&  this.paragrafAnswers["img:"+val]!=null)
                        {
                            opts.push({"class":'dropdown-item col-xl-3 bg-selected',"text":text,"value":val,"img":img});
                        }
                        else
                        {
                            opts.push({"class":'dropdown-item col-xl-3',"text":text,"value":val,"img":img});
                        }
                        
                    }
                }
                this.elements.push({"id":this.field.id,"typeSelect":this.field.typeSelect,"type":"select","typetwo":tipo,"title":title,"options":opts,"content":this.paragrafAnswers,"isImg":isImg,"description":this.field.description});
        }
//        console.log(this.elements);
        
//        if(this.field.auxType == "ubication")
//        {
//            setTimeout(_ => this.mapInitializer(), 200);
//        }
        
    }
    
    updateAnswerTextArea(value,elementId)
    {
        console.log(document.getElementById(elementId));
        (<HTMLInputElement>document.getElementById(elementId)).value = value;
        this.paragrafAnswers[document.getElementById(elementId).getAttribute("id")] = value;
        this.updateAnswer(this.paragrafAnswers);
    }
    updateAnswerParagraf(event,tipo)
    {
        let elem = event.target;
        let pValue = (<HTMLInputElement>elem).value;
        let pId = (<HTMLElement>elem).getAttribute("id");
        
        this.paragrafAnswers = pValue;
        console.log(this.paragrafAnswers[pId]);
        this.updateAnswer(this.paragrafAnswers);
        if("textarea"==tipo)
        {
            let answers = {};
            if(!this.field.answer)
            {
                answers =  {};
            }
            else
            {
                answers =  JSON.parse(this.field.answer);
            }
            if(!answers[pId])
            {
                answers[pId] = {};
            }

            answers[pId][this.project.getId()] = pValue;
            this.field.answer = JSON.stringify(answers);
            
            
            this.fieldsService.updateLine(this.field.id,{"answer":this.field.answer}).then(
            (data : any)=>{
                console.log(data);
            },
            error=>{
                console.log(error);
            }
        );
        }
    }
    
    // Return an array of the selected opion values
    // select is an HTML select element
    getSelectValues(event) {
        let pId = (<HTMLElement>event.target).getAttribute("data-id");
        let checkboxs = document.getElementById(pId).getElementsByTagName("input");
        let result = {};
        
        for (let i=0;i<checkboxs.length;i++)
        {
            if(checkboxs[i].checked)
            {
                result[i]=checkboxs[i].value;
            }
        }
        
        
        this.paragrafAnswers = result;
        this.updateAnswer(this.paragrafAnswers);
    }
    // Return an array of the selected opion values
    // select is an HTML select element
    setSelectValues(event) {
//        let pId = (<HTMLElement>event.target).getAttribute("id");
        let options = (<HTMLElement>event.target).getElementsByTagName("option");
        let result = {};
        
        for (let i=0;i<options.length;i++)
        {
            if(options[i].selected)
            {
                result[i]=options[i].value;
            }
        }
        
        
        this.paragrafAnswers = result;
        this.updateAnswer(this.paragrafAnswers);
    }
    
    selectImg(id,idCurrent)
    {
        let elem = document.getElementById(idCurrent);
        console.log(elem);
        let img = elem.getAttribute("data-img");
        let text = elem.getAttribute("data-text");
        let setSelected = true;
        console.log(this.paragrafAnswers[id]);
        if(this.paragrafAnswers==null)
        {
            this.paragrafAnswers = {};
        }
        if(this.paragrafAnswers[img]==null || this.paragrafAnswers[img]=="")
        {
            this.paragrafAnswers = {};
            this.paragrafAnswers[img] = text;
            setSelected = true;
        }
        else
        {
            this.paragrafAnswers[img] = "";
            setSelected = false;
        }
        let imgId = img.replace("img:", "");
        this.setSelectedImage(id+"_"+imgId,setSelected);
        this.updateAnswer(this.paragrafAnswers);
        
    }
    setSelectedImage(id,status)
    {
        console.log(id);
        let idp=id.split("_")[0];
        for(let i=0;i<document.querySelectorAll("[id*='"+idp+"']").length;i++)
        {
            document.querySelectorAll("[id*='"+idp+"']")[i].classList.remove("bg-selected");
        }
        if(status)
        {
            document.getElementById(id).className = "dropdown-item col-xl-3 bg-selected";
        }
        else
        {
            document.getElementById(id).className = "dropdown-item col-xl-3";
        }
    }
    setUpTable()
    {
        console.log(this.field);
        this.boardArray = [];
        if(this.project.getAnswer()==null)
        {
            this.boardAnswers = {};
        }
        else
        {
            if(this.project.getAnswer()[this.field.id]==null)
            {
                this.boardAnswers = {};
            }
            else
            {
                this.boardAnswers = this.project.getAnswer()[this.field.id];
            }
        }    
        for(let i=0;i<this.field.rooms.length;i++)
        {
            let res = this.field.rooms[i];
            let cell = [];
            cell["board"] = res["board"];
            cell["colPos"] = res["colPos"];
            cell["colSpan"] = res["colSpan"];
            cell["content"] = res["content"];
            cell["rowPos"] = res["rowPos"];
            cell["rowSpan"] = res["rowSpan"];
            cell["border"] = res["border"];
            cell["background"] = res["background"];
            cell["font"] = res["font"];
            cell["id"] = res["id"];
            cell["status"] = res["status"];
            
            if(typeof this.boardArray[res["rowPos"]] === 'undefined') {
                this.boardArray[res["rowPos"]] = [];
            }
            this.boardArray[res["rowPos"]][res["colPos"]] = cell;
            if(this.cellx<+(+res["rowPos"])+1)
            {
                this.cellx = +(+res["rowPos"])+1;
            }
            if(this.celly<+(+res["colPos"])+1)
            {
                this.celly = +(+res["colPos"])+1;
            }
            console.log(this.boardArray);
        }
        
        
        let str = '<div class="table-responsive mt-15"><table class="table text-right mb-0" style="border-spacing: 10px;border-collapse: separate;">';
        for(let i=0;i<this.boardArray.length;i++)
        {
            str = str + "<tr> ";
            
            for(let j=0;j<this.boardArray[i].length;j++)
            {
                if(this.boardArray[i][j] && this.boardArray[i][j]["status"]!="merge")
                {
                    let elemId="x"+i+"y"+j;
                    this.boardArray[i][j]["style"] =
                    {
                        'padding':'3px',
                        'border-radius': '10px',
                        'border':'1px solid '+this.boardArray[i][j]["border"],
                        'background-color':this.boardArray[i][j]["background"]
                        
                    };
                    this.boardArray[i][j]["styleInput"] =
                    {
                        'padding':'.375rem .75rem',
                        'display':'inline-block',
                        'background-color':this.boardArray[i][j]["background"],
                        'color':this.boardArray[i][j]["font"]
                        
                    };
                    this.boardArray[i][j]["colPos"] = i;
                    this.boardArray[i][j]["rowPos"] = j;
                }
            }
            str = str + " </tr>";
            console.log(str);
        }
        str = str + " </table></div>";
        console.log(this.boardArray);
        
        
    }
    getRoomContent(room)
    {
        if(this.project.getAnswer()==null)
        {
            return room.content;
        }
        else
        {
            if(this.boardAnswers[room.id]==null)
            {
                return room.content;
            }
            else
            {
                return this.boardAnswers[room.id];
            }
        }
    }
    changeCellAnswer(event)
    {
        let elem = event.target;
        let roomValue = (<HTMLInputElement>elem).value;
        let roomId = (<HTMLElement>elem).getAttribute("id");
        
        this.boardAnswers[roomId] = roomValue;
        
        this.updateAnswer(this.boardAnswers);
        
        
    }
    //////PICTURE///////
    setUpPicture()
    {

        console.log(this.field);
        this.fieldEdit = JSON.parse(this.field.content).l;
        
        
    }
    savePicture()
    {
        console.log(this.imgCanvas.lowerCanvasEl);
        this.imgCanvas.lowerCanvasEl.setAttribute("crossorigin","Anonymous");
        var blobBin = window.atob(this.imgCanvas.lowerCanvasEl.toDataURL('image/png').split(',')[1]);
        var array = [];
        for(var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }
        var file = new Blob([new Uint8Array(array)], {type: 'image/png'});
        //canvasMerge.style.display="none";
        this.uploadPicture(file);
           
    }
    
    uploadPicture(file) {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        console.log(this.project.getAnswer());
        console.log(this.project.getId()+"   "+this.field.id);
        this.projectService.uploadPicture(this.project.getId(),this.field.id,file).then(
            (data : any)=>{
                console.log(data);
                let extension = file.name.split(".")[1];
                console.log(extension);
                this.project.updateAnswer(this.field.id,this.user_model.getUrl()+"/upload/answers/"+this.project.getId()+"-"+this.field.id+"."+extension);
                let strdata = JSON.stringify(this.project.getAnswer());
                console.log(strdata);
                this.projectService.putProject(this.project.getId(),{"answer":strdata}).then(
                    (data : any)=>{
                        console.log(data);
                        setTimeout(_ => this._commondata.showLoader(false), 200);
                    },
                    error=>{
                        console.log(error);
                    }
                );
            },
            error=>{
                console.log(error);
            }
        );
        
   
    }
    ///////////////////
    ////IMAGE//////////
    setUpImage()
    {
        
        this.imgStatus = "image";
        this.setUpFabric();
        console.log(this.imgCanvas.wrapperEl);
        this.imgCanvas.wrapperEl.style.display="none";
//        if(document.querySelector(".canvas-container"))
//        {
//            (<HTMLElement>document.querySelector(".canvas-container")).style.display="none";
//        }
        console.log(this.field);
        this.fieldEdit = JSON.parse(this.field.content).l;
        let lthis = this;
        document.querySelector("#container_"+this.field.id).addEventListener("mouseover",function(event){
            let elemImg = document.querySelector("#img_"+lthis.field.id);
            let btnImg = document.querySelector("#img_upload_button_"+lthis.field.id);
            //let btngal = document.querySelector("#img_gallery_button_"+lthis.field.id);
            (<HTMLElement>elemImg).style.opacity = "0.3";
            (<HTMLElement>btnImg).style.opacity = "1";
            //(<HTMLElement>btngal).style.opacity = '1';
        });
        
        document.querySelector("#container_"+this.field.id).addEventListener("mouseout",function(event){
            let elemImg = document.querySelector("#img_"+lthis.field.id);
            let btnImg = document.querySelector("#img_upload_button_"+lthis.field.id);
            //let btngal = document.querySelector("#img_gallery_button_"+lthis.field.id);
            (<HTMLElement>elemImg).style.opacity = '1';
            (<HTMLElement>btnImg).style.opacity = '0';
            //(<HTMLElement>btngal).style.opacity = '0';
        });
        
        
    }
    onFileChanged(event) {
        if(event.target.files[0].size>1056770)
        {
            alert(this.translateImageSize);  
            return false;
        }
    this.selectedFile = event.target.files[0];
        if (event.target.files && event.target.files[0]) {
//            this.imgPreview=true;
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url
            console.log(event.target);
            reader.onload = (event) => 
            { // called once readAsDataURL is completed
              //this.url = reader.result;
                this.uploadFile();
            }
        }
    }
    
    uploadFile() {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.projectService.uploadPicture(this.project.getId(),this.field.id,this.selectedFile).then(
            (data : any)=>{
                let extension = this.selectedFile.name.split(".")[1];
                console.log(extension);
                this.project.updateAnswer(this.field.id,this.user_model.getAnswersUrl()+this.project.getId()+"-"+this.field.id+"."+extension);
                let strdata = JSON.stringify(this.project.getAnswer());
                console.log(strdata);
                this.projectService.putProject(this.project.getId(),{"answer":strdata}).then(
                    (data : any)=>{
                        console.log(data);
                        let lthis = this;
                        (<HTMLImageElement>document.querySelector("#img_"+this.field.id)).src = this.project.getAnswer()[this.field.id] + "?" + new Date().getTime();
                        setTimeout(_ => lthis._commondata.showLoader(false), 200);
                    },
                    error=>{
                        console.log(error);
                    }
                );
              },
              error=>{
                console.log(error);
              }
            );
        
    }
    convertToCanvas()
    {
        this.imgCanvas.wrapperEl.style.display="block";
//        if(document.querySelector(".canvas-container"))
//        {
//            (<HTMLElement>document.querySelector(".canvas-container")).style.display="block";
//        }
//        this.imgCanvas = document.querySelector("#canvas_"+this.field.id);
//        this.cx = this.imgCanvas.getContext('2d');
//
//        // set the width and height
//        this.imgCanvas.width = (<HTMLElement>document.querySelector("#img_"+this.field.id)).offsetWidth;
//        
//        this.imgCanvas.height = (<HTMLElement>document.querySelector("#img_"+this.field.id)).offsetHeight;
//        
        let img = (<HTMLImageElement>document.querySelector("#img_"+this.field.id));
        
        this.getImgSize(img.src);
        
    }
    
    getImgSize(imgSrc) {
        var fabric = window.fabric;

        var newImg = new Image();
        newImg.crossOrigin = 'anonymous';
        var lthis = this;
        newImg.onload = function() {
            
            var height = newImg.height;
            var width = newImg.width;
            
            var f_img = new fabric.Image(newImg);
                lthis.imgCanvas.setHeight((<HTMLElement>document.querySelector("#img_"+lthis.field.id)).offsetHeight);
                lthis.imgCanvas.setWidth((<HTMLElement>document.querySelector("#img_"+lthis.field.id)).offsetWidth);
                lthis.imgCanvas.renderAll();
                lthis.imgCanvas.setBackgroundImage(f_img, lthis.imgCanvas.renderAll.bind(lthis.imgCanvas), {
                   scaleX: lthis.imgCanvas.width / f_img.width,
                   scaleY: lthis.imgCanvas.height / f_img.height
                });
            lthis.imgStatus = "picture";
            
        }

        newImg.src = imgSrc; // this must be done AFTER setting onload
    }
    
    returnToImg()
    {
        this.imgStatus = "image";
        console.log(this.imgCanvas);
        this.imgCanvas.wrapperEl.style.display="none";
//        if(document.querySelector(".canvas-container"))
//        {
//            (<HTMLElement>document.querySelector(".canvas-container")).style.display="none";
//        }
    }
    ///////////////////
    /////DIAGRAM///////
    setUpDiagram()
    {
        if(this.project.getAnswer()==null)
        {
            console.log(this.field);
            this.diagramArray = {};
            this.diagramArray[0] = {};
            this.diagramArray[0][0] = {};
            this.diagramArray[0][0]["content"] = 0;
            this.diagramArray[0][1] = {};
            this.diagramArray[0][1]["content"] = 0;
            console.log(Object.keys(this.diagramArray).length);
        }
        else
        {

            if(this.project.getAnswer()[this.field.id]==null)
            {
                if(Object.keys(this.diagramArray).length == 0)
                {
                    console.log(this.field);
                    this.diagramArray = {};
                    this.diagramArray[0] = {};
                    this.diagramArray[0][0] = {};
                    this.diagramArray[0][0]["content"] = 0;
                    this.diagramArray[0][1] = {};
                    this.diagramArray[0][1]["content"] = 0;
                    console.log(Object.keys(this.diagramArray).length);
                }
            }
            else
            {
                this.diagramArray = this.project.getAnswer()[this.field.id];
            }
        }
        
        this.fieldEdit = JSON.parse(this.field.content);
        if(this.fieldEdit.x != null)
        {
            this.field.x = this.fieldEdit.x;
        }
        else
        {
            this.field.x = "";
        }
        if(this.fieldEdit.y != null)
        {
            this.field.y = this.fieldEdit.y;
        }
        else
        {
            this.field.y = "";
        }
        this.updateDiagram();
          
    }
    
    changeValueDiagram(strId)
    {
        console.log(document.querySelector("#"+strId));
        let fx = document.querySelector("#"+strId).getAttribute("data-x");
        console.log(fx);
        let fy = document.querySelector("#"+strId).getAttribute("data-y");
        console.log(fy);
        this.diagramArray[fx][fy]["content"] = (<HTMLInputElement>document.querySelector("#"+strId)).value;
      
        this.updateAnswer(this.diagramArray);
        this.updateDiagram();
    }
    
    
    updateDiagram()
    {
        console.log("eeeeee");
        let check=true;
        for(let i=0; i<Object.keys(this.diagramArray).length; i++)
        {
            
            for(let y=0; y<Object.keys(this.diagramArray[i]).length; y++)
            {
                console.log(this.diagramArray[i][y]);
                if(this.diagramArray[i][y]["content"] == "" || this.diagramArray[i][y]["content"] == 0)
                {
                    
                    check = false;
                }
            }
        }
//        if(check)
//        {
//            let newLine = Object.keys(this.diagramArray).length;
//            this.diagramArray[newLine] = {};
//            this.diagramArray[newLine][0] = {};
//            this.diagramArray[newLine][0]["content"] = 0;
//            this.diagramArray[newLine][1] = {};
//            this.diagramArray[newLine][1]["content"] = 0;
//        }
        console.log(this.diagramArray);
        
        let str = '<div class="table-responsive mt-15"><table class="table mb-0" style="border-spacing: 10px;border-collapse: separate;">';
        str = str + "<thead><th>"+this.field.x+"</th><th>"+this.field.y+"</th></thead><tbody>"
        console.log(Object.keys(this.diagramArray).length);
        for(let i=0;i<Object.keys(this.diagramArray).length;i++)
        {
            str = str + "<tr> ";
            console.log(Object.keys(this.diagramArray[i]).length);
            for(let j=0;j<Object.keys(this.diagramArray[i]).length;j++)
            {
                    let elemId="diagram_"+this.field.id+"_x"+i+"y"+j;
                    let text ="";
                    if(j==0)
                    {
                        text = '<td  class="btn-secondary"  data-x="'+i+'" data-y="'+j+'" > '+'\n\
                                                        <input type="text" id="'+elemId+'" data-x="'+i+'" data-y="'+j+'" class="form-control submit-table addEvent" value="'+this.diagramArray[i][j]["content"]+'">'+" </td>";
                    }
                    else
                    {
                        text = '<td  class="btn-secondary"  data-x="'+i+'" data-y="'+j+'" > '+'\n\
                                                        <input type="number" id="'+elemId+'" data-x="'+i+'" data-y="'+j+'" class="form-control submit-table addEvent" value="'+this.diagramArray[i][j]["content"]+'">'+" </td>";
                    }
                    str = str + text;
            }
            str = str + " </tr>";
        }
        str = str + " </body></table></div>";
        
        console.log(this.diagramArray);
        setTimeout(_ => this.checkExist(str), 200);
        
    }
    diagramInsertLine()
    {
        let newLine = Object.keys(this.diagramArray).length;
        this.diagramArray[newLine] = {};
        this.diagramArray[newLine][0] = {};
        this.diagramArray[newLine][0]["content"] = 0;
        this.diagramArray[newLine][1] = {};
        this.diagramArray[newLine][1]["content"] = 0;
        this.updateAnswer(this.diagramArray);
        this.updateDiagram();
    }
    checkExist(str)
    {
        if(document.getElementById(this.field.id+"_innerDiagramTable")!=null)
        {
            document.getElementById(this.field.id+"_innerDiagramTable").innerHTML=str;
            
            let elements = document.getElementById(this.field.id+"_innerDiagramTable").getElementsByClassName("addEvent");
            for(let y=0;y<elements.length;y++)
            {
                elements[y].addEventListener("change", (e:Event) => this.changeValueDiagram(elements[y].getAttribute("id")));
            }
            
        }
    }
    //////////UPDATEANSWER////////////
    updateAnswer(updata)
    {
        console.log(updata);
        this.project.updateAnswer(this.field.id,updata);
        let strdata = JSON.stringify(this.project.getAnswer());
        console.log(strdata);
        this.projectService.putProject(this.project.getId(),{"answer":strdata,"modify":"1"}).then(
            (data : any)=>{
                console.log(data);
            },
            error=>{
                console.log(error);
            }
        );
    }
    hasLeyenda(content)
    {
        if(content!=null)
        {
            if(content.l!=null)
            {
                if(content.l!="")
                {
                    return true;
                }
            }
        }
        return false;
    }
    openModal(id: string) {
        this.modalService.open(id);
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.fieldsService.getAllImage().then(
        (data : any)=>{
            console.log(data);
            let option = "";
            for(let i=0;i<data.length;i++)
            {
                let valu = data[i].split("/");
                let imgName = valu[valu.length-1]; 
                
                option = option + "<div style='width: 24%;display: inline-block;max-width: 100%;' class='dropdown-item col-xl-3'><img style='width:100%;cursor:pointer;' class='addImg' data-img='"+imgName+"' src='"+data[i]+"'></div>";

            }
            document.querySelector("#submenu").innerHTML = option;

            let elements = document.getElementsByClassName("addImg");
            for(let y=0;y<elements.length;y++)
            {
                elements[y].addEventListener("click", (e:Event) => this.selectingImg(elements[y].getAttribute("src"),elements[y].getAttribute("data-img")));
            }
            setTimeout(_ => this._commondata.showLoader(false), 200);

        },
        (err)=>{

        });             
    }
    closeModal(id:string)
    {
        this.modalService.close(id);
    }
    selectingImg(src,imgName)
    {
        //setTimeout(_ => this._commondata.showLoader(true), 200);
        
                console.log(this.project.getAnswer());
                this.project.updateAnswer(this.field.id,src);
                let strdata = JSON.stringify(this.project.getAnswer());
                console.log(strdata);
                this.projectService.putProject(this.project.getId(),{"answer":strdata}).then(
                    (data : any)=>{
                        console.log(data);
                        let lthis = this;
                        (<HTMLImageElement>document.querySelector("#img_"+this.field.id)).src = this.project.getAnswer()[this.field.id] + "?" + new Date().getTime();
                        //setTimeout(_ => lthis._commondata.showLoader(false), 200);
                    },
                    error=>{
                        console.log(error);
                    });
    }
    
    
    checkSelected(content,count,value)
    {
        if(content == null)
        {
            return false;
        }
        if(content[count]==null)
        {
            return false;
        }
        return content[count] == value && value != "";
    }
    
    getAnswer(answers,id)
    {
        if(!answers)
        {
            return false;
        }
        let ans = JSON.parse(answers);
        if(!ans[id])
        {
            return false;
        }
        console.log(ans[id]);
        
        let opts = [];
        for(let i of Object.keys(ans[id]))
        {
            opts.push(ans[id][i]);
        }
        
        return opts;
    }
    leyendaChange(event)
    {
        let data = {
            "l":(<HTMLInputElement>event.target).value
        };
        this.project.updateAnswer(this.field.id+"_l",data);
        let strdata = JSON.stringify(this.project.getAnswer());
        this.projectService.putProject(this.project.getId(),{"answer":strdata}).then(
            (data : any)=>{
                console.log(data);
            },
            error=>{
                console.log(error);
            }
            );
    }
    getLeyenda(field)
    {
        if(this.project.getAnswer()[field.id+"_l"])
        {
            return this.project.getAnswer()[field.id+"_l"]["l"];
        }
        else
        {
            console.log(field.content);
            if(JSON.parse(field.content))
            {
                if(JSON.parse(field.content).l)
                {
                    return JSON.parse(field.content).l;
                }
            }
        }
        return "";
    }
    /////canvas draw conponent/////
    addLayer(intem,tem){

        }
    setUpFabric(){
        var lthis = this;
        var fabric = window.fabric;

        this.imgCanvas = new window.fabric.Canvas('canvas_'+this.field.id, {
          isDrawingMode: false
        });
        let limgCanvas = this.imgCanvas;

        fabric.Object.prototype.transparentCorners = false;

        var drawingModeEl = document.getElementById('drawing-mode_'+this.field.id),
            drawingOptionsEl = document.getElementById('drawing-mode-options_'+this.field.id),
            dragOptionsEl = document.getElementById('drag-mode-options_'+this.field.id),
            drawingColorEl = document.getElementById('drawing-color_'+this.field.id),
            drawingShadowColorEl = document.getElementById('drawing-shadow-color_'+this.field.id),
            drawingLineWidthEl = document.getElementById('drawing-line-width_'+this.field.id),
            drawingShadowWidth = document.getElementById('drawing-shadow-width_'+this.field.id),
            drawingShadowOffset = document.getElementById('drawing-shadow-offset_'+this.field.id),
            drawingSetText = document.getElementById('set-text_'+this.field.id),
            drawingSetBox = document.getElementById('set-box_'+this.field.id),
            drawingSetCircle = document.getElementById('set-circle_'+this.field.id),
            drawingSetLine = document.getElementById('set-line_'+this.field.id),
            //clearEl = document.getElementById('clear-canvas_'+this.field.id),
            saveEl = document.getElementById('save-canvas_'+this.field.id);

//        clearEl.onclick = function() 
//        { 
//            lthis.imgCanvas.clear();
////            lthis.imgStatus = "image";
////            let img = (<HTMLImageElement>document.querySelector("#img_"+lthis.field.id));
////        
////            lthis.getImgSize(img.src);
////            lthis.imgStatus = "picture";
//             
//        };
        saveEl.onclick = function()
        {
            lthis.savePicture()
        }
        
        drawingModeEl.onclick = function() {
            limgCanvas.isDrawingMode = !limgCanvas.isDrawingMode;
            if (limgCanvas.isDrawingMode) {
              drawingModeEl.innerHTML = 'Cancel drawing mode';
              drawingOptionsEl.style.display = '';
              dragOptionsEl.style.display = 'none';
            }
            else {
              drawingModeEl.innerHTML = 'Enter drawing mode';
              drawingOptionsEl.style.display = 'none';
              dragOptionsEl.style.display = '';
            }
          };
        
        
        
        limgCanvas.observe('mouse:down', function(e) { mousedown(e); });
        limgCanvas.observe('mouse:move', function(e) { mousemove(e); });
        limgCanvas.observe('mouse:up', function(e) { mouseup(e); });
        
        var started = false;
        var x = 0;
        var y = 0;
        var fElem = "";

        /* Mousedown */
        function mousedown(e) {
            if(limgCanvas.isDrawingMode){return;}
            if(fElem==""){return;}
            console.log(e);
            //var mouse = limgCanvas.getPointer(e.memo.e);
            started = true;
            x = e.pointer.x;
            y = e.pointer.y;
            
            if(fElem=="text"){
                var square = new fabric.IText('text', {
                    fontFamily: 'arial black',
                    width: 0, 
                    height: 0,
                    left: x, 
                    top: y,
                    fontSize: 16,
                    objecttype: 'text',
                    fill:(<HTMLInputElement>drawingColorEl).value
                });
            }
            if(fElem=="box")
            {
                var square = new fabric.Rect({ 
                    width: 0, 
                    height: 0, 
                    left: x, 
                    top: y, 
                    fill: (<HTMLInputElement>drawingColorEl).value
                });
            }
            if(fElem=="circle")
            {
                var square = new fabric.Circle({ 
                    width: 0, 
                    height: 0,
                     
                    left: x, 
                    top: y, 
                    fill: (<HTMLInputElement>drawingColorEl).value
                });
            }
            if(fElem=="line")
            {
                var square = new fabric.Line([x,y,x+50,y+50],{ 
                    left: x, 
                    top: y, 
                    stroke: (<HTMLInputElement>drawingColorEl).value,
                    strokeWidth:3
                });
            }
            limgCanvas.add(square); 
            limgCanvas.renderAll();
            limgCanvas.setActiveObject(square); 

        }


        /* Mousemove */
        function mousemove(e) {
            if(limgCanvas.isDrawingMode){return;}
            if(fElem==""){return;}
            if(!started) {
                return false;
            }

            //var mouse = limgCanvas.getPointer(e.memo.e);

            var w = Math.abs(e.pointer.x - x),
            h = Math.abs(e.pointer.y - y);

            if (!w || !h) {
                return false;
            }

            var square = limgCanvas.getActiveObject(); 
            square.set('width', w).set('height', h).set("radius",w/2);
            limgCanvas.renderAll(); 
        }

        /* Mouseup */
        function mouseup(e) {
            if(limgCanvas.isDrawingMode){return;}
            if(fElem==""){return;}
            if(started) {
                started = false;
            }

            var square = limgCanvas.getActiveObject();

            limgCanvas.add(square); 
            limgCanvas.renderAll();
            fElem = "";
         } 
        
        drawingSetBox.onclick= function()
        {
            if(fElem=="box")
            {
                fElem = "";
            }
            else
            {
                fElem = "box";
            }
        }
        
        drawingSetText.onclick = function()
        {
            if(fElem=="text")
            {
                fElem = "";
            }
            else
            {
                fElem = "text";
            }
            
        }
        drawingSetCircle.onclick = function()
        {
            if(fElem=="circle")
            {
                fElem = "";
            }
            else
            {
                fElem = "circle";
            }
            
        }
        drawingSetLine.onclick = function()
        {
            if(fElem=="line")
            {
                fElem = "";
            }
            else
            {
                fElem = "line";
            }
            
        }
        
        

//        drawingModeEl.onclick = function() {
//          lthis.imgCanvas.isDrawingMode = !lthis.imgCanvas.isDrawingMode;
//          if (lthis.imgCanvas.isDrawingMode) {
//            drawingModeEl.innerHTML = 'Cancel drawing mode';
//            drawingOptionsEl.style.display = '';
//          }
//          else {
//            drawingModeEl.innerHTML = 'Enter drawing mode';
//            drawingOptionsEl.style.display = 'none';
//          }
//        };

        if (fabric.PatternBrush) {
          var vLinePatternBrush = new fabric.PatternBrush(lthis.imgCanvas);
          vLinePatternBrush.getPatternSrc = function() {

            var patternCanvas = fabric.document.createElement('canvas');
            patternCanvas.width = patternCanvas.height = 10;
            var ctx = patternCanvas.getContext('2d');

            ctx.strokeStyle = this.color;
            ctx.lineWidth = 5;
            ctx.beginPath();
            ctx.moveTo(0, 5);
            ctx.lineTo(10, 5);
            ctx.closePath();
            ctx.stroke();

            return patternCanvas;
          };

          var hLinePatternBrush = new fabric.PatternBrush(lthis.imgCanvas);
          hLinePatternBrush.getPatternSrc = function() {

            var patternCanvas = fabric.document.createElement('canvas');
            patternCanvas.width = patternCanvas.height = 10;
            var ctx = patternCanvas.getContext('2d');

            ctx.strokeStyle = this.color;
            ctx.lineWidth = 5;
            ctx.beginPath();
            ctx.moveTo(5, 0);
            ctx.lineTo(5, 10);
            ctx.closePath();
            ctx.stroke();

            return patternCanvas;
          };

          var squarePatternBrush = new fabric.PatternBrush(lthis.imgCanvas);
          squarePatternBrush.getPatternSrc = function() {

            var squareWidth = 10, squareDistance = 2;

            var patternCanvas = fabric.document.createElement('canvas');
            patternCanvas.width = patternCanvas.height = squareWidth + squareDistance;
            var ctx = patternCanvas.getContext('2d');

            ctx.fillStyle = this.color;
            ctx.fillRect(0, 0, squareWidth, squareWidth);

            return patternCanvas;
          };

          var diamondPatternBrush = new fabric.PatternBrush(lthis.imgCanvas);
          diamondPatternBrush.getPatternSrc = function() {

            var squareWidth = 10, squareDistance = 5;
            var patternCanvas = fabric.document.createElement('canvas');
            var rect = new fabric.Rect({
              width: squareWidth,
              height: squareWidth,
              angle: 45,
              fill: this.color
            });

            var canvasWidth = rect.getBoundingRect().width;

            patternCanvas.width = patternCanvas.height = canvasWidth + squareDistance;
            rect.set({ left: canvasWidth / 2, top: canvasWidth / 2 });

            var ctx = patternCanvas.getContext('2d');
            rect.render(ctx);

            return patternCanvas;
          };

//          var img = new Image();
//          img.src = '../assets/honey_im_subtle.png';
//
//          var texturePatternBrush = new fabric.PatternBrush(canvas);
//          texturePatternBrush.source = img;
        }

        document.getElementById('drawing-mode-selector_'+this.field.id).onchange = function() {
          let rthis = (<HTMLInputElement>this);  
          if (rthis.value === 'hline') {
            lthis.imgCanvas.freeDrawingBrush = vLinePatternBrush;
          }
          else if (rthis.value === 'vline') {
            lthis.imgCanvas.freeDrawingBrush = hLinePatternBrush;
          }
          else if (rthis.value === 'square') {
            lthis.imgCanvas.freeDrawingBrush = squarePatternBrush;
          }
          else if (rthis.value === 'diamond') {
            lthis.imgCanvas.freeDrawingBrush = diamondPatternBrush;
          }
//          else if (rthis.value === 'texture') {
//            canvas.freeDrawingBrush = texturePatternBrush;
//          }
          else {
            lthis.imgCanvas.freeDrawingBrush = new fabric[rthis.value + 'Brush'](lthis.imgCanvas);
          }

          if (lthis.imgCanvas.freeDrawingBrush) {
            lthis.imgCanvas.freeDrawingBrush.color = (<HTMLInputElement>drawingColorEl).value;
            lthis.imgCanvas.freeDrawingBrush.width = parseInt((<HTMLInputElement>drawingLineWidthEl).value, 10) || 1;
            lthis.imgCanvas.freeDrawingBrush.shadow = new fabric.Shadow({
              blur: parseInt((<HTMLInputElement>drawingShadowWidth).value, 10) || 0,
              offsetX: 0,
              offsetY: 0,
              affectStroke: true,
              color: (<HTMLInputElement>drawingShadowColorEl).value,
            });
          }
        };

        drawingColorEl.onchange = function() {
            let rthis = (<HTMLInputElement>this); 
          lthis.imgCanvas.freeDrawingBrush.color = rthis.value;
        };
//        drawingShadowColorEl.onchange = function() {
//          let rthis = (<HTMLInputElement>this);   
//          lthis.imgCanvas.freeDrawingBrush.shadow.color = rthis.value;
//        };
        drawingLineWidthEl.onchange = function() {
          let rthis = (<HTMLInputElement>this);  
          lthis.imgCanvas.freeDrawingBrush.width = parseInt(rthis.value, 10) || 1;
          (<HTMLElement>this.previousSibling).innerHTML = rthis.value;
        };
//        drawingShadowWidth.onchange = function() {
//          let rthis = (<HTMLInputElement>this);
//          lthis.imgCanvas.freeDrawingBrush.shadow.blur = parseInt(rthis.value, 10) || 0;
//          (<HTMLElement>this.previousSibling).innerHTML = rthis.value;
//        };
//        drawingShadowOffset.onchange = function() {
//            let rthis = (<HTMLInputElement>this);
//          lthis.imgCanvas.freeDrawingBrush.shadow.offsetX = parseInt(rthis.value, 10) || 0;
//          lthis.imgCanvas.freeDrawingBrush.shadow.offsetY = parseInt(rthis.value, 10) || 0;
//          (<HTMLElement>this.previousSibling).innerHTML = rthis.value;
//        };

        if (lthis.imgCanvas.freeDrawingBrush) {
          lthis.imgCanvas.freeDrawingBrush.color = (<HTMLInputElement>drawingColorEl).value;
          lthis.imgCanvas.freeDrawingBrush.width = parseInt((<HTMLInputElement>drawingLineWidthEl).value, 10) || 1;
          lthis.imgCanvas.freeDrawingBrush.shadow = new fabric.Shadow({
            //blur: parseInt((<HTMLInputElement>drawingShadowWidth).value, 10) || 0,
            offsetX: 0,
            offsetY: 0,
            affectStroke: true,
            //color: (<HTMLInputElement>drawingShadowColorEl).value,
          });
        }
      }
      
    sliderChange(event)
    {
        let elem = event.target;
        let pValue = (<HTMLInputElement>elem).value;
        let pId = (<HTMLElement>elem).getAttribute("id");
        
        (<HTMLElement>document.getElementById(pId+"_output")).innerHTML = pValue;
    }
    
    switchChange(event)
    {
        let elem = event.target;
        let pValue = (<HTMLInputElement>elem).checked;
        let pId = (<HTMLElement>elem).getAttribute("id");
        console.log("switch value");
        console.log(pValue);
        if(pValue)
        {
            pValue = JSON.parse(this.field.extra).high;
        }
        else
        {
            pValue = JSON.parse(this.field.extra).low;
        }
        
        this.paragrafAnswers = pValue;
        console.log(this.paragrafAnswers[pId]);
        this.updateAnswer(this.paragrafAnswers);
            let answers = {};
            if(!this.field.answer)
            {
                answers =  {};
            }
            else
            {
                answers =  JSON.parse(this.field.answer);
            }
            if(!answers[pId])
            {
                answers[pId] = {};
            }

            answers[pId][this.project.getId()] = pValue;
            this.field.answer = JSON.stringify(answers);
            
            
            this.fieldsService.updateLine(this.field.id,{"answer":this.field.answer}).then(
            (data : any)=>{
                console.log(data);
            },
            error=>{
                console.log(error);
            }
        );
    }  
    
}
