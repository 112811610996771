﻿import { Component, OnInit } from '@angular/core';
import { AppModule } from './../../app.module';
import { CommonDataService } from './../../common-data.service';
import { Users } from '../../../services/Users';
import { UserAuthService } from '../../../services/auth.service';
import { ModalService } from './../../shared/_services/index';
import { Router } from '@angular/router';
import { FormBuilder,FormControl, FormGroup, Validators, NgForm,FormsModule } from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    isInvalid = false;
    onMain: Boolean;
    flag: Boolean;
    login = false;
    logging = false;
    public elementRef;
    signupForm: FormGroup;
    recoveryForm: FormGroup;
    isVisibleError = false;
    wPurl="";
    isLogin = true;
    urlDocs;
    
    constructor(
        public router: Router,
        public _commondata: CommonDataService,
        public user_model: Users,
        public auth: UserAuthService,
        private frmbuilder:FormBuilder,
        public modalService:ModalService,
        translate: TranslateService
    ) { 
        this.onMain = false;
        this.flag = true;
        this.signupForm=frmbuilder.group({  
                username:new FormControl(null, [Validators.required,Validators.minLength(3)]),
                password:new FormControl(null, [Validators.required,Validators.minLength(5)]),
            });
        this.recoveryForm=frmbuilder.group({  
                email:new FormControl(null, [Validators.required,Validators.minLength(3)]),
            });    
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('fr');

        // the lang to use, if the lang isn't available, it will use the current loader to get them      
        translate.use('fr');    
    }

    ngOnInit() {
        this.urlDocs = this.user_model.getDocUrl();
        setTimeout(_ => this._commondata.showLoader(false), 200);
        this.wPurl=this.user_model.getWPUrl();
  }
  
    loginUser(data)
    {
        
        this.isVisibleError = false;
        console.log(this.signupForm.valid);
        if (this.signupForm.valid) {
              console.log('form submitted');
                this.logging = true;
                this._commondata.showLoader(true);
                let response = this.auth.login({
                    'username':data.value.username,
                    'password':data.value.password
                }).then(
                    (res)=>{
                        this.user_model.getProf().then(
                            (res)=>{
                                this.router.navigate(['/']);
                            },
                            err=>{
                                this.logging = false;
                                this.isInvalid = true;
                                this.isVisibleError = true;
                                setTimeout(_ => this._commondata.showLoader(false), 200);
                            }
                        );
                    },
                    err=>{
                        this.isVisibleError = true;
                        this.logging = false;
                        this.isInvalid = true;
                        setTimeout(_ => this._commondata.showLoader(false), 200);
                        
                    }
                );
        } else {
              this.validateAllFormFields(this.signupForm);
              this.isVisibleError = true;
                this.logging = false;
                this.isInvalid = true;
              return;
        }
    }
    
    validateAllFormFields(formGroup: FormGroup) {        
      Object.keys(formGroup.controls).forEach(field => { 
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {            
              control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {       
              this.validateAllFormFields(control);           
            }
        });

    }
    
    recoverPassword(data)
    {
        this.isVisibleError = false;
        console.log(this.recoveryForm.valid);
        if (this.recoveryForm.valid) {
            setTimeout(_ => this._commondata.showLoader(true), 200);
            this.user_model.forgotPassword(data.value.email).then(
                (res) =>
                {
                    this.openModal();
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                },
                (err) =>
                {
                    
                }
            );
        } else {
              this.validateAllFormFields(this.signupForm);
              this.isVisibleError = true;
                this.logging = false;
                this.isInvalid = true;
              return;
        }
    }
    
    openModal() {
        
        this.modalService.open("SendMailModal");
        
    }

    closeModal() {
        
        this.modalService.close("SendMailModal");
        this.isLogin=true;
    }
    
}
