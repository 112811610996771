import {Injectable} from '@angular/core';
import {Api} from '../providers/api';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {RequestOptions, Headers} from '@angular/http';
import {Users} from './Users';
import {FormModule} from '../models/FormModule';
import {ProjectModule} from '../models/ProjectModule';
import {ProjectGroupUserModule} from '../models/ProjectGroupUserModule';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ProjectService 
{
    project;
    projectTrans = null;
//    defaultLanguage = "es";
//    defaultLanguageName = "Español";
    translang;
    
    constructor(public UsersService: Users,private api: Api, public form: FormModule,translate: TranslateService) 
    {
        this.translang = translate;
        this.UsersService = UsersService;
        this.translang.use(this.UsersService.getLocalUser().getLang());
//        this.UsersService.getLocalUser().setLang(this.defaultLanguage);
//        
//        this.translang.setDefaultLang(this.defaultLanguage);
//
//        this.translang.use(this.defaultLanguage);
        let respuesta = this.translang.get('Project').subscribe(res => { this.projectTrans = res; });
    }

    createProject(theme)
    {
        
        let data = {
            "theme" : ""+theme.id,
            "state" : 0,
            "admin": ""+this.UsersService.getLocalUser().getId(),
            "name": this.projectTrans
        }
        console.log(data);
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            console.log(this.UsersService.getLocalUser().getId());
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.post('api/projects', data).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log(res);
                        let project: ProjectModule = new ProjectModule();
                        project.initialize(res);
                        console.log(project);
                        resolve(project);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    getProject(idProject)
    {
        
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.get('api/projects/'+idProject).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    if(res.answer!="" && res.answer!=null)
                    {
                        res.answer = JSON.parse(res.answer);
                    }
                    else
                    {
                        res.answer = {};
                    }
                    let project: ProjectModule = new ProjectModule();
                        project.initialize(res);
                        project.setFiles(res.files);
                    resolve(project);

                },
                
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    getNav(idProject)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            
            lthis.api.get('api/my/nav/'+idProject,"",options).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    
                    resolve(res);

                },
                
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    getPDFDownloads(idProject)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            
            lthis.api.get('api/my/pdf/downloads/'+idProject,"",options).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    
                    resolve(res);

                },
                
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    postPrintPdf(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            
            lthis.api.post('api/set/pdf/downloads',data,options).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    
                    resolve(res);

                },
                
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    getPreview(idProject)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let data = {
                "project_id": idProject
            };
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.get('make/html/preview',data).subscribe(
                (res: any) =>
                {
                    console.log(res.text());
                    resolve(res.text());
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    getMyProjects()
    {
        let lthis = this;
        console.log(lthis.UsersService.getLocalUser()!= null);
        console.log(lthis.UsersService.getLocalUser().getId()>0);
        return new Promise(function (resolve, reject)
        {
            console.log(lthis.UsersService.getLocalUser()!= null);
            console.log(lthis.UsersService.getLocalUser().getId()>0);
            if(lthis.UsersService.getLocalUser()!= null && lthis.UsersService.getLocalUser().getId()>0)
            {
                console.log(localStorage.getItem('token'));
                let bearer = localStorage.getItem('token');
                let headers = new Headers({"Authorization": "Bearer " + bearer});
                let options = new RequestOptions({headers: headers});
                lthis.api.get('api/project/getMyProjects',"",options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        let projects = [];
                        console.log(res);
                        for(let i = 0; i < res.length; i++)
                        {
                            let project: ProjectModule = new ProjectModule();
                            project.initialize(res[i]);
                            console.log(project);
                            projects.push(project);
                        }
                        console.log("------------------------------");
                        console.log(projects);
                        console.log("------------------------------");
                        resolve(projects);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            }    
        });
    }
    putProject(projectId,data)
    {
        
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.put('api/projects/'+projectId, data).map(res => res.json()).subscribe(
            (res: any) =>
            {
                console.log("Register result ", res);
                let project: ProjectModule = new ProjectModule();
                project.initialize(res);
                project.setFiles(res.files);
                console.log(project);
                resolve(project);
                
                console.log("Register masterpice ", project);
                resolve(project);
            },
            (err) =>
            {
                console.error('error register ', err);
                reject(err);
            });
        });
    }
    deleteProject(idProject)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.delete('api/projects/'+idProject).map(res => res.json()).subscribe(
            (res: any) =>
            {
                resolve(true);
            },
            (err) =>
            {
                console.error('error register ', err);
                reject(err);
            });
        });
    }
    ChangeIri(project)
    {
        if( project.getUser() != null)
        {
            if(typeof project.getUser() != "string")
            {
                project.setUser(""+project.getUser().id);
            }
        }
        if( project.getAdmin() != null)
        {
            if(typeof project.getAdmin() != "string")
            {
                project.setAdmin(""+project.getAdmin().id);
            }
        }
        if( project.getFiles() != null)
        {
            
                delete project.files;
            
        }
        if( project.getComments() != null)
        {
            if(typeof project.getComments() != "string")
            {
                delete project.comments;
            }
        }
        return project;
    }
    
    uploadFile(id:number, file: File)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            console.log(file);
            const formData = new FormData();
            formData.append('file', file, id+"-"+this.slugify(file["name"]).normalize('NFD').replace(/[\u0300-\u036f]/g, ""));
            console.log(formData);
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                lthis.api.post('api/upload-file/uploadFile', formData, options).subscribe(
                (res: any) =>
                {
                    console.log(res);
                    resolve(res);
                },
                (err) =>
                {
                    console.log(" Error.. \n " + err);
                    reject(err);
                });
            });
        }    
    }
    
   
    uploadPortada(id:number, file: File)
    {
        console.log(file);
        const formData = new FormData();
        formData.append('file', file, id+"-"+this.slugify(file["name"]).normalize('NFD').replace(/[\u0300-\u036f]/g, ""));
        console.log(formData);
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.post('api/upload-file/uploadPortada', formData).subscribe(
            (res: any) =>
            {
                console.log(res);
                resolve(res);
            },
            (err) =>
            {
                console.log(" Error.. \n " + err);
                reject(err);
            });
        });
    }
    
    
    uploadPicture(id:number, idField:number ,file)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            console.log(file);
            let extension = file.name.split(".").pop();
            const formData = new FormData();
            formData.append('file', file, id+"-"+idField+"."+extension);
            console.log(formData);
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                lthis.api.post('api/upload-answer/newAnswer', formData,options).subscribe(
                (res: any) =>
                {
                    console.log(res);
                    resolve(res);
                },
                (err) =>
                {
                    console.log(" Error.. \n " + err);
                    reject(err);
                });
            });
        }
    }
    
    setComment(idProject,comment,idUser)
    {
        let lthis = this;
        let data = {
            'message': comment,
            'user': idUser,
            'project': idProject 
        };
        console.log(data);
        return new Promise(function (resolve, reject)
        {
            lthis.api.post('api/comments', data).subscribe(
            (res: any) =>
            {
                console.log(res);
                resolve(res);
            },
            (err) =>
            {
                console.log(" Error.. \n " + err);
                reject(err);
            });
        });
    }
    pdfProject(idProject)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('make/html/pdf/'+idProject,"").map(res => res.json()).subscribe(
            (res: any) =>
            {
                console.log("Register result ", res);
                
                resolve(res);
            },
            (err) =>
            {
                console.error('error register ', err);
                reject(err);
            });
        });
    }
    deleteFile(idFile)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.delete('api/upload-files/'+idFile).map(res => res.json()).subscribe(
            (res: any) =>
            {
                console.log("Register result ", res);
                
                resolve(res);
            },
            (err) =>
            {
                console.error('error register ', err);
                reject(err);
            });
        });
    }
    sendExel(idProject)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('make/html/projectToXls/'+idProject).subscribe(
            (res: any) =>
            {
                resolve(res);
            },
            (err) =>
            {
                console.error('error register ', err);
                reject(err);
            });
        });
    }
    
    getNavigationPageProject(idProject)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('get/navigation/project/'+idProject).subscribe(
            (res: any) =>
            {
                resolve(res);
            },
            (err) =>
            {
                console.error('error register ', err);
                reject(err);
            });
        });
    }
    
    putProjectGroupUser(projectId,bandId,userId)
    {
        let data = {
            "projectId":projectId,
            "bandId":bandId,
            "userId":userId
        }
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.post('put/project/group/user',data).map(res => res.json()).subscribe(
            (res: any) =>
            {
                resolve(res);
            },
            (err) =>
            {
                console.error('error register ', err);
                reject(err);
            });
        });
    }
    
    getProjectGroupUser(projectId,userId)
    {
        let data = {
            "projectId":projectId,
            "userId":userId
        }
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('get/project/group/user/'+projectId+"/"+userId).map(res => res.json()).subscribe(
            (res: any) =>
            {
                let pgus = [];
                for(let pgu of res)
                {
                    let projectgroupuser: ProjectGroupUserModule = new ProjectGroupUserModule();
                    projectgroupuser.initialize(pgu);
                    pgus.push(projectgroupuser);
                }
                
                resolve(pgus);
            },
            (err) =>
            {
                console.error('error register ', err);
                reject(err);
            });
        });
    }
    
    getMyCommentsProjects()
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            if(lthis.UsersService.getLocalUser()!= null && lthis.UsersService.getLocalUser().getId()>0)
            {
                console.log(localStorage.getItem('token'));
                let bearer = localStorage.getItem('token');
                let headers = new Headers({"Authorization": "Bearer " + bearer});
                let options = new RequestOptions({headers: headers});
                lthis.api.get('api/project/getMyCommentsProjects',"",options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log(res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            }    
        });
    }
    
    
    slugify(text)
    {
      return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
    }
    
}

