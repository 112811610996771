import {Injectable} from '@angular/core';
import {Api} from '../providers/api';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {RequestOptions, Headers} from '@angular/http';
import {Users} from './Users';
import {FormModule} from '../models/FormModule';
import {BlockModule} from '../models/BlockModule';
import {ThemeModule} from '../models/ThemeModule';
import { SectionModule } from '../models/SectionModule';
import { SectionClusterModule } from '../models/SectionClusterModule';
import { FettleGroupModule } from '../models/FettleGroupModule';
import { FettleModule } from '../models/FettleModule';

@Injectable()
export class ThemeService 
{
    
    
    constructor(public UsersService: Users,private api: Api, public form: FormModule, public block: BlockModule, public theme: ThemeModule) 
    {
        this.UsersService = UsersService;
    }

    createTheme()
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            console.log(this.UsersService.getLocalUser().getId());
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                
                var d = new Date();
                lthis.api.post('api/themes', {"name":"Theme","user":lthis.UsersService.getLocalUser().getId()}).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    getMyThemes()
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.get('api/theme/getMyThemes', '', options).subscribe(
                    (res: any) =>
                    {
                        let toRet = [];
                        console.log("Register result ", res);
                        for (let current of JSON.parse(res.text())) {
                            lthis.theme = new ThemeModule();
                            lthis.theme.initialize(current);
                            toRet.push(lthis.theme);
                        }
                        resolve(toRet);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    getMyThemesForProject()
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.get('api/theme/getMyThemesFromProject', '', options).subscribe(
                    (res: any) =>
                    {
                        let toRet = [];
                        console.log("Register result ", res);
                        for (let current of JSON.parse(res.text())) {
                            lthis.theme = new ThemeModule();
                            lthis.theme.initialize(current);
                            toRet.push(lthis.theme);
                        }
                        resolve(toRet);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    getTheme(idTheme)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise<ThemeModule>(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.get('api/themes/'+idTheme, '', options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        
                        let theme = new ThemeModule();
                        theme.initialize(res);
                        console.log(theme);
                        resolve(theme);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    putTheme(idTheme,data)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.put('api/themes/'+idTheme, data, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    createSection(idTheme)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.post('api/sections', {"name":"title","theme":""+idTheme}, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        let section = new SectionModule();
                        section.initialize(res);
                        resolve(section);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    createSubSection(idTheme,section)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.post('api/sections', {"name":"title","theme":""+idTheme,"parent":""+section.getId()}, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        let section = new SectionModule();
                        section.initialize(res);
                        resolve(section);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    setVariableRelation(relationId,data)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.put('api/variable-templates/'+relationId, data, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    newConditionGroupRelation(data)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.post('api/fettle-groups', data, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        let fettleGroup = new FettleGroupModule();
                        fettleGroup.initialize(res);
                        resolve(fettleGroup);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    putFettleGroup(fettleGroupId,data)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.put('api/fettle-groups/'+fettleGroupId, data, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    deleteFettleGroup(fettleGroupId)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.delete('api/fettle-groups/'+fettleGroupId, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    newConditionFettle(data)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.post('api/fettles', data, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        let fettle = new FettleModule();
                        fettle.initialize(res);
                        resolve(fettle);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    putFettle(id,data)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.put('api/fettles/'+id, data, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    
    setSectionToCluster(data)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.post('api/section-clusters', data, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        let sectionCluster = new SectionClusterModule();
                        sectionCluster.initialize(res);
                        resolve(sectionCluster);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    putSection(sectionId,data)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.put('api/sections/'+sectionId, data, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        let sectionCluster = new SectionClusterModule();
                        sectionCluster.initialize(res);
                        resolve(sectionCluster);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    getSectionCluster(sectionClusterId)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.get('api/section-clusters/'+sectionClusterId, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        let sectionCluster = new SectionClusterModule();
                        sectionCluster.initialize(res);
                        resolve(sectionCluster);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    putSectionCluster(sectionClusterId,data)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.put('api/section-clusters/'+sectionClusterId, data, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    deleteSectionCluster(sectionClusterId)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.delete('api/section-clusters/'+sectionClusterId, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    deleteSectionTheme(sectionThemeId)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.delete('api/section-themes/'+sectionThemeId, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    deleteSection(sectionId)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.delete('api/sections/'+sectionId, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    putFormOnTheme(themeId,formId)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.put('api/themes/'+themeId, {"form":formId+""} ,options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    deleteTheme(themeId)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.delete('api/themes/'+themeId,options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    deleteFormFromTheme(themeId)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.post('remove/form',{"themeId":themeId},options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    setSectionToTheme(data)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.post('api/section-themes',data,options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    preventRecursiveTheme(id)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.get('prevent/recursive/theme/'+id,"",options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    putConditionFettle(id,data)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.put('api/fettle-groups/'+id,data,options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    loadFettleGroup(variableId)
    {
        console.log(variableId);
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.get('get/my/fettlegroup/'+variableId,'').map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    loadFettle(variableId)
    {
        console.log(variableId);
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.get('get/my/fettle/'+variableId,'').map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    loadVariableField(variableId)
    {
        console.log(variableId);
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.get('get/my/variable/field/'+variableId,'').map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    uploadDocument(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            const formData = new FormData();
            formData.append('file', data, ""+lthis.UsersService.getLocalUser().getId());
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            lthis.api.post('template/document', formData, options).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    putUpdateTemplate(template)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.put('api/template-documents/'+template.id, template).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    uploadTemplateDocumentImg(idTemplatedata,data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            const formData = new FormData();
            formData.append('file', data, idTemplatedata+"");
            console.log(formData);
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('api/template/document/uploadImg', formData).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    putVariable(id,data)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.put('api/variable-templates/'+id,data, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    getAllTemplates()
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.get('my/original/templates/'+lthis.UsersService.getLocalUser().getId(),"",options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    putTemplate(templateId,templateName)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.put('api/template-documents/'+templateId, {"templateName":templateName}, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    selectTemplate(myTheme,templateId)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.post('template/select', {"theme":myTheme.id,"templateDocument":templateId,"user":lthis.UsersService.getLocalUser().getId()}, options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    
    updateTemplateVariable(idTemplate,data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            const formData = new FormData();
            formData.append('file', data, idTemplate+"");
            console.log(formData);
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('update/template/document', formData).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    getAllTemplatesPublic()
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.get('my/original/templates/'+lthis.UsersService.getLocalUser().getId(),"",options).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }

}

