import {Injectable} from '@angular/core';
import {Api} from '../providers/api';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {User,UserStorageKey} from '../models/user';
import {Client} from '../models/client';
import {TarrifModule} from '../models/tarrifModule';
import {TarrifTypeModule} from '../models/tarrifTypeModule';
import {PaymentModule} from '../models/PaymentModule';
import {RequestOptions, Headers} from '@angular/http';
import {Users} from './Users';

@Injectable()
export class ConfigurationService
{
    constructor(public UsersService: Users,private api: Api) 
    {
        this.UsersService = UsersService;
    };
    
    uploadLogoWeb(image: File,user)
    {
        const formData = new FormData();
        formData.append('file', image, this.getLocalUser().getId()+"-logo");
        console.log(formData);
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.post('api/users/uploadLogoWeb', formData).map(res => res.json()).subscribe(
            (res: any) =>
            {
                user.setConfiguration(res);
                console.log(user);
                resolve(user);
            },
            (err) =>
            {
                console.log(" Error.. \n " + err);
                reject(err);
            });
        });
    }
    
    uploadImageWeb(image: File,user)
    {
        const formData = new FormData();
        formData.append('file', image, this.getLocalUser().getId()+"-image");
        console.log(formData);
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.post('api/users/uploadImageWeb', formData).map(res => res.json()).subscribe(
            (res: any) =>
            {
                user.setConfiguration(res);
                console.log(user);
                resolve(user);
            },
            (err) =>
            {
                console.log(" Error.. \n " + err);
                reject(err);
            });
        });
    }
    
    uploadColorWeb(user,primary,secondary,bar)
    {
        let data = {
            "user_id" : user.getId(),
            "primary" : primary,
            "secondary" : secondary,
            "bar" : bar
        };
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.post('api/users/uploadColorWeb', data).map(res => res.json()).subscribe(
            (res: any) =>
            {
                user.setConfiguration(res);
                console.log(user);
                resolve(user);
            },
            (err) =>
            {
                console.log(" Error.. \n " + err);
                reject(err);
            });
        });
    }
    
    resetConfigWeb(user)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.post('api/users/resetConfig/'+user.getId(),'').map(res => res.json()).subscribe(
            (res: any) =>
            {
                user.setConfiguration(res);
                console.log(user);
                resolve(user);
            },
            (err) =>
            {
                console.log(" Error.. \n " + err);
                reject(err);
            });
        });
    }
    
    getLocalUser()
    {
        let user: User = new User();
        user.initialize(JSON.parse(localStorage.getItem(UserStorageKey)));
        return user;
    }
    
    uploadPrimaryColor(color)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/get/config/user/', color).subscribe(
            (res: any) =>
            {
                console.log(res);
                resolve(res);
            },
            (err) =>
            {
                console.log(" Error.. \n " + err);
                reject(err);
            });
        });
    }
        
     uploadLogo(id:number, file: File)
    {
        console.log(file);
        const formData = new FormData();
        let splitImage = file.name.split(".");
        let extension = splitImage.pop();
        formData.append('file', file, id+"-"+this.slugify(splitImage.join()).normalize('NFD').replace(/[\u0300-\u036f]/g, "")+"."+extension);
        console.log(formData);
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.post('api/upload-file/uploadLogo', formData).subscribe(
            (res: any) =>
            {
                console.log(res);
                resolve(res);
            },
            (err) =>
            {
                console.log(" Error.. \n " + err);
                reject(err);
            });
        });
    }
    uploadLogoFooter(id:number, file: File)
    {
        console.log(file);
        const formData = new FormData();
        let splitImage = file.name.split(".");
        let extension = splitImage.pop();
        formData.append('file', file, id+"-"+this.slugify(splitImage.join()).normalize('NFD').replace(/[\u0300-\u036f]/g, "")+"."+extension);
        console.log(formData);
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.post('api/upload-file/uploadLogoFooter', formData).subscribe(
            (res: any) =>
            {
                console.log(res);
                resolve(res);
            },
            (err) =>
            {
                console.log(" Error.. \n " + err);
                reject(err);
            });
        });
    }
    
    uploadImg(file: File)
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            const formData = new FormData();
            formData.append('file', file);
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                lthis.api.post('api/upload-file/uploadImg', formData, options).subscribe(
                (res: any) =>
                {
                    console.log(res);
                    resolve(res);
                },
                (err) =>
                {
                    console.log(" Error.. \n " + err);
                    reject(err);
                });
            });
        }
    }
    
    slugify(text)
    {
      return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
    }
    
    
}

