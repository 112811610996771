import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

export class FieldsModule extends Base_Model
{

    private id: number = null;
    private question: string = null;
    private tipo: string = null;
    private content: string = null;
    private textarea: string = null;
    public choice;
    private image: string = null;
    private sort: string = null;
    private block: string = null;
    private band: number = null;
    private optional: number = 0;
    private status: number = null;
    private description: string = null;
    public typeSelect = false;
    public special:string = "";
    
    
    public getId()
    {
        return this.id;
    }
    public getQuestion()
    {
        return this.question;
    }
    public getContent()
    {
        return this.content;
    }
    public getDescription()
    {
        return this.description;
    }
    public getImage()
    {
        return this.image;
    }
    
    public getBlock()
    {
        return this.block;
    }
    public getType()
    {
        return this.tipo;
    }
    public getTextarea()
    {
        return this.textarea;
    }
//    public getChoice()
//    {
//        return this.choice;
//    }
    public getSort()
    {
        return this.sort;
    }
    public setSort(sort)
    {
        this.sort = sort;
    }
    
    public setType()
    {
        return this.tipo;
    }
    
    public getStatus()
    {
        return this.status;
    }
}