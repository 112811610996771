import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

export class ProjectGroupUserModule extends Base_Model
{

    private id: number = null;
    public bandId:number = null;
    public projectId:number = null;
    public userId:number = null;
    
    public getId()
    {
        return this.id;
    }
    
    public getBandId()
    {
        return this.bandId;
    }
    
    public getProjectId()
    {
        return this.projectId;
    }
    
    public getUserId()
    {
        return this.userId;
    }
}