﻿import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonDataService } from './../common-data.service';
import { ClusterService } from './../../services/ClusterService';
import { FieldsService } from './../../services/FieldsService';
import { VariableModule } from './../../models/VariableModule';
import { PoolModule } from './../../models/PoolModule';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../shared/_services/index';

import * as shape from 'd3-shape';
declare var window: any;

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss']
})
export class BlockComponent implements OnInit {

    @ViewChild("containerPrint") containerPrint: ElementRef;
    statusField;
    currentTypeField;
    setActionColor = "";
    currentFieldId = 0;
    fixedFields;
    isEdit = false;
    fieldDelete;
    constructor(
    private _commondata: CommonDataService,
    //public form: Form,
    public clusterServices: ClusterService,
    public router: Router,
    public route: ActivatedRoute,
    private modalService: ModalService,
    public fieldService: FieldsService
    ) { }
    
    ngOnInit()
    {
        this.getInit();
    }
    
    getInit()
    {
        this.statusField="";
        this.currentFieldId = 0;
        this.isEdit=false;
        setTimeout(_ => this._commondata.showLoader(true), 10);
        let response = this.fieldService.getFixed().then(
            (res:any)=>{
                console.log(res);
                this.fixedFields = res;
            },
            (err)=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
        setTimeout(_ => this._commondata.showLoader(false), 200);
    }
    
    createFixed()
    {
        this.openModal("addField");
    }
    selectField(element)
    {
        this.currentTypeField = element;
    }
    openModal(id: string) 
    {
        this.setActionColor = "";
        let allElem = document.querySelectorAll(".colorBlock");
        for(let i = 0; i < allElem.length; i++)
        {
            (<HTMLInputElement>allElem[i]).style.border = "0.3px solid";
        }
        this.modalService.open(id);
        this.currentTypeField = false;
        this.statusField = false;
    }
    closeModal(id: string) 
    {
        console.log(id);
        this.modalService.close(id);
        this.currentTypeField = false;
        this.statusField = false;
    }
    nextStep()
    {
        this.statusField = this.currentTypeField;
    }
    
    editFields(field)
    {
        this.isEdit=true;
        console.log(field);
        this.currentFieldId = field.id;
        
        if(field.parent)
        {
            this.statusField = "fixed";
        }
        else if(field.type == "Line")
        {
            if(field.special == "title")
            {
                this.statusField = "title_oriented";
            }
            else if(field.choice==null)
            {
                if(field.auxType)
                {
                    this.statusField = field.auxType;
                }
                else
                {
                    this.statusField = "textPlain";
                }
            }
            else
            {
                if(field.auxType == "switch")
                {
                    this.statusField = field.auxType;
                }
                else
                {
                    this.statusField = 'select';
                }
            }
        }
        else
        {
            this.statusField = "image";
        }
        console.log(this.statusField);
        this.modalService.open("editFieldModal");
        
    }
    
    deleteFieldModal(field)
    {
        this.modalService.open("deleteFieldModal");
        this.fieldDelete = field;
    }
    
    deleteField()
    {
        
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let response = this.fieldService.deleteField(this.fieldDelete).then(
            (res)=>{
                this.modalService.close("deleteFieldModal");
                console.log(this.fieldDelete.type);
                console.log(this.fieldDelete.type=="Paragraf");
//                if(this.fieldDelete.type=="Paragraf")
//                {
//                     let response = this.fieldService.deleteOrphanLine(this.myForm).then(
//                        (res)=>{
//                             setTimeout(_ => this._commondata.showLoader(false), 200);
//                            this.getInit();
//                        },
//                        (err)=>{
//                             setTimeout(_ => this._commondata.showLoader(false), 200);
//                        });
//                }
//                else
//                { 
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.getInit();
//                }
            },
            err=>{
                        console.log("form_no");

            }
        );
    }
    
    setActive(target)
    {
        var elems = document.querySelectorAll(".active");

        [].forEach.call(elems, function(el) {
            el.classList.remove("active");
        });
        
        target.closest(".imgBox").classList.add("active");
        
    }
    
}
