﻿import { Component, OnInit } from '@angular/core';
import { CommonDataService } from './../../common-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Users } from './../../../services/Users';
import { ProjectService } from './../../../services/ProjectService';
import { FieldsService } from './../../../services/FieldsService';
import { ProjectModule } from './../../../models/ProjectModule';
import { BlockModule } from './../../../models/BlockModule';
import { BandModule } from './../../../models/BandModule';
import * as shape from 'd3-shape';
declare var window: any;
@Component({
  selector: 'app-projects',
  templateUrl: './accessProjects.component.html',
  styleUrls: ['./accessProjects.component.scss']
})
export class AccessProjectsComponent implements OnInit {

    idProject = 0;
    project;
    blocks = [];
    myForm;
    currentStep = 0;
    count = 0;
    totalGroups=0;
    myGroups=[];
    myGroup;
    group;
    myOptionalGroups=[];
    myNav;
    constructor(
    private _commondata: CommonDataService,
    public router: Router,
    public route: ActivatedRoute,
    public user_model: Users,
    public projectService: ProjectService,
    public fieldService: FieldsService,
    ) 
    { 
    
    }
    ngOnInit() 
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        //(<HTMLElement>document.querySelector("#projectBackground")).style.backgroundImage = "url('assets/images/projectImg.jpg')";
        
        this.route.params.subscribe(
            (res: ProjectModule) =>
            {
                if(res["idProject"]>0)
                {
                    this.idProject = res["idProject"];
                    let response = this.projectService.getProject(this.idProject).then(
                        (res:any)=>{
                            
                            this.project = res;
                            document.querySelector("#titleEditable").innerHTML = this.project.getName();
                            
                            setTimeout(_ => this._commondata.showLoader(true), 200);
                            let respons = this.projectService.getNav(this.idProject).then(
                            (band:any)=>{
                                    this.totalGroups = band.length;
                                    console.log(this.totalGroups);
                                    this.myNav = band;
                                    setTimeout(_ => this._commondata.showLoader(true), 200);
                                    let respo = this.fieldService.oneGroup(this.project,this.myNav[0].id).then(
                                    (group:any)=>{
                                            group.fields.sort(function(a,b){
                                                        return a.sort - b.sort;
                                            });

                                            this.group = group;

                                            console.log(this.group);
                                            setTimeout(_ => this._commondata.showLoader(false), 200);

                                        },
                                        err=>{
                                            console.log("form_no");
                                        }
                                    );
                                    
                                },
                                err=>{
                                    console.log("form_no");
                                }
                            );
                        },
                        err=>{
                            console.log("form_no");
                        }
                    );
                    
                    
                    
                    
                }
                if (this.idProject === 0)
                {
                    this.router.navigate(['/projects/']);
                    
                }
            }
        );
    }
    
    recursiveLevel(blocks,toRet,index)
    {
        let y = 0;
        for (let blck of blocks) 
        {
            y++;
            let otherIndex = index+y+".";
            blck.name = "<span>"+""+otherIndex+"&nbsp;&nbsp;"+blck.name+"</span>";
            console.log(toRet);
            console.log(blck);
            toRet.push(blck);
            let fields = blck.fields;
            for(let i=0;i<fields.length;i++)
            {
                fields[i].question = fields[i].question;
            }
            if(blck.getBlocks().length>0)
            {
                this.recursiveLevel(blck.getBlocks(),toRet,otherIndex,);
            }
        }
    }
    removeSpaces(str)
    {
         return str.replace(new RegExp('&nbsp;', "g"),"");
    }
    nextStep()
    {
        this.currentStep++;
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let respo = this.fieldService.oneGroup(this.project,this.myNav[this.currentStep].id).then(
            (group:any)=>{

                    group.fields.sort(function(a,b){
                                return a.sort - b.sort;
                    });
                    this.group = group;
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    console.log(this.group);


                },
                err=>{
                    console.log("form_no");
                }
            );
    }
    backStep()
    {
        this.currentStep--;
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let respo = this.fieldService.oneGroup(this.project,this.myNav[this.currentStep].id).then(
                                    (group:any)=>{

                                        group.fields.sort(function(a,b){
                                            return a.sort - b.sort;
                                        });
                                            this.group = group;

                                            console.log(this.group);
                                            setTimeout(_ => this._commondata.showLoader(false), 200);

                                        },
                                        err=>{
                                            console.log("form_no");
                                        }
                                    );
    }
    goToStep(groupId,step)
    {
        
        this.currentStep = step;
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let respo = this.fieldService.oneGroup(this.project,groupId).then(
                                    (group:any)=>{
                                        group.fields.sort(function(a,b){
                                                return a.sort - b.sort;
                                        });
                                            this.group = group;

                                            console.log(this.group);
                                            setTimeout(_ => this._commondata.showLoader(false), 200);

                                        },
                                        err=>{
                                            console.log("form_no");
                                        }
                                    );
    }
    goToPreview()
    {
        let strdata = JSON.stringify(this.project.getAnswer());
        console.log(strdata);
        this.projectService.putProject(this.project.getId(),{"answer":strdata}).then(
            (data : any)=>{
                console.log(data);
                this.router.navigate(['/preview/'+this.idProject]);
            },
            error=>{
                console.log(error);
            }
        );
        
    }
    blockQuestion(response,band)
    {
        band.setOptional(response);
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let data = {
            "b":response
        };
        this.project.updateAnswer(band.getBlockId()+"_b",data);
        let strdata = JSON.stringify(this.project.getAnswer());
        this.projectService.putProject(this.project.getId(),{"answer":strdata}).then(
            (data : any)=>{
                setTimeout(_ => this._commondata.showLoader(false), 200);
                
                console.log(data);
            },
            error=>{
                console.log(error);
            }
            );
    }
    
    checkVisibility(field,project)
    {
        if(field.status == 0 || field.status == null)
        {
            return true;
        }
        if(field.status == 1)
        {
            return false
        }
        if(field.status == 2)
        {
            console.log(field);
            let strBool = "";
            let fettlesCount = field.fettles.length;
            let i = 0;
            let relationArray = [];
            for( let relation of field.fettles )
            {
//                if(relation.lineParentId)
//                {
//                    relation.lineId = relation.lineParentId; 
//                }
                console.log(relation);
                let resp = "";
                if(typeof project.answer[relation.lineId] === 'object')
                {
                    console.log(Object.values(project.answer[relation.lineId]));
                    console.log(relation.realResponse);
                    for(let j=0; j<Object.values(project.answer[relation.lineId]).length; j++)
                    {
                        if ((Object.values(project.answer[relation.lineId]))[j] == relation.realResponse) 
                        {
                            console.log(relation.realResponse);
                            console.log(relationArray);
                            console.log(relationArray.includes(relation.realResponse));
                            if (!(relationArray.includes(relation.realResponse))) 
                            {
                                resp = Object.values(project.answer[relation.lineId])[j];
                                relationArray.push(resp);
                                console.log(resp);
                            }
                        }
                    }
                    
                }
                else
                {
                    resp = project.answer[relation.lineId];
                }
                    
                if(resp)
                {
                    strBool += "'"+resp.replace("'","")+"' ";
                    strBool += relation.cond+" ";
                    strBool += "'"+relation.realResponse.replace("'","")+"' ";
                    if((fettlesCount-1) != i)
                    {
                        if(relation.logic=='AND')
                        {
                            strBool += "&& ";
                        }
                        if(relation.logic=='OR')
                        {
                            strBool += "|| ";
                        }
                    }
                }
                else
                {
                    if(relation.cond == "!=")
                    {
                        strBool += ' true ';
                    }
                    else
                    {
                        strBool += ' false ';
                    }
                    if((fettlesCount-1) != i)
                    {
                        if(relation.logic=='AND')
                        {
                            strBool += "&& ";
                        }
                        if(relation.logic=='OR')
                        {
                            strBool += "|| ";
                        }
                    }
                }
                i++;
                
            }
            console.log(strBool);
            console.log(eval(strBool));
            if(!eval(strBool))
            {
                delete project.answer[field.id];
                return false;
            }
            return eval(strBool);
        }
    }
}