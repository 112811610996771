import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {TarrifModule} from './tarrifModule';
import {ConfigModule} from './ConfigModule';

export const UserStorageKey = "user";

export class User extends Base_Model
{

    private id: number = null;
    private email: string = null;
    private password: string = null;
    private passconf: string = null;
    private name: string = null;
    private username: string = null;
    private surname: string = null;
    private token: string = null;
    private tokenExpire: string = null;
    private tarrifs: TarrifModule = new TarrifModule();
    private parent:any=null;
    private children:any=null;
    private enabled: boolean = false;
    private typeUser: any = null;
    private image: any = null;
    private configureWeb: ConfigModule = new ConfigModule();
    private lang: string = "es";
    private currentTarrif: number = 0;
    private projectCount: number = 0;
    private usersCount: number = 0;
    private logo: string = "";
    private logoFooter: string = "";


    public getId()
    {
        return this.id;
    }
    public getEmail()
    {
        return this.email;
    }
    public getPassword()
    {
        this.password;
    }
    public getPassconf()
    {
        this.passconf;
    }
    public checkPass()
    {
        if (this.password == this.passconf)
        {
            return true;
        }
        return false;
    }
    public getName()
    {
        return this.name;
    }
    public getUserName()
    {
        return this.username;
    }
    public getSurname()
    {
        return this.surname;
    }
    
    public getToken()
    {
        return this.token;
    }
    public setToken(token)
    {
        this.token = token;
        return this;
    }

    public getTokenExpire()
    {
        return this.tokenExpire;
    }
    public setTokenExpire(expire)
    {
        this.tokenExpire = expire;
        return this;
    }
    
    public getTarrif()
    {
            let tarrif: TarrifModule = new TarrifModule();
            tarrif.initialize(JSON.parse(JSON.stringify(this.tarrifs)));
        
        return tarrif;
    }
    
    public setTarrif(tarrifs)
    {
        this.tarrifs = tarrifs;
        return this;
    }
    
    public getParent()
    {
        let parent: User = new User();
            parent.initialize(this.parent);
        
        return parent;
    }
    
    public getTypeUser()
    {
        return this.typeUser;
    }
    
    public setTypeUser(typeuser)
    {
        this.typeUser = typeuser;
    }
    
    public getUserAccess()
    {
        if(this.getTarrif().isActive())
        {
            return true;
        }
        
        return false
    }
    
    public hasParentTarrif()
    {
        let parent = this.getParent();
        if(parent.getId()==null)
        {
            return false;
        }
        console.log(parent);
        console.log(parent.tarrifs);
        console.log(Object.values(parent.tarrifs).length>0);
        if(Object.values(parent.tarrifs).length>0)
        {
            return true;
        }
        return false;
    }
    
    public getCurrentParentTarrifId()
    {
        let parent = this.getParent();
        let tarrifs =Object.values(parent.tarrifs);
        let currentTarrif = tarrifs[tarrifs.length-1];
        return currentTarrif.id;
    }
    
    public getChildren()
    {
        return this.children;
    }
    public setChildren(children)
    {
        this.children = children;
        return this;
    }
    
    public getImage()
    {
        return this.image;
    }
    
    public getConfiguration()
    {
        if (!(this.configureWeb instanceof ConfigModule))
        {
            let configureWebAux = new ConfigModule();
            configureWebAux.initialize(this.configureWeb);
            this.configureWeb = configureWebAux;
        }
        return this.configureWeb;
    }
    
    public setConfiguration(configuration)
    {
        this.configureWeb = configuration;
        return this;
    }
    
    public setLang(lang)
    {
        this.lang = lang;
        return this;
    }
    
    public getLang()
    {
        return this.lang;
    }
    
    public getCurrentTarrif()
    {
        return this.currentTarrif;
    }
    
    public getProjectCount()
    {
        return this.projectCount;
    }
    
    public getUsersCount()
    {
        return this.usersCount;
    }
    public getLogoFooter()
    {
        return this.logoFooter;
    }
    public getLogo()
    {
        return this.logo;
    }
    public setLogoFooter(logoFooter)
    {
        this.logoFooter = logoFooter;
        return this.logoFooter;
    }
    public setLogo(logo)
    {
        this.logo = logo;
        return this.logo;
    }
}