import { Component, OnInit } from '@angular/core';
import { AppModule } from './../../app.module';
import { CommonDataService } from './../../common-data.service';
import { Users } from '../../../services/Users';
import { UserAuthService } from '../../../services/auth.service';
import { TarrifModule} from '../../../models/tarrifModule';
import { TarrifTypeModule} from '../../../models/tarrifTypeModule';
import { Router } from '@angular/router';
import { FormBuilder,FormControl, FormGroup, Validators, NgForm,FormsModule } from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    isInvalid = false;
    onMain: Boolean;
    flag: Boolean;
    login = false;
    logging = false;
    public elementRef;
    signupForm: FormGroup;
    isVisibleError = false;
    sendPost;
    wPurl="";
    pwRepet = false;
    conditions = false;
    urlDocs;

    constructor(
        public router: Router,
        public _commondata: CommonDataService,
        public user_model: Users,
        public auth: UserAuthService,
        private frmbuilder:FormBuilder,
        translate: TranslateService
        
    ) { 
        this.urlDocs = this.user_model.getDocUrl();
        this.onMain = false;
        this.flag = true;
        this.signupForm=frmbuilder.group({  
                email:new FormControl(null, Validators.compose([Validators.minLength(5),Validators.required])),
                username:new FormControl(null, Validators.compose([Validators.minLength(3),Validators.required])),
                businessName:new FormControl(null, Validators.compose([Validators.minLength(5),Validators.required])),
                address:new FormControl(null, Validators.compose([Validators.minLength(5),Validators.required])),
                ibanNumber:new FormControl(null, Validators.compose([Validators.minLength(5),Validators.required])),
                cp:new FormControl(null, Validators.compose([Validators.minLength(3),Validators.required])),
                city:new FormControl(null, Validators.compose([Validators.minLength(3),Validators.required])),
                phone:new FormControl(null, Validators.compose([Validators.minLength(0)])),
                password: new FormControl(null, Validators.compose([Validators.minLength(5),Validators.required])),
                repeatPassword: new FormControl(null, Validators.compose([Validators.minLength(5),Validators.required])),
                termsUse: new FormControl(null, Validators.compose([Validators.required])),
                privacityPolicity: new FormControl(null, Validators.compose([Validators.required]))
            });
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('fr');

        // the lang to use, if the lang isn't available, it will use the current loader to get them      
        translate.use('fr');    
    }
    private matchValidator(control) 
    {         
    return this.signupForm.value.repeatPassword === this.signupForm.value.password
    }

    ngOnInit() 
    {
        this.wPurl=this.user_model.getWPUrl();
    }
    
    registerUser(data)
    {
        this.pwRepet = false;
        this.isVisibleError = false;
        this.conditions = false;
        
        if(data.value.termsUse!= true || data.value.privacityPolicity!=true)
        {
            this.conditions = true;
        }
            
        if (this.signupForm.valid) {
            
            if(data.value.password!=data.value.repeatPassword)
            {
                this.pwRepet = true;
                return;
            }
            if(this.conditions ==true)
            {
                return;
            }
            
              console.log('form submitted');
                this.logging = true;
                this._commondata.showLoader(true);
            this.logging = true;
            this.sendPost = {
                'username':data.value.username,
                'email':data.value.email,
                'plainPassword':data.value.password,
                'IVA':data.value.ibanNumber,
                'bussinesName':data.value.businessName,
                'phone':data.value.phone,
                'city':data.value.city,
                'cp':data.value.cp,
                'address':data.value.address,
                'enabled':true,
                "terms": "1",
                "privacity": "1"
                
            };
            let lthis = this;
            let response = this.auth.registerUser(this.sendPost).then(
            (res:any)=>{
                let pass = this.sendPost["plainPassword"];
                    this.sendPost["password"] = pass;
                    this.auth.login(this.sendPost).then(
                                (res)=>{
                                    this.user_model.getProf().then(
                                    (res)=>{
                                        
                                        setTimeout(_ => this._commondata.showLoader(false), 200);
                                        this.router.navigate(['/']);
                                        
                                    },
                                    err=>{
                                        this.logging = false;
                                        this.isInvalid = true;
                                        this.isVisibleError = true;
                                        setTimeout(_ => this._commondata.showLoader(false), 200);
                                    }
                                );
                            },
                            err=>{
                                this.logging = false;
                                this.isInvalid = true;
                                this.isVisibleError = true;
                                setTimeout(_ => this._commondata.showLoader(false), 200);
                            }
                        );
            },
            err=>{
                this.isVisibleError = true;
                this.logging = false;
                this.isInvalid = true;
                setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
        }else{
            this.validateAllFormFields(this.signupForm);
        }
    }   

    
    validateAllFormFields(formGroup: FormGroup) {        
        Object.keys(formGroup.controls).forEach(field => { 
        const control = formGroup.get(field); 
        if (control instanceof FormControl) {            
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {       
            this.validateAllFormFields(control);           
        }
        });
    }
    
    tarrif(numtype)
    {
        let lthis = this;
        this.user_model.getProf().then(
        (res)=>{
            console.log(res);
            var CurrentDate = new Date();
            CurrentDate.setFullYear(CurrentDate.getFullYear() + 1);
            let data = 
            {
                "dateStart": this.formatDate(new Date().getTime()) + " 00:00:00",
                "dateEnd": this.formatDate(CurrentDate.getTime()) + " 23:59:59",
                'User':res["id"].toString(),
                'tarrifType':numtype
            };
            this.user_model.setTarrif(data).then(
            (res: any)=>{
                let user  = lthis.user_model.getLocalUser();
                console.log("yyyyyyyyyyyyyyyy " + res);
                let tarrif: TarrifModule = new TarrifModule();
                tarrif.initialize(JSON.parse(res.text()));
                this.user_model.getTarrifType(numtype).then(
                (tarrifType: TarrifTypeModule)=>{
                   
                    tarrif.setTarrifType(tarrifType);
                    user.setTarrif(tarrif);
                    user.getTarrif();
                    var userType = 4;
                    
                    if(tarrifType.getId()>1)
                    {
                        userType=2;
                    }
                    
                    user.setTypeUser(userType);    
                    console.log(user);
                    let data={
                        "TypeUser":userType+""
                    };
                    this.user_model.updateUser(user.getId(),data).then(
                    (res: any)=>{
                        lthis.user_model.saveLocalUser(user);
                        setTimeout(_ => this._commondata.showLoader(false), 200);
                        this.router.navigate(['/']);
                    },
                    (err: any)=>{
                        
                    });
                    
                    
                },
                err=>{
                    
                });
                
            },
            err=>{

            });
        },
        err=>{

        }
        ); 
    }
    
    formatDate(date) {
        let d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2){ month = '0' + month;}
        if (day.length < 2){ day = '0' + day;}

        return [year, month, day].join('-');
    }
     
}    
