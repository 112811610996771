import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {SectionModule} from './SectionModule';
export class ThemeModule extends Base_Model
{

    private id: number = null;
    public name: string = null;
    private text: string = null;
    private user: string = null;
    public sections: SectionModule[] = [];
    public form: any = null;
    public public: boolean = false;
    public templateDocument:any = null;
    
    public getId()
    {
        return this.id;
    }
    public getName()
    {
        return this.name;
    }
    public getText()
    {
        return this.text;
    }
    public setText(text)
    {
        this.text = text;
        return this;
    }
    public getUser()
    {
        return this.user;
    }
    
    public getSections()
    {
        let sectionsAux = [];
        for(let section of this.sections)
        {
            if (!(section instanceof SectionModule))
            {
                let sectionAux = new SectionModule();
                sectionAux.initialize(section);
                sectionsAux.push(sectionAux);
            }
            else
            {
                sectionsAux.push(section);
            }
            
        }
        this.sections = sectionsAux;
        this.sections.sort(function(a,b){
           return a.getSort()-b.getSort(); 
        });
        return this.sections;
    }
    
}