import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {FettleModule} from './FettleModule';

export class FettleGroupModule extends Base_Model
{

    private id: number = null;
    public fettes:FettleModule[] = [];
    private value:string = null;
    private field:string = null;
    private status:any = null;
    public templateDocument = {id:null};
    
    public getId()
    {
        return this.id;
    }
    
    public getFettles()
    {
        let fettlesAux = [];
        for(let fettle of this.fettes)
        {
            if (!(fettle instanceof FettleModule))
            {
                let fettleAux = new FettleModule();
                fettleAux.initialize(fettle);
                fettlesAux.push(fettleAux);
            }
            else
            {
                fettlesAux.push(fettle);
            }
            
        }
        this.fettes = fettlesAux;
        return this.fettes;
    }
    
    public getValue()
    {
        return this.value;
    }
    
    public setValue(value)
    {
        this.value = value;
        return this;
    }
    
    public getStatus()
    {
        return this.status;
    }
    
    public setStatus(status)
    {
        this.status = status;
        return this;
    }
    
    public getLine()
    {
        return this.field;
    }
    
    public setLine(field)
    {
        this.field = field;
        return this;
    }
    
    public addFettle(fettle)
    {
        this.getFettles();
        this.fettes.push(fettle);
    }
    
    public removeFettle(fettleToRemove)
    {
        let fettlesAux = [];
        for(let fettle of this.fettes)
        {
            if(fettleToRemove.getId()!=fettle.getId())
            {
                fettlesAux.push(fettle);
            }    
            
        }
        this.fettes = fettlesAux;
        return this.fettes;
    }
    
    public addPosibility()
    {
        for(let fettle of this.fettes)
        {
            console.log(fettle.getLogic());
            if(fettle.getLogic() == null || fettle.getLogic() == "")
            {
                return false;
            }
        }
        return true;
    }
    
    
}