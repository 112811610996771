import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

export class ProjectModule extends Base_Model
{

    private id: number = null;
    private name: string = null;
    private date: string = null;
    private description: string = null;
    private logo: string = null;
    private portada: string = null;
    private portadaText: string = null;
    private state: number = null;
    private user: any = null;
    private admin: any = null;
    private files: any = [];
    private comments: any = [];
    private form: any = [];
    private answer: any = {};
    private preview: string = null;
    private lateralBarText: string = null;
    private lateralBarColor: string = null;
    private lateralBarBackgroundColor: string = null;
    private logoFooter: string = null;
    private modify: string = null;
    private projectGroupUsers: any = null;
    public progress: number = 0;
    
    public getId()
    {
        return this.id;
    }
    public setId(id)
    {
        this.id = id;
        return this;
    }
    public getModify()
    {
        return this.modify;
    }
    public setsetModify(modify)
    {
        this.modify = modify;
        return this;
    }
    public getName()
    {
        return this.name;
    }
    public setName(name)
    {
        this.name = name;
        return this;
    }
    public getUser()
    {
        return this.user;
    }
    public setUser(user)
    {
        this.user = user;
        return this;
    }
    public getAdmin()
    {
        return this.admin;
    }
    public setAdmin(admin)
    {
        this.admin = admin;
        return this;
    }
    public getDate()
    {
        return this.date;
    }
    public setDate(date)
    {
        this.date = date;
        return this;
    }
    public getState()
    {
        return this.state;
    }
    public setState(state)
    {
        this.state = state;
        return this;
    }
    public getDescription()
    {
        return this.description;
    }
    public setDescription(description)
    {
        this.description = description;
        return this;
    }
    public getFiles()
    {
        return this.files;
    }
    public setFiles(files)
    {
        this.files=files;
        return this;
    }
    public getComments()
    {
        return this.comments;
    }
    public setComments(comments)
    {
        this.comments=comments;
        return this;
    }
    public getForm()
    {
        return this.form;
    }
    public setForm(form)
    {
        this.form=form;
        return this;
    }    
    public getAnswer()
    {
        return this.answer;
    }
    public setAnswer(answer)
    {
        this.answer=answer;
        return this;
    }
    public getLogo()
    {
        return this.logo;
    }
    public setLogo(logo)
    {
        this.logo=logo;
        return this;
    }
    public getPortada()
    {
        return this.portada;
    }
    public setPortada(portada)
    {
        this.portada=portada;
        return this;
    }
    public getPortadaText()
    {
        return this.portadaText;    
    }
    public setPortadaText(portadaText)
    {
        this.portadaText=portadaText;
        return this;
    }
    public getPreview()
    {
        return this.preview;    
    }
    public setPreview(preview)
    {
        this.preview=preview;
        return this;
    }
    public getLateralBarText()
    {
        return this.lateralBarText;    
    }
    public setLateralBarText(lateralBarText)
    {
        this.lateralBarText=lateralBarText;
        return this;
    }
    public getLateralBarColor()
    {
        return this.lateralBarColor;    
    }
    public setLateralBarColor(lateralBarColor)
    {
        this.lateralBarColor=lateralBarColor;
        return this;
    }
    public getLateralBarBackgroundColor()
    {
        return this.lateralBarBackgroundColor;    
    }
    public setLateralBarBackgroundColor(lateralBarBackgroundColor)
    {
        this.lateralBarBackgroundColor=lateralBarBackgroundColor;
        return this;
    }
    public getLogoFooter()
    {
        return this.logoFooter;    
    }
    public setLogoFooter(logoFooter)
    {
        this.logoFooter=logoFooter;
        return this;
    }
    
    public getProjectGroupUsers()
    {
        return this.projectGroupUsers;    
    }
    
    public getProjectGroupUsersDist()
    {
        let resUsersDist=[];
        let usersDist=[];
        
        if(this.projectGroupUsers && this.projectGroupUsers!=null)
        {
            for(let pUser of this.projectGroupUsers)
            {
                if(!resUsersDist.includes(pUser.user.username))
                {
                    usersDist.push(pUser);
                    resUsersDist.push(pUser.user.username);
                }
            }
        }
        return usersDist;    
    }
    
    public setProjectGroupUsers(projectGroupUsers)
    {
        this.projectGroupUsers=projectGroupUsers;
        return this;
    }
    
    public updateAnswer(fieldId,answer)
    {
        if(this.answer==null)
        {
            this.answer = {};
        }
        if(this.answer[fieldId]==null)
        {
            this.answer[fieldId] = {};
        }
        console.log(fieldId);
        console.log(answer);
        this.answer[fieldId]=answer;
    }
    public getProgress()
    {
        let ques = 0;
        let ans = 0;
        let width = 0;
        if(this.form == null)
        {
            width = 0;
            return 0;
        }
        if(this.form.blocks)
        {
            for( let block of this.form.blocks )
            {
                if(block.fields)
                {
                    for( let field of block.fields )
                    {
                        if(field.type!="Paragraf")
                        {
                            ques++;
                        }    
                    }
                }
            }
        }
        if(this.answer==null)
        {
            return 0;
        }
        
        for(let key of Object.keys(JSON.parse(this.answer)))
        {
            if(!(key.includes("_l")))
            {
                ans++;
            }
        }
        
        console.log(typeof Object.keys(JSON.parse(this.answer)).length);
        console.log(ans);
        console.log(ques);
        width = (ans*100/ques);
        console.log(width);
        return width;
        
    }
    
}